import { IVendorBalance, IVendorBalanceArray } from "../interface/dashboard";

export const convertObjectToArray = (data: any) => {
  const arrayOfObjects: IVendorBalanceArray[] = [];
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      arrayOfObjects.push({
        name: key,
        value: parseFloat(data[key]),
      });
    }
  }
  return arrayOfObjects;
};

export const sumArrayValues = (data: IVendorBalanceArray[]) => {
  let sum = 0;
  for (const item of data) {
    sum += item.value;
  }
  return parseFloat(sum.toFixed(2));
};
