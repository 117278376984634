import React, { useState } from 'react';
import { Modal } from '../../../../components/modal';
import { IBusinessDetailsprops } from '../../../../interface/business';
import IDCard from './idCard';

const ViewIdCard = ({ business, refetch }: IBusinessDetailsprops) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="bg-activeLinkBlack rounded-normal p-5 flex items-center justify-between cursor-pointer">
        <p>ID Card</p>
        <div className="bg-bColor py-1 px-2 gap-2 rounded-md text-[8px] flex">
          <div className="mx-2">
            Verification: {business.verification.idCard.validated ?? '---'}
          </div>
          <div>|</div>
          <div className="mx-2">
            ID Type: {business.verification?.idCard?.idType ?? '----'}
          </div>
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        header
        center
        overflow
        title="ID Card"
        padding={false}>
        <IDCard item={business} refetch={refetch} />
      </Modal>
    </div>
  );
};

export default ViewIdCard;
