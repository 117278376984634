export const serviceColors = (label: string) => {
  let badgeClass = '';
  switch (label) {
    case 'MTN':
      badgeClass = 'bg-[#fc0]';
      break;
    case 'GLO':
      badgeClass = 'bg-[#28a745]';
      break;
    case 'AIRTEL':
      badgeClass = 'bg-[#ed1c24]';
      break;
    case '9MOBILE':
      badgeClass = 'bg-[#b4c404]';
      break;
    case 'DSTV':
      badgeClass = 'bg-[#007bff]';
      break;
    case 'GOTV':
      badgeClass = 'bg-[#007e3c]';
      break;
    case 'ETISALAT':
      badgeClass = 'bg-[#800020]';
      break;
    default:
      badgeClass = 'bg-bColor';
      break;
  }
  return badgeClass;
};
