import { useContext, useState } from 'react';
import ToastContext from '../../context/toast';
import { CardVariant, IFundCard, IWithdrawCard } from '../../interface/cards';
import { useFundCard } from '../../services/cards';
import Button from '../button';
import Input from '../input';
import { Modal } from '../modal';
import Switch from '../switch';
import { capitalized } from '../../utils/capitalize';
import Dropdown from '../dropdown';

interface IFundCardProps {
  cardId: string;
  variant: string;
}

const FundCard = ({ cardId, variant }: IFundCardProps) => {
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState<IFundCard>({} as IFundCard);
  const [openSecretModal, setOpenSecretModal] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const { mutate, isLoading } = useFundCard();
  const { addToast } = useContext(ToastContext);

  const submit = () => {
    if (!payload?.secret) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
      return;
    }
    mutate(
      {
        ...payload,
        cardId,
        business: payload.business || false,
        chargeFromUser: payload.chargeFromUser || false,
        variant,
      },
      {
        onSuccess: () => {
          setOpenSecretModal(false);
          addToast({
            msg: 'Successfully Updated',
            type: 'success',
          });
        },
      }
    );
  };

  const openAccessModal = () => {
    if (!payload.amount) {
      addToast({
        msg: 'Please enter an amount',
        type: 'error',
      });
      return;
    } else {
      setOpen(false);
      setOpenSecretModal(true);
    }
  };

  return (
    <div className=" w-auto">
      <Button
        bgColor="bg-blue"
        onClick={() => {
          setOpen(true);
        }}
      >
        Fund card
      </Button>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        width="medium"
        header
        padding
        overflow
        title="Fund card"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={openAccessModal}>Fund</Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div className="py-2">
            <small className="text-xs">Amount</small>
            <Input
              defaultValue={payload?.amount}
              onChange={(e) => {
                setPayload({ ...payload!, amount: Number(e?.target.value) });
              }}
            />
          </div>
          <div className="py-2 flex justify-between items-center">
            <small className="text-xs">Charge from User</small>
            <Switch
              checked={payload?.chargeFromUser!}
              onCheck={() =>
                setPayload({
                  ...payload!,
                  chargeFromUser: !payload?.chargeFromUser,
                })
              }
            />
          </div>
          <div className="py-2 flex justify-between items-center">
            <small className="text-xs">Business</small>
            <Switch
              checked={payload?.business!}
              onCheck={() =>
                setPayload({ ...payload!, business: !payload?.business })
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        padding={false}
        isVisible={openSecretModal}
        center
        close={setOpenSecretModal}
        title="Enter Access Key"
        header
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="p-4">
          <p className="pb-2">
            <small className="w-1/4 text-textDimGray">Access key</small>
          </p>
          <Input
            name="secret"
            type={showToken ? 'text' : 'password'}
            value={payload?.secret}
            onChange={(e) =>
              setPayload({ ...payload!, secret: e.target.value })
            }
            trailing={
              <p
                onClick={() => setShowToken(!showToken)}
                className="text-sm text-blue"
              >
                Show
              </p>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default FundCard;
