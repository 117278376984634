import React, { useEffect, useState } from "react";
import { useCardCountSummary } from "../../../services/dashboard";
import ChartDropDown from "../../chartDropDown";
import { PieChart } from "../pieChart";
import { ICardCountResponse } from "../../../interface/dashboard";

const CardCountChart = () => {
  let date = new Date();
  let rangeStartDate = `${date.getDate() - 5}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let rangeEndDate = new Date().toLocaleDateString().replaceAll("/", "-");

  const [openDropDown, setopenDropDown] = useState<boolean>(false);
  const [period, setPeriod] = useState("Today");
  const [cardCountSummary, setCardCountSummary] =
    useState<ICardCountResponse>();
  const [range, setRange] = useState<{ startDate: string; endDate: string }>({
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  const { data } = useCardCountSummary(period === "Today" ? {} : { ...range });

  const totalSum =
    cardCountSummary?.creation! +
    cardCountSummary?.topUp! +
    cardCountSummary?.withdraw!;

  const pieChartData = {
    labels: ["Creation", "TopUp", "Withdraw"],
    datasets: [
      {
        data: [
          cardCountSummary?.creation!,
          cardCountSummary?.topUp!,
          cardCountSummary?.withdraw!,
        ],
        backgroundColor: ["#ff6384", "#36a2eb", "#ffce56"],
      },
    ],
  };

  useEffect(() => {
    if (period === "Today") {
      setCardCountSummary(data?.data!);
    }
    if (period === "Range") {
      setCardCountSummary(data?.data!);
    }
  }, [data, period]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">
          Card Count{" "}
          <span className="font-normal text-xs"> ({totalSum || 0})</span>
        </h3>
        <ChartDropDown
          open={openDropDown}
          setOpen={setopenDropDown}
          value={period}
          setValue={setPeriod}
          data={periodArray}
          range={range!}
          setRange={setRange}
        />
      </div>
      <div className=" h-[320px] w-full">
        <PieChart data={pieChartData} />
      </div>
    </div>
  );
};

export default CardCountChart;

const periodArray = [
  {
    label: "Today",
  },
  {
    label: "Range",
  },
];
