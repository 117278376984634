import { useState } from 'react';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { IUserProps } from '../../../../interface/user';
import { useGetUserNIN } from '../../../../services/user';

const ViewNin = ({ user }: IUserProps) => {
  const [open, setOpen] = useState(false);

  const { data } = useGetUserNIN({
    enabled: user?.verification.nin ? true : false,
    id: user?._id,
  });

  return (
    <>
      <div className=" w-auto">
        <Button
          onClick={() => {
            setOpen(true);
          }}
          bgColor="bg-activeLinkBlack"
          disabled={user?.verification.nin ? false : true}
          className={`cursor-pointer ${
            user?.verification.nin ? '' : 'opacity-60'
          }`}>
          NIN
        </Button>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        padding={false}
        width="medium"
        header
        title="NIN">
        <div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Customer</small>
              <small>{`${data?.data.firstName} ${data?.data.lastName}`}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Identity</small>
              <small>{data?.data.identity}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Gender</small>
              <small>{data?.data.gender}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Email</small>
              <small>{data?.data.email}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Date of birth</small>
              <small>{data?.data.dob}</small>
            </div>
          </div>
          <div className="flex justify-between py-4 px-4">
            <small>Phone</small>
            <small>{data?.data.phone}</small>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewNin;
