import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { Campaign } from '../../../../../interface/mail-campaigns';
import { Modal } from '../../../../../components/modal';

interface CampaignProps {
  campaign: Campaign;
}

const ViewCampaign: FC<CampaignProps> = ({ campaign }) => {
  const [open, setOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState<number>(0.9);

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          View Campaign
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        width="large"
        close={() => {
          setOpen(false);
        }}
        title={'View Campaign'}
        header={true}
      >
        <div className="py-4 relative">
          <div className="flex space-x-2 absolute top-8 left-6 z-[5]">
            <div
              className="flex justify-center items-center bg-activeLinkBlack rounded-full h-5 w-5 text-xl cursor-pointer"
              onClick={() => setZoomLevel((prev) => Math.max(0.5, prev - 0.1))}
            >
              -
            </div>
            <span className="text-activeLinkBlack">
              {(zoomLevel * 100).toFixed(0)}%
            </span>
            <div
              className="flex justify-center items-center bg-activeLinkBlack rounded-full h-5 w-5 text-xl cursor-pointer"
              onClick={() => setZoomLevel((prev) => Math.min(2, prev + 0.1))}
            >
              +
            </div>
          </div>
          <div
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: 'top left',
              width: `${100 / zoomLevel}%`,
            }}
          >
            <iframe
              srcDoc={campaign.body}
              style={{ width: '100%', height: '100vh', border: 'none' }}
              title="HTML Preview"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewCampaign;
