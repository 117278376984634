import UserCard from "../../../../components/userCard";
import { useGetUserCards } from "../../../../services/cards";
import { IUser } from "../../../../interface/user";
import CreateCard from "../../../../components/createCard";

const Cards = ({ user }: { user: IUser }) => {
  const { data, refetch } = useGetUserCards(user._id);

  return (
    <>
      <div className="  mb-6 flex items-center gap-3">
        <small className="text-lightGray2 border-r border-grey pr-3">
          Total Cards Created ({data?.data.length})
        </small>
        <small className="text-lightGray2">Total Amount</small>
        <CreateCard userId={user._id} />
      </div>
      <div className="rounded-md font-normal flex items-center gap-4 flex-wrap justify-center lg:justify-start">
        {data?.data.map((item, index) => (
          <UserCard key={index} card={item} refetchCard={refetch} />
        ))}
      </div>
    </>
  );
};

export default Cards;
