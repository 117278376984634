import { AxiosError, AxiosResponse } from 'axios';
import { IList, IResponse } from '../interface/response';
import Axios from './axios';
import { useMutation, useQuery } from 'react-query';
import { useToast } from '../context/toast';
import { NotificationResponse } from '../interface/notifications';

const getAllNotifications = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<NotificationResponse>>> =
    await Axios.get('/notification/admin', {
      params,
    });
  return response.data;
};

const createNotifications = async (payload: FormData) => {
  const res: AxiosResponse<IResponse<any>> = await Axios.post(
    '/notification',
    payload
  );
  return res;
};

const editNotifications = async ({
  id,
  payload,
}: {
  id: string;
  payload: FormData;
}) => {
  const res: AxiosResponse<IResponse<any>> = await Axios.patch(
    `/notification/${id}`,
    payload
  );
  return res;
};

const deleteNotifications = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.delete(
    `/notification/${id}`
  );
  return response.data;
};

export const useGetNotifications = (params: object) => {
  const { addToast } = useToast();
  return useQuery(
    ['notifications', params],
    () => getAllNotifications(params),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse<any>>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || 'Error fetching notifications',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
    }
  );
};

export const useCreateNotifications = () => {
  const { addToast } = useToast();

  return useMutation(createNotifications, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error creating notifications',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useEditNotifications = () => {
  const { addToast } = useToast();

  return useMutation(editNotifications, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating notifications',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useDeleteNotifications = () => {
  const { addToast } = useToast();
  return useMutation(deleteNotifications, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error deleting notifications',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};
