import { useState } from 'react';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import ItemCard from '../../../../../components/itemCard';
import { Modal } from '../../../../../components/modal';
import Switch from '../../../../../components/switch';
import { useToast } from '../../../../../context/toast';
import {
  ILimitedReg,
  ISettingsResponse,
} from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import { Close } from '../../../../../components/icons';

interface IBusinessRegInterface {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const BusinessReg = ({ settingsData, refetch }: IBusinessRegInterface) => {
  const businessReg = settingsData?.businessReg;
  const [active, setActive] = useState<boolean>(businessReg?.active ?? false);
  const [open, setOpen] = useState(false);
  const [openLiability, setOpenLiability] = useState(false);
  const [businessNameReg, setBusinessNameReg] = useState('');
  const [limitedLabilityInputs, setLimitedLabilityInputs] = useState<
    ILimitedReg[]
  >(businessReg?.limitedReg ?? []);

  const { addToast } = useToast();
  const { mutate, isLoading } = useUpdateSettingsData();

  const handleActiveChange = () => {
    if (isLoading) return;
    try {
      setActive(!active);
      mutate(
        {
          businessReg: {
            ...businessReg,
            active: !businessReg?.active,
          },
        },
        {
          onSuccess: () => {
            refetch();
            addToast({ msg: 'Successfully Updated', type: 'success' });
          },
          onError: (error) => {
            setActive(!active);
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };

  const submit = () => {
    mutate(
      {
        businessReg: {
          ...businessReg,
          businessNameReg: parseInt(businessNameReg),
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const handleInputsChange = (
    value: string,
    inputName: string,
    index: number
  ) => {
    setLimitedLabilityInputs((prevState) => {
      const updatedInputs: ILimitedReg[] = [...prevState];
      if (inputName === 'capital') {
        updatedInputs[index].capital = parseInt(value);
      } else if (inputName === 'amount') {
        updatedInputs[index].amount = parseInt(value);
      }
      return updatedInputs;
    });
  };

  const submitLiability = () => {
    mutate(
      {
        businessReg: {
          ...businessReg,
          limitedReg: limitedLabilityInputs,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpenLiability(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
        <h5>Activate Business Registration</h5>
        <Switch checked={active} onCheck={handleActiveChange} />
      </div>
      <div className="rounded-md bg-dark mb-6" onClick={() => setOpen(true)}>
        <ItemCard
          title="Business Name Registration"
          hint={businessReg?.businessNameReg}
        />
      </div>

      <div
        className="rounded-md bg-dark mb-6"
        onClick={() => setOpenLiability(true)}
      >
        <ItemCard title="Limited Liabilty" />
      </div>

      <Modal
        isVisible={open}
        close={setOpen}
        title={'Business Name Registration'}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <Input
              defaultValue={businessReg?.businessNameReg}
              onChange={(e) => setBusinessNameReg(e.target.value)}
            />
          </div>
        </div>
      </Modal>

      {limitedLabilityInputs.length > 0 && openLiability && (
        <Modal
          isVisible={openLiability}
          close={setOpenLiability}
          title={'Limited Liabililty'}
          padding={false}
          center
          width="medium"
          header
          showFooter
          footer={
            <div className="flex justify-end">
              <Button
                onClick={submitLiability}
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          }
        >
          <div className="px-4">
            <div className="grid grid-cols-[50%_50%] py-2 mb-2">
              <h5>Capital</h5>
              <h5>Amount</h5>
            </div>
            {limitedLabilityInputs.map((item: ILimitedReg, index) => (
              <div
                className="grid grid-cols-[50%_50%] items-center gap-1 mb-1"
                key={index}
              >
                <Input
                  defaultValue={item.capital}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, 'capital', index)
                  }
                />
                <Input
                  defaultValue={item.amount}
                  onChange={(e) =>
                    handleInputsChange(e.target.value, 'amount', index)
                  }
                />
              </div>
            ))}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BusinessReg;
