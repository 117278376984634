export interface NotificationResponse {
  _id: string;
  title: string;
  body: string;
  active: boolean;
  type: string;
  count: number;
  url: string;
  photo?: string;
  isDeleted: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface CreateNotification {
  title: string;
  body: string;
  url?: string;
  type: string;
  image?: File;
  cta?: string;
  webUrl?: string;
}

export enum StatusEnum {
  true = 'true',
  false = 'false',
}

export enum NotificationTypeEnum {
  BANNER = 'BANNER',
  INFO = 'INFO',
}
