import React, { useState } from 'react';
import { Modal } from '../../../../components/modal';
import { IBusinessDetailsprops } from '../../../../interface/business';
import Button from '../../../../components/button';

const Socials = ({ business }: IBusinessDetailsprops) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <Button
        bgColor="bg-activeLinkBlack"
        className="cursor-pointer"
        onClick={() => setOpen(true)}>
        Socials
      </Button>

      <Modal
        isVisible={open}
        close={setOpen}
        header
        center
        title="Socials"
        padding={false}>
        <div>
          <div className="flex flex-col py-4 px-4">
            <div className="flex justify-between py-4">
              <small>instagram</small>
              <small> {business?.social?.instagram ?? '----'} </small>
            </div>
            <div className="flex justify-between py-4">
              <small>Facebook</small>
              <small> {business?.social?.facebook ?? '----'} </small>
            </div>
            <div className="flex justify-between py-4">
              <small>Twitter</small>
              <small> {business?.social?.twitter ?? '----'} </small>
            </div>
            <div className="flex justify-between py-4">
              <small>Whatsapp</small>
              <small> {business?.social?.whatsapp ?? '----'} </small>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Socials;
