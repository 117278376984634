import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../../../components/dropdown';
import { Search } from '../../../components/icons';
import Input from '../../../components/input';
import Pagination from '../../../components/pagination';
import { ITxnFilter } from '../../../interface/txn';
import { useBusiness, useBusinessReg } from '../../../services/business';
import { useDashboardCount } from '../../../services/dashboard';
import { capitalized } from '../../../utils/capitalize';
import { isCurrency } from '../../../utils/isCurrency';
import BusinessDetails from './details/index';
import useUrlPagination from '../../../utils/urlPagination';
import { INewBusinessResponse } from '../../../interface/business';
import { StatusEnum } from '../../../utils/constants';
import RegDetails from './regDetails';
import RejectRegDetails from './regDetails/rejectRegDetails';
import AcceptRegDetails from './regDetails/acceptRegDetails';

const Business = () => {
  const [size, setSize] = useState(10);
  const [filter, setFilter] = useState<ITxnFilter>();
  const [activeTab, setActiveTab] = useState<string>('business setup');
  const tabItems = ['business setup', 'new business'];

  const { data } = useDashboardCount();
  const { currentPage, setPage } = useUrlPagination();

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  const {
    data: business,
    isLoading,
    refetch,
  } = useBusiness({
    currentPage: activeTab === 'business setup' ? currentPage : 1,
    size,
    ...filter,
  });
  const {
    data: businessReg,
    isLoading: isLoadingBusinessReg,
    refetch: refetchBusinessReg,
  } = useBusinessReg({
    currentPage: activeTab === 'new business' ? currentPage : 1,
    size,
    ...filter,
  });

  const dropdown = [
    { label: 'Active', value: true },
    { label: 'Inactive', value: false },
  ];

  const suspendDropdown = [
    { label: 'Active', value: false },
    { label: 'Inactive', value: true },
  ];

  return (
    <>
      <h2 className="font-medium text-grayText pb-4 ">
        Business ({business?.data.pagination.total.toLocaleString()})
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
        <div className="grid md:grid-cols-2  lg:grid-cols-4 gap-4">
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">Start</span>}
            onChange={(e) =>
              setFilter({ ...filter, startDate: e.target.value.trim() })
            }
          />
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">End</span>}
            onChange={(e) =>
              setFilter({ ...filter, endDate: e.target.value.trim() })
            }
          />
          <Dropdown
            label="Suspend"
            option={suspendDropdown}
            onChange={(e) => setFilter({ ...filter, suspend: e?.value })}
          />
          <Dropdown
            label="Approved"
            option={dropdown}
            onChange={(e) => setFilter({ ...filter, approved: e?.value })}
          />
        </div>
        <div className=" relative w-full">
          <Input
            type="text"
            placeholder="search"
            leading={<Search />}
            onChange={(e) =>
              setFilter({ ...filter, search: e.target.value.trim() })
            }
          />
        </div>
      </div>

      <div className="flex gap-4 mb-4 font-normal">
        <small className="text-lightGray2">Business Amount</small>
        <small className="text-grayText">
          ₦{isCurrency(data?.data.businessWallet ?? 0)}
        </small>
      </div>
      <div className="flex gap-4 text-sm mb-4 items-center">
        {tabItems.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              setActiveTab(item);
              setPage(1);
            }}
            className={`${
              activeTab === item
                ? 'text-white bg-activeLinkBlack font-medium px-3 py-[.35rem] mx-1 rounded-md'
                : 'text-grayText'
            } cursor-pointer`}
          >
            {capitalized(item)}
          </div>
        ))}
      </div>

      {activeTab === 'business setup' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto  tableScrollBar">
            <div className="inline-block min-w-full">
              <div className="overflow-x-auto">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Name
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Type
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Approved
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Account Number
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Account Name
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Wallet
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Status
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Customer
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        More
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoading &&
                      business?.data.response.map((item, index) => (
                        <tr key={index} className={'border-b border-grey'}>
                          <td className="text-sm p-4">
                            {item.createdAt &&
                              format(
                                new Date(item.createdAt),
                                'MMM do, yyyy H:mma'
                              )}
                          </td>
                          <td className="text-sm p-4">
                            {capitalized(item.name)}
                          </td>
                          <td className="text-sm p-4">
                            {capitalized(item.type)}
                          </td>

                          <td className="text-sm p-4">
                            {item.approved ? (
                              <div className="text-green-500">Yes</div>
                            ) : (
                              <div className="text-red-500">No</div>
                            )}
                          </td>
                          <td className="text-sm p-4">
                            {item.account[0]?.accountNumber ?? '----'}
                          </td>
                          <td className="text-sm p-4">
                            {item.account[0]?.accountName ?? '----'}
                          </td>
                          <td className="text-sm p-4">
                            ₦{isCurrency(item.wallet ?? 0)}
                          </td>
                          <td className="text-sm p-4">
                            {item.suspend ? (
                              <div className="text-red-500">Inactive</div>
                            ) : (
                              <div className="text-green-500">Active</div>
                            )}
                          </td>
                          <td className="text-sm p-4 text-blue">
                            <Link
                              to={`/app/user/${item?.user?._id}`}
                            >{`${item?.user?.firstName} ${item?.user?.lastName}`}</Link>
                          </td>
                          <td className=" p-4">
                            {/* <OldBusinessDetails item={item} refetch={refetch} /> */}
                            <BusinessDetails
                              business={item}
                              refetch={refetch}
                            />
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {business?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={business?.data.pagination.total!}
              itemsPerPage={size}
              setPage={setPage}
              page={currentPage}
              setSize={setSize}
            />
          )}
        </div>
      )}

      {activeTab === 'new business' && (
        <div className="rounded-md font-normal bg-dimGray border border-grey">
          <div className="overflow-x-auto  tableScrollBar">
            <div className="inline-block min-w-full">
              <div className="overflow-x-auto">
                <table className="table-auto min-w-full">
                  <thead className="bg-lightGray">
                    <tr className="border-b border-grey">
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Date
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Status
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        Customer
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50">
                        More
                      </th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                      <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoadingBusinessReg &&
                      businessReg?.data.response.map(
                        (item: INewBusinessResponse, index) => (
                          <tr key={index} className={'border-b border-grey'}>
                            <td className="text-sm p-4">
                              {item.createdAt &&
                                format(
                                  new Date(item.createdAt),
                                  'MMM do, yyyy H:mma'
                                )}
                            </td>
                            <td className="text-sm p-4">
                              {item.process === StatusEnum.FAILED && (
                                <span className="text-red-500">
                                  {capitalized(StatusEnum.FAILED)}
                                </span>
                              )}

                              {item.process === StatusEnum.SUCCESS && (
                                <span className="text-green-500">
                                  {capitalized(StatusEnum.SUCCESS)}
                                </span>
                              )}
                              {item.process === StatusEnum.PENDING && (
                                <span className=" text-amber-500">
                                  {capitalized(StatusEnum.PENDING)}
                                </span>
                              )}
                            </td>
                            <td className="text-sm p-4 text-blue">
                              <Link
                                to={`/app/user/${item?.user?._id}`}
                              >{`${item?.user?.firstName} ${item?.user?.lastName}`}</Link>
                            </td>
                            <td className="text-sm p-4 cursor-pointer">
                              <RegDetails item={item} />
                            </td>
                            <td className="text-sm p-4 cursor-pointer">
                              <AcceptRegDetails
                                item={item}
                                refetch={refetchBusinessReg}
                              />
                            </td>
                            <td className="text-sm p-4 cursor-pointer">
                              <RejectRegDetails
                                item={item}
                                refetch={refetchBusinessReg}
                              />
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {businessReg?.data.pagination.total! > 0 && (
            <Pagination
              totalItems={businessReg?.data.pagination.total!}
              itemsPerPage={size}
              setPage={setPage}
              page={currentPage}
              setSize={setSize}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Business;
