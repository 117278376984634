import React, { useState } from 'react';
import { IPayoneerResponse } from '../../../../interface/payoneer';
import { Link } from 'react-router-dom';
import { Modal } from '../../../../components/modal';
import { capitalized } from '../../../../utils/capitalize';
import { StatusEnum } from '../../../../utils/constants';
import { isCurrency } from '../../../../utils/isCurrency';

interface IProps {
  item: IPayoneerResponse;
}

const PayoneerDetails = ({ item }: IProps) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          Details
        </Link>
      </div>

      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title="Payoneer details"
        header={true}
      >
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Status</small>
            <small>
              {item.status === StatusEnum.FAILED && (
                <span className="text-red-500">
                  {capitalized(StatusEnum.FAILED)}
                </span>
              )}
              {item.status === StatusEnum.REFUNDED && (
                <span className="text-red-500">
                  {capitalized(StatusEnum.REFUNDED)}
                </span>
              )}
              {item.status === StatusEnum.SUCCESS && (
                <span className="text-green-500">
                  {capitalized(StatusEnum.SUCCESS)}
                </span>
              )}
              {item.status === StatusEnum.PENDING && (
                <span className=" text-amber-500">
                  {capitalized(StatusEnum.PENDING)}
                </span>
              )}
              {item.status === StatusEnum.PROCESSING && (
                <span className=" text-amber-500">
                  {capitalized(StatusEnum.PROCESSING)}
                </span>
              )}
              {item.status === StatusEnum.INITIATED && (
                <span className=" text-gray-500">
                  {capitalized(StatusEnum.INITIATED)}
                </span>
              )}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Customer</small>
            <Link
              to={`/app/user/${item.user?._id}`}
              onClick={() => setOpen(false)}
            >
              <small className="text-blue">{`${item.user?.firstName} ${item.user?.lastName}`}</small>
            </Link>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Amount</small>
            <small>₦{isCurrency(item.amount)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Amount To Recieve</small>
            <small>₦{isCurrency(item.amountToReceive)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Business</small>
            <small>{item.business}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Fee</small>
            <small>₦{isCurrency(item.fee)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>From currency</small>
            <small>{item.fromCurrency}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>To currency</small>
            <small>{item.toCurrency}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Rate</small>
            <small>₦{isCurrency(item.rate)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Reference</small>
            <small>{item.reference}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Remark</small>
            <small>{item.remark}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>IP Address</small>
            <small>{item.ipAddress ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4 gap-4">
            <small>Device Info</small>
            <small
              className="text-right "
              style={{ whiteSpace: 'break-spaces' }}
            >
              {item.deviceIdInfo ?? '----'}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>App Version</small>
            <small>{item.appVersion ?? '----'}</small>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PayoneerDetails;
