import { useEffect, useState } from 'react';
import Switch from '../../../../../components/switch';
import { useToast } from '../../../../../context/toast';
import { ISettingsResponse } from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';

interface IProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const General = ({ settingsData, refetch }: IProps) => {
  const generalData = settingsData?.figur;
  const [active, setActive] = useState<boolean>(
    generalData?.emailVerification ?? false
  );
  const [kycActive, setKycActive] = useState<boolean>(
    generalData?.livelinessKYC ?? false
  );
  const { mutate } = useUpdateSettingsData();
  const { addToast } = useToast();

  const toggleEmail = () => {
    setActive(!active);
    mutate(
      {
        figur: {
          ...generalData,
          emailVerification: !generalData?.emailVerification,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const toggleKyc = () => {
    setKycActive(!kycActive);
    mutate(
      {
        figur: {
          ...generalData,
          livelinessKYC: !generalData?.livelinessKYC,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  useEffect(() => {
    if (generalData) {
      setActive(generalData.emailVerification);
      setKycActive(generalData.livelinessKYC);
    }
  }, [generalData, setActive, setKycActive]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Email Verification</h5>
        <Switch checked={active} onCheck={toggleEmail} />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Liveliness KYC</h5>
        <Switch checked={kycActive} onCheck={toggleKyc} />
      </div>
    </div>
  );
};

export default General;
