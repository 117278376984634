import React, { useContext, useState } from 'react';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import {
  IUserProps,
  IUserVerficationResponse,
} from '../../../../interface/user';
import {
  useGetUserVerificationData,
  useUser,
  useVerifyUserBvnOrNin,
} from '../../../../services/user';
import Input from '../../../../components/input';
import ToastContext from '../../../../context/toast';

const VerifyBvn = ({ user }: IUserProps) => {
  const [open, setOpen] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [openAccessKeyModal, setOpenAccessKeyModal] = useState(false);
  const [accessKey, setAccessKey] = useState<string>('');
  const [identity, setIdentity] = useState<string>('');
  const { addToast } = useContext(ToastContext);

  const { refetch } = useUser(user?._id);
  const { data } = useGetUserVerificationData({
    _id: user?._id,
    purpose: 'BVN',
  });
  const { mutate, isLoading } = useVerifyUserBvnOrNin();

  const submit = () => {
    if (!accessKey) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
    } else {
      mutate(
        { identity, secret: accessKey },
        {
          onSuccess: () => {
            refetch();
            setOpenAccessKeyModal(false);
            addToast({
              msg: 'Successfully Updated',
              type: 'success',
            });
          },
        }
      );
    }
  };

  return (
    <>
      <div className=" w-auto">
        <Button
          bgColor="bg-dimGray"
          onClick={() => {
            setOpen(true);
          }}
        >
          Verify
        </Button>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        padding={false}
        width="medium"
        header
        title="Verify BVN"
      >
        <div className="p-4">
          <div className="flex flex-col gap-2">
            {data && data?.data.data.length > 0 ? (
              data?.data.data.map((item: IUserVerficationResponse) => (
                <div
                  key={item._id}
                  className="border border-grey rounded-md bg-dimGray py-2 px-4 flex items-center justify-between"
                >
                  <div className="flex flex-col gap-2">
                    <h6>
                      {`${item.data.entity.first_name} ${item.data.entity.last_name}`}
                    </h6>
                    <div className="flex gap-2 items-center">
                      <h6>{item.identity}</h6>
                      <span className="text-xs bg-black p-1 items-center justify-center flex rounded-md">
                        {item.data.entity.date_of_birth}
                      </span>
                      <span className="text-xs bg-black p-1 items-center justify-center flex rounded-md">
                        {item.data.entity.phone_number1}
                      </span>
                    </div>
                  </div>
                  <Button
                    className="!py-1 px-3"
                    onClick={() => {
                      setIdentity(item.identity);
                      setOpen(false);
                      setOpenAccessKeyModal(true);
                    }}
                  >
                    Verify
                  </Button>
                </div>
              ))
            ) : (
              <div className="border border-grey rounded-md bg-dimGray py-2 px-4 flex items-center justify-center">
                <h6>No records found</h6>
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        padding={false}
        isVisible={openAccessKeyModal}
        center
        close={setOpenAccessKeyModal}
        title="Enter Access Key"
        header
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="p-4">
          <p className="pb-2">
            <small className="w-1/4 text-textDimGray">Access key</small>
          </p>
          <Input
            name="secret"
            type={showToken ? 'text' : 'password'}
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            trailing={
              <p
                onClick={() => setShowToken(!showToken)}
                className="text-sm text-blue"
              >
                Show
              </p>
            }
          />
        </div>
      </Modal>
    </>
  );
};

export default VerifyBvn;
