import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthLayout from "../components/layout/auth";
import Login from "../page/auth";
import AccessToken from "../page/auth/accessToken";
import Main from "../page/main";
import AuthGuard from "./authGard";
import ProtectedRoute from "./protectedRoute";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="app/*"
        element={
          <ProtectedRoute>
            <Main />
          </ProtectedRoute>
        }
      />
      <Route path="/" element={<Navigate replace to="/app" />} />

      <Route
        path="auth"
        element={
          <AuthGuard>
            <AuthLayout />
          </AuthGuard>
        }
      >
        <Route index element={<Login />} />
      </Route>

      <Route
        path="token"
        element={
          <AuthGuard>
            <AuthLayout />
          </AuthGuard>
        }
      >
        <Route index element={<AccessToken />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
