import React, {
  ButtonHTMLAttributes,
  FC,
  ReactElement,
  ReactNode,
} from "react";
import { Loading } from "../icons";

export interface IButton {
  children?: ReactNode;
  loading?: boolean;
  bgColor?: string;
  className?: string;
  icon?: ReactElement;
  iconright?: ReactElement;
  iconLeft?: ReactElement;
}
const Button: FC<IButton & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  loading,
  bgColor = "bg-blue",
  className,
  icon,
  iconLeft,
  iconright,
  ...rest
}) => {
  return (
    <button
      disabled={loading}
      className={`${bgColor} ${className} ${
        iconright || iconLeft || icon
          ? " flex items-center justify-center gap-x-2"
          : ""
      } px-4 py-sm font-normal text-white rounded-md ${
        loading ? "opacity-60" : ""
      }`}
      {...rest}
    >
      {loading ? <Loading width={24} height={24} /> : children}
      {iconright}
    </button>
  );
};

export default Button;
