import React, { useState } from 'react';
import { useGetPendingKyc } from '../../../../services/user';
import useUrlPagination from '../../../../utils/urlPagination';
import { Link } from 'react-router-dom';
import { Back } from '../../../../components/icons';
import Pagination from '../../../../components/pagination';
import { format } from 'date-fns';
import ViewKyc from './viewKyc';
import { IPendingKycResponse } from '../../../../interface/user';

const PendingKyc = () => {
  const [size, setSize] = useState(10);
  const { currentPage, setPage } = useUrlPagination();
  const [purpose, setPurpose] = useState('BVN');

  const { data, isLoading, refetch } = useGetPendingKyc({
    page: currentPage,
    size,
    purpose,
  });

  return (
    <>
      <div className="flex justify-between items-center pb-4">
        <div className="flex-1">
          <div className="flex gap-4 items-center">
            <Link to={'/app/user'} className="p-2 bg-lightGray rounded-md">
              <Back />
            </Link>
            <h2 className="font-medium">
              Pending KYC ({data?.data.pagination.total})
            </h2>
          </div>
        </div>
      </div>

      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto  tableScrollBar">
          <div className=" inline-block min-w-full ">
            <div className=" overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-lightGray">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Email Address
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Phone Number
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      BVN
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Registered
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                {!isLoading && (
                  <tbody>
                    {data?.data.data.map((item: IPendingKycResponse, index) => (
                      <tr key={index} className={'border-b border-grey'}>
                        <td className="text-sm p-4">
                          {item.user.firstName} {item.user.lastName}
                        </td>

                        <td className="text-sm p-4">{item.user.email}</td>
                        <td className="text-sm p-4">
                          {`${item.user.dialCode}${item.user.phone}`}
                        </td>
                        <td className="text-sm p-4">
                          {item.user.verification?.bvn ? (
                            <div className="text-green-500">Yes</div>
                          ) : (
                            <div className="text-red-500">No</div>
                          )}
                        </td>
                        <td className="text-sm p-4">
                          <div>
                            {format(
                              new Date(item.user.createdAt),
                              'MMM do, yyyy H:mma'
                            )}
                          </div>
                        </td>
                        <td className="text-sm p-4">
                          {item.user.suspend ? (
                            <div className="text-red-500">Inactive</div>
                          ) : (
                            <div className="text-green-500">Active</div>
                          )}
                        </td>

                        <td className="text-sm p-4">
                          <ViewKyc details={item} refetch={refetch} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>{' '}
        {data?.data.pagination.total! > 0 && (
          <Pagination
            totalItems={data?.data.pagination.total!}
            itemsPerPage={size}
            setPage={setPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </>
  );
};

export default PendingKyc;
