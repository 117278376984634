import React, { useRef, useState } from 'react';
import {
  IBusinessReg,
  INewBusinessResponse,
} from '../../../../interface/business';
import { useUpdateBusinessReg } from '../../../../services/business';
import { StatusEnum } from '../../../../utils/constants';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import Input from '../../../../components/input';
import FileUpload from '../../../../components/fileUpload';

const AcceptRegDetails = ({
  item,
  refetch,
}: {
  item: INewBusinessResponse;
  refetch: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [regDetails, setRegDetails] = useState<IBusinessReg>(
    {} as IBusinessReg
  );

  const { mutate, isLoading } = useUpdateBusinessReg();

  const onSelectCertificate = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setRegDetails({ ...regDetails, certificate: e.target.files[0] });
    }
  };

  const onSelectMermat = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setRegDetails({ ...regDetails, mermat: e.target.files[0] });
    }
  };

  const onSelectUtility = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setRegDetails({ ...regDetails, utilityBill: e.target.files[0] });
    }
  };

  const onSelectApplication = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setRegDetails({ ...regDetails, application: e.target.files[0] });
    }
  };

  const onSelectIdCard = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setRegDetails({ ...regDetails, idCard: e.target.files[0] });
    }
  };

  const submit = () => {
    const formData = new FormData();
    formData.append('status', StatusEnum.SUCCESS);
    for (const value in regDetails) {
      formData.append(value, String(regDetails[value as keyof IBusinessReg]));
    }
    mutate(
      { id: item._id, body: formData },
      {
        onSuccess: () => {
          setOpen(false);
          refetch();
        },
      }
    );
  };

  return (
    <div>
      <div className=" w-auto">
        <div className="text-blue" onClick={() => setOpen(true)}>
          Approve
        </div>
      </div>
      <Modal
        isVisible={open}
        title="Approve Business"
        header
        close={setOpen}
        center
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <h5>Approved Name</h5>
            <Input
              type="text"
              onChange={(e) =>
                setRegDetails({ ...regDetails, approvedName: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Reg. Number</h5>
            <Input
              type="text"
              onChange={(e) =>
                setRegDetails({ ...regDetails, regNumber: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Tin Number</h5>
            <Input
              type="text"
              onChange={(e) =>
                setRegDetails({ ...regDetails, tin: e.target.value })
              }
            />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Certificate</h5>
            <FileUpload
              onSelectFile={onSelectCertificate}
              uploadFile={regDetails.certificate}
            />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Mermat</h5>
            <FileUpload onSelectFile={onSelectMermat} />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Aplication</h5>
            <FileUpload onSelectFile={onSelectApplication} />
          </div>
          <div className="flex flex-col gap-1">
            <h5>Utility Bill</h5>
            <FileUpload onSelectFile={onSelectUtility} />
          </div>
          <div className="flex flex-col gap-1">
            <h5>ID Card</h5>
            <FileUpload onSelectFile={onSelectIdCard} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AcceptRegDetails;
