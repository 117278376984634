import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useToast } from '../context/toast';
import { IResponse } from '../interface/response';
import {
  IGiftCardResponse,
  IProviders,
  ISettingsResponse,
  IUpdateAcct,
} from '../interface/settings';
import Axios from './axios';

const getSettingsData = async () => {
  const response: AxiosResponse<IResponse<ISettingsResponse>> = await Axios.get(
    '/store/settings'
  );
  return response.data;
};

const getGiftCardVouchers = async () => {
  const response: AxiosResponse<IResponse<IGiftCardResponse[]>> =
    await Axios.get('/gift-card-voucher');
  return response.data;
};

const createGiftCard = async (payload: FormData) => {
  const res: AxiosResponse<IResponse> = await Axios.post(
    '/gift-card-voucher',
    payload
  );
  return res.data;
};

const updateSettingsData = async (payload: any) => {
  const response: AxiosResponse<IResponse<ISettingsResponse>> =
    await Axios.patch('/store/settings/update', payload);
  return response.data;
};

const deleteGiftCard = async (id: string) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.delete(
    `/gift-card-voucher/${id}`
  );
  return response.data;
};

const getSMEData = async () => {
  const response: AxiosResponse<IResponse<IProviders>> = await Axios.get(
    '/store/cheap-data/plan'
  );
  return response.data;
};

const getAyinlakData = async (provider: string) => {
  const response: AxiosResponse<IResponse<IProviders>> = await Axios.get(
    `/store/cheap-data/plan?provider=${provider}`
  );
  return response.data;
};

const updateSMeProvider = async (payload: IProviders) => {
  const response: AxiosResponse<IResponse> = await Axios.patch(
    '/store/cheap-data/update',
    payload
  );
  return response.data;
};

const updateUserAccount = async (payload: IUpdateAcct) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    '/user/update/account',
    payload
  );
  return response.data;
};

const updateBusinessAccount = async (payload: IUpdateAcct) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    '/business/update/account',
    payload
  );
  return response.data;
};

const updateAyinlakProvider = async (payload: {
  provider: string;
  data: IProviders;
}) => {
  const response: AxiosResponse<IResponse> = await Axios.patch(
    `/store/cheap-data/update?provider=${payload.provider}`,
    payload.data
  );
  return response.data;
};

const updateGiftCard = async ({
  id,
  payload,
}: {
  id: string;
  payload: FormData;
}) => {
  const response: AxiosResponse<IResponse<ISettingsResponse>> =
    await Axios.patch(`/gift-card-voucher/${id}`, payload);
  return response.data;
};

export const useGetSettings = () => {
  const { addToast } = useToast();
  return useQuery('SettingsData', getSettingsData, {
    onError: (error) => {
      const err = error as AxiosError<IResponse<ISettingsResponse>>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching business',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetGiftCardVoucher = () => {
  const { addToast } = useToast();
  return useQuery('giftcard voucher', getGiftCardVouchers, {
    onError: (error) => {
      const err = error as AxiosError<IResponse<IGiftCardResponse[]>>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateSettingsData = () => {
  const { addToast } = useToast();

  return useMutation(updateSettingsData, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating information',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useDeleteGiftcard = () => {
  const { addToast } = useToast();

  return useMutation(deleteGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error deleting giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useCreateGiftCard = () => {
  const { addToast } = useToast();

  return useMutation(createGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error creating giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateGiftCard = () => {
  const { addToast } = useToast();

  return useMutation(updateGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetAyinlakData = (provider: string) => {
  const { addToast } = useToast();

  return useQuery(['ayinlak', provider], () => getAyinlakData(provider), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useGetSMEData = () => {
  const { addToast } = useToast();

  return useQuery(['sme'], () => getSMEData(), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useUpdateSMEData = () => {
  const { addToast } = useToast();

  return useMutation(updateSMeProvider, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating provder',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateUserAccount = () => {
  const { addToast } = useToast();

  return useMutation(updateUserAccount, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating user account',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateBusinessAccount = () => {
  const { addToast } = useToast();

  return useMutation(updateBusinessAccount, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating user account',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateAyinlakData = () => {
  const { addToast } = useToast();

  return useMutation(updateAyinlakProvider, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating provder',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};
