import { title } from 'process';
import React, { useContext, useState } from 'react';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import { Modal } from '../../../../../../components/modal';
import GlobalContext from '../../../../../../context/globalContext';

interface IEditGiftCard {
  title: string;
  defaultValue: string;
}

const EditCardName = ({ title, defaultValue }: IEditGiftCard) => {
  const [brandName, setBrandName] = useState<string>('');
  const { giftCardDetails, setGiftCardDetails } = useContext(GlobalContext);
  const [time, setTime] = useState<string>('');
  const [open, setOpen] = useState(false);

  const save = () => {
    setGiftCardDetails({
      ...giftCardDetails,
      brandName: title === 'Brand Name' ? brandName : time,
    });
    setOpen(false);
  };

  return (
    <div>
      <div className="text-blue cursor-pointer" onClick={() => setOpen(true)}>
        Change
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title={title}
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={save}>Save Changes</Button>
          </div>
        }
      >
        <div>
          <Input
            defaultValue={defaultValue}
            onChange={(e) => {
              title === 'Brand Name'
                ? setBrandName(e.target.value)
                : setTime(e.target.value);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default EditCardName;
