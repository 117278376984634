import React, { createContext, FC, ReactNode, useState } from 'react';
import { Constants } from '../utils/constant';
import { SecureStorage } from '../utils/storage';
import { ICurrencyProvider } from '../interface/settings';

const secureStorage = new SecureStorage();

const initState = {
  auth: secureStorage.getItem(Constants.token),
  setAuthAndCache: (v: string) => {},
  setLogout: () => {}, 
  secret:secureStorage.getItem(Constants.secret),
  setSecretToken:(value:string) => {}
};

const AuthContext = createContext(initState);
export const AuthProvider = AuthContext.Provider;

export const getDefaultAuth = () => {
  try {
    const token = secureStorage.getItem(Constants.token);
    return token;
  } catch (e) {
    return null;
  }
};

export const getDefaultSecret = () => {
  try {
    const token = secureStorage.getItem(Constants.secret);
    return token;
  } catch (e) {
    return null;
  }
};

//On user logout remove token from localstorage
export const setLogout = () => {
  secureStorage.removeItem(Constants.token);
  secureStorage.removeItem(Constants.secret);
  window.location.href = '/';
};

interface Props {
  children?: ReactNode;
}

export const AuthProviderContainer: FC<Props> = ({ children }) => {
  const defaultAuth = getDefaultAuth();
  const defaultSecret = getDefaultSecret()
  const [auth, setAuth] = useState<string | null>(defaultAuth);
  const [secret, setSecret] = useState<string | null>(defaultSecret);

  const setAuthAndCache = (value: string) => {
    value
      ? secureStorage.storeItem(Constants.token, value)
      : secureStorage.removeItem(Constants.token);

    setAuth(value);
  };

  const setSecretToken = (value: string) => {
    value
      ? secureStorage.storeItem(Constants.secret, value)
      : secureStorage.removeItem(Constants.secret);

      setSecret(value);
  };

  return (
    <AuthProvider value={{ auth, setAuthAndCache, setLogout, setSecretToken,secret }}>
      {children}
    </AuthProvider>
  );
};
export default AuthContext;
