import { format } from "date-fns";
import { useEffect, useState } from "react";
import EmptyImage from "../../../../../src/assets/image/emptyImage.png";
import Button from "../../../../components/button";
import Switch from "../../../../components/switch";
import {
  BvnSelfieStatusEnum,
  IUserDetailsProps,
} from "../../../../interface/user";
import {
  useGetCurrentUser,
  useGetUserBvn,
  useGetUserNIN,
  useUser,
} from "../../../../services/user";
import { isCurrency } from "../../../../utils/isCurrency";
import BanUser from "./banUser";
import CreditUserWallet from "./creditUserWallet";
import DebitUserWallet from "./debitUserWallet";
import TrapUser from "./trapUser";
import VerifyBvn from "./verifyBvn";
import VerifyNin from "./verifyNin";
import ViewBvn from "./viewBvn";
import ViewNin from "./viewNin";
import Services from "./services";
import FundSweep from "./fundSweep";

const UserInfo = ({ user, txn }: IUserDetailsProps) => {
  const { data: currentUser } = useGetCurrentUser();
  const { refetch } = useUser(user._id);
  const { data } = useGetUserBvn({
    enabled: user?.verification.bvn,
    id: user?._id,
  });

  const { data: userNin } = useGetUserNIN({
    enabled: user?.verification.nin ? true : false,
    id: user?._id,
  });

  const [defaultImage, setDefaultImage] = useState<{
    image: string;
    type: "NIN" | "BVN" | "SELFIE";
  }>({
    image: data?.data.image
      ? `data:image/png;base64,${data?.data.image}`
      : EmptyImage,
    type: "BVN",
  });

  useEffect(() => {
    if (data?.data.image) {
      setDefaultImage({
        image: `data:image/png;base64,${data?.data.image}`,
        type: "BVN",
      });
    } else {
      setDefaultImage({ image: EmptyImage, type: "BVN" });
    }
  }, [data?.data.image]);

  const statusColor = () => {
    switch (user.verification.bvnSelfieStatus) {
      case BvnSelfieStatusEnum.APPROVED:
        return "text-green-700";

      case BvnSelfieStatusEnum.PENDING:
        return "text-orange-500";

      case BvnSelfieStatusEnum.REJECTED:
        return "text-red-500";

      case BvnSelfieStatusEnum.NOT_SUBMITTED:
        return "text-white";

      default:
        break;
    }
  };

  return (
    <div>
      <div className="font-normal grid grid-cols-1 lg:grid-cols-[60%_38%] items-center gap-[2%]">
        <div className="border border-grey rounded-md bg-dimGray ">
          <div className="flex flex-col md:flex-row items-center gap-6 bg-lightGray rounded-md p-8">
            <div className=" h-[205px] max-w-[220px] w-full relative">
              <img
                src={defaultImage.image}
                alt="user_image"
                className="h-full w-full object-cover rounded-lg"
              />
              <div className="bottom-[5%] absolute flex right-[5%] gap-2">
                {user.verification.bvnSelfieStatus !==
                  BvnSelfieStatusEnum.NOT_SUBMITTED && (
                  <div
                    className={`text-xs  p-1 items-center justify-center flex rounded-md cursor-pointer  ${
                      defaultImage.type === "SELFIE"
                        ? " bg-lightGray"
                        : "bg-black"
                    }`}
                    onClick={() =>
                      setDefaultImage(
                        user?.verification.bvnSelfie
                          ? {
                              image: `${user?.verification.bvnSelfie}`,
                              type: "SELFIE",
                            }
                          : { image: EmptyImage, type: "SELFIE" }
                      )
                    }
                  >
                    Selfie
                  </div>
                )}
                <div
                  className={`text-xs  p-1 items-center justify-center flex rounded-md cursor-pointer  ${
                    defaultImage.type === "BVN" ? " bg-lightGray" : "bg-black"
                  }`}
                  onClick={() =>
                    setDefaultImage(
                      data?.data.image
                        ? {
                            image: `data:image/png;base64,${data?.data.image}`,
                            type: "BVN",
                          }
                        : { image: EmptyImage, type: "BVN" }
                    )
                  }
                >
                  Bvn
                </div>
                <div
                  className={`text-xs  p-1 items-center justify-center flex rounded-md cursor-pointer  ${
                    defaultImage.type === "NIN" ? " bg-lightGray" : "bg-black"
                  }`}
                  onClick={() =>
                    setDefaultImage(
                      userNin?.data.image
                        ? {
                            image: `data:image/png;base64,${userNin?.data.image}`,
                            type: "NIN",
                          }
                        : { image: EmptyImage, type: "NIN" }
                    )
                  }
                >
                  Nin
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center flex-wrap gap-4  w-full">
                <p className="font-semibold text-white">
                  {user?.firstName + " " + user?.lastName}
                </p>
                <div className="bg-bColor py-1 px-2 gap-2 rounded-md text-[8px] flex ">
                  <div className="mx-2">
                    Selfie:{" "}
                    <span className={`${statusColor()}`}>
                      {user.verification.bvnSelfieStatus}
                    </span>
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    Suspend:{" "}
                    {user?.suspend ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    Trap:{" "}
                    {user?.trap.status ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    BVN:{" "}
                    {user?.verification.bvn ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    NIN:{" "}
                    {user?.verification.nin ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="rounded-md bg-xBlack mt-4">
                <p className=" p-3 border-b border-activeLinkBlack">
                  {user?.email}
                </p>
                <p className=" p-3 border-b border-activeLinkBlack">
                  {user?.dialCode! + user?.phone!}
                </p>
                {user?.createdAt && (
                  <p className=" p-3 border-b border-activeLinkBlack">
                    {format(new Date(user?.createdAt), "MMM do, yyyy H:mma")}
                  </p>
                )}
                {user?.address && (
                  <p className=" p-3 border-b border-activeLinkBlack">
                    {user?.address
                      ? `${user?.address.line1}, ${user?.address.city}, ${user?.address.state}`
                      : "----"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="m-6 flex flex-wrap gap-4 justify-between">
            <div className="flex flex-wrap gap-4">
              <TrapUser user={user} />
              <BanUser user={user} />
            </div>
            <FundSweep user={user} refetch={refetch} />
          </div>
        </div>
        <div className="border border-grey rounded-md bg-dimGray p-6 md:h-full">
          <p className=" pb-2">
            <small className="opacity-70">Verification</small>
          </p>

          <div className="flex flex-col gap-3">
            <div className="bg-activeLinkBlack rounded-normal p-5 flex items-center justify-between">
              <p>Bvn Verification</p>
              {user?.verification.bvn ? (
                <ViewBvn bvnDetails={data?.data!} />
              ) : (
                <VerifyBvn user={user} />
              )}
            </div>

            <div className="bg-activeLinkBlack rounded-normal p-5 flex items-center justify-between">
              <p>NIN Verification</p>
              {user?.verification.nin ? (
                <ViewNin ninDetails={userNin?.data!} />
              ) : (
                <VerifyNin user={user} />
              )}
            </div>

            <div className="bg-activeLinkBlack rounded-normal p-5 flex items-center justify-between">
              <p>Address</p>
              <div className="flex items-center gap-3">
                {user?.address ? <p>Yes</p> : <p>No</p>}
              </div>
            </div>
            <div className="bg-activeLinkBlack rounded-normal">
              <Services user={user} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row flex-wrap lg:flex-nowrap items-center gap-6 my-8 h-full">
        <div className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/4 flex-1 h-full">
          <small className="opacity-70">Figur Account</small>
          <h3 className="font-medium">
            ₦{isCurrency(user?.wallet! ? user?.wallet! : 0)}
          </h3>
          <div className="flex gap-2 mt-4">
            <>
              <CreditUserWallet
                user={user}
                refetch={refetch}
                role={currentUser?.data.role!}
              />
              <DebitUserWallet
                user={user}
                refetch={refetch}
                role={currentUser?.data.role!}
              />
            </>
          </div>
        </div>

        <div className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/4 flex-1 h-full ">
          <small className="opacity-70">Reserved Account</small>
          <h3 className="font-medium">
            ₦{isCurrency(user?.reservedAccount! ? user?.reservedAccount! : 0)}
          </h3>
          <div className="flex itens-center gap-2 mt-4">
            <Button
              className=" text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Credit
            </Button>
            <Button
              className=" text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Debit
            </Button>
          </div>
        </div>

        {user.account.map((item, index) => (
          <div
            className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/4 flex-1 h-full"
            key={index}
          >
            <small className="opacity-70">Account Number </small>
            {item.bvnVerified && (
              <span className="p-1 items-center justify-center rounded-md bg-green-500 text-xs">
                Verifed
              </span>
            )}
            <h3 className="font-medium">
              {item?.accountNumber ? `${item?.accountNumber}` : "----"}
            </h3>
            {item?.bankName && (
              <p className="text-md mt-6 truncate">{`${item?.bankName} (${item.accountName})`}</p>
            )}
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
        <div className="border border-grey rounded-md bg-dimGray p-4  w-full">
          <small className="opacity-70">Total Transaction</small>
          <h3 className="font-medium">{txn.pagination.total ?? 0}</h3>
        </div>

        <div className="border border-grey rounded-md bg-dimGray p-4  w-full">
          <small className="opacity-70">Total Transaction Amount </small>
          <h3 className="font-medium">----</h3>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
