import React, { FC, InputHTMLAttributes, ReactNode } from 'react';

export interface IProps {
  leading?: ReactNode;
  parentClassName?: string;
  trailing?: string | JSX.Element;
  submit?: () => void;
}

const Input: FC<IProps & InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  leading,
  parentClassName,
  trailing,
  submit,
  ...props
}) => {
  return (
    <div
      className={`flex items-center w-full relative bg-black text-white gap-2 px-2 py-3 rounded-lg ${parentClassName}`}>
      {leading && <div className="left-2">{leading}</div>}
      <input
        className={`bg-transparent outline-none w-full  ${className}`}
        {...props}
      />
      {trailing && (
        <div className="right-2 cursor-pointer" onClick={submit}>
          {trailing}
        </div>
      )}
    </div>
  );
};

export default Input;
