import { useState } from 'react'; 
import { useAccessToken } from '../../../services/auth';
import useForm from '../../../utils/useForm';
import { IAccessToken } from '../../../interface/auth';
import Input from '../../../components/input';
import Button from '../../../components/button';

const AccessToken = () => {
  const [showToken, setShowToken] = useState<boolean>(false);
  const { mutate, isLoading } = useAccessToken();

  const submit = () => mutate(inputs);
  const { handleChange, handleSubmit, inputs } = useForm<IAccessToken>(
    {} as IAccessToken,
    submit
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-dimGray rounded-lg px-8 py-10 flex flex-col gap-4 font-medium"
    > 
      <div className="mb-1">
        <p className="pb-2">
          <small className="w-1/4 text-textDimGray">Access key</small>
        </p>
        <Input
          name="secret"
          type={showToken ? 'text' : 'password'}
          value={inputs.secret}
          onChange={handleChange} 
          trailing={
            <p
              onClick={() => setShowToken(!showToken)}
              className="text-sm text-blue"
            >
              Show
            </p>
          }
        />
      </div>
      <Button type="submit" loading={isLoading} className="!py-3">
        Continue
      </Button>
    </form>
  );
};

export default AccessToken;
