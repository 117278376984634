import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Dropdown from '../../../../components/dropdown';
import { ArrowDown, ArrowUp } from '../../../../components/icons';
import Input from '../../../../components/input';
import { FullscreenModal } from '../../../../components/modal/fullscreenModal';
import {
    ISafeHavenTxn,
    TypeEnum
} from '../../../../interface/txn';
import {
    useSafeHavenTxnHistory
} from '../../../../services/transaction';
import { capitalized } from '../../../../utils/capitalize';
import { isCurrency } from '../../../../utils/isCurrency';
import { useGetVendorBalance } from '../../../../services/dashboard';
import { convertObjectToArray } from '../../../../utils/objectToArray';
import { IVendorBalanceArray } from '../../../../interface/dashboard';
import Button from '../../../../components/button';
import Requery from './requeryModal';

interface ISafeHavenTransaction {
  open: boolean;
  setOpen: (v: boolean) => void;
}

interface ITxnFilter {
  toDate?: string;
  fromDate?: string;
  type?: string;
}

const SafeHavenTransaction = ({ open, setOpen }: ISafeHavenTransaction) => {
  const [txnSize, setTxnSize] = useState<number>(100);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { data: vendorBalance } = useGetVendorBalance();

  const balanceArray = convertObjectToArray(vendorBalance?.data);
  const safeHavenItem = balanceArray.find(item => item.name === "safehaven");
  const safeHavenBalance = safeHavenItem ? safeHavenItem.value : 0;  

  const [filter, setFilter] = useState<ITxnFilter>();
  const { data, isLoading } = useSafeHavenTxnHistory({
    limit: txnSize,
    page: currentPage,
    ...filter,
  });

  const purposeOptions = Object.entries(TypeEnum).map(([label, value]) => ({
    label: capitalized(label).replace(/_/g, ' '),
    value,
  }));

  useEffect(() => {
    if (filter) setCurrentPage(0);
  }, [filter]);

  return (
    <FullscreenModal
      isVisible={open}
      close={() => {
        setOpen(false);
        setFilter(undefined);
        setCurrentPage(0);
      }}
      title="Safe Haven Transaction History"
      header
      loading={isLoading}
    >
      <h2 className="font-medium text-grayText pb-4">
        Balance (₦{isCurrency(safeHavenBalance)})
      </h2>
      <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">Start</span>}
            onChange={(e) =>
              setFilter({ ...filter, fromDate: e.target.value.trim() })
            }
          />
          <Input
            type="date"
            className="text-xs"
            leading={<span className="opacity-50">End</span>}
            onChange={(e) =>
              setFilter({ ...filter, toDate: e.target.value.trim() })
            }
          />
          <Dropdown
            label="Type"
            option={purposeOptions}
            onChange={(e) => setFilter({ ...filter, type: e?.value })}
          />
        </div>
      </div>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-activeLinkBlack ">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Amount
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Type
                    </th>

                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Narration
                    </th>

                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&  data?.data &&
                    data?.data?.map((item: ISafeHavenTxn) => (
                      <tr key={item._id} className={'border-b border-grey'}>
                        <td className="text-sm p-4">
                          {item.transactionDate &&
                            format(
                              new Date(item.transactionDate),
                              'MMM do, yyyy H:mma'
                            )}
                        </td>
                        <td
                          className={`${
                            item.type === TypeEnum.Credit
                              ? 'text-green-500'
                              : ''
                          } text-sm p-4`}
                        >
                          {item.type === TypeEnum.Credit ? '+ ' : '- '}₦
                          {isCurrency(item.amount)}
                        </td>
                        <td className="text-sm p-4 flex items-center gap-2">
                          <div
                            className={`p-1 items-center justify-center flex rounded-md ${
                              item.type === TypeEnum.Credit
                                ? 'bg-green-500'
                                : 'bg-red-500'
                            }`}
                          >
                            {item.type === TypeEnum.Credit ? (
                              <ArrowDown />
                            ) : (
                              <ArrowUp />
                            )}
                          </div>
                          {item.type}
                        </td>
                        <td className="text-sm p-4">{item.narration}</td>
                        <td className="text-sm p-4">
                            {item.type === TypeEnum.Credit && <Requery sessionId={item.narration.split('|')[2]} />}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* {data?.data.Data.TotalRecordInStore! > 0 && (
          <Pagination
            totalItems={data?.data.Data.TotalRecordInStore!}
            itemsPerPage={txnSize}
            setPage={setCurrentPage}
            page={currentPage}
            setSize={setTxnSize}
          />
        )} */}
      </div>
    </FullscreenModal>
  );
};

export default SafeHavenTransaction;
