import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Loading } from '../../../../components/icons';
import { Modal } from '../../../../components/modal';
import { IUser } from '../../../../interface/user';
import { useUpdateUser } from '../../../../services/user';

interface IProps {
  user: IUser;
  refetch: () => void;
}

const FundSweep = ({ user, refetch }: IProps) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading, isSuccess } = useUpdateUser();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setOpen(false);
      refetch();
    }
  }, [isLoading, isSuccess, refetch]);

  return (
    <>
      <div className=" w-auto">
        <Button bgColor={`bg-activeLinkBlack`} onClick={() => setOpen(true)}>
          {user.fundSweep ? 'Stop Fund Sweep' : 'Start Fund Sweep'}
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center ">
          <p className="pb-4">
            Are your sure you want to{' '}
            {user.fundSweep ? 'Stop Fund Sweep' : 'Start Fund Sweep'}
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link
              to=""
              onClick={() =>
                mutate({
                  _id: user?._id,
                  fundSweep: user.fundSweep ? false : true,
                })
              }
              className="text-blue"
            >
              Yes, {user.fundSweep ? 'Stop Fund Sweep' : 'Start Fund Sweep'}
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
};

export default FundSweep;
