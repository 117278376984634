import { AxiosError, AxiosResponse } from 'axios';
import { IResponse, IList } from '../interface/response';
import Axios from './axios';
import {
  IDeclinePayoneerPayload,
  IApprovePayoneerPayload,
} from '../interface/txn';
import { useToast } from '../context/toast';
import { useMutation, useQuery } from 'react-query';

const payoneer = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<any>>> = await Axios.get(
    '/payoneer',
    {
      params,
    }
  );
  return response.data;
};

const declinePayoneer = async ({ id, payload }: IDeclinePayoneerPayload) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/payoneer/approval/${id}`,
    payload
  );
  return response.data;
};

const approvePayoneer = async ({ id, payload }: IApprovePayoneerPayload) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/payoneer/approval/${id}`,
    payload
  );
  return response.data;
};

export const usePayoneer = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['payoneer', params], () => payoneer(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching payoneer data',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useDeclinePayoneer = () => {
  const { addToast } = useToast();

  return useMutation(declinePayoneer, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error declining payoneer',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useApprovePayoneer = () => {
  const { addToast } = useToast();

  return useMutation(approvePayoneer, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error approving payoneer',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};
