import React, { useState } from 'react';
import { Modal } from '../../../../components/modal';
import { IBusinessDetailsprops } from '../../../../interface/business';
import Input from '../../../../components/input';
import { Link } from 'react-router-dom';
import Switch from '../../../../components/switch';
import { Loading } from '../../../../components/icons';
import { isCurrency } from '../../../../utils/isCurrency';
import { capitalized } from '../../../../utils/capitalize';
import { useBusinessUpdate } from '../../../../services/business';
import Button from '../../../../components/button';

const Profile = ({ business, refetch }: IBusinessDetailsprops) => {
  const [open, setOpen] = useState<boolean>(false);
  const [suspend, setSuspend] = useState(business.suspend);

  const { mutate: bizMutate, isLoading: bizUpdating } = useBusinessUpdate();

  const handleUpdate = () => {
    bizMutate(
      {
        id: business._id,
        body: {
          suspend: !suspend,
        },
      },
      {
        onSuccess: refetch,
      }
    );

    setSuspend(!suspend);
  };

  return (
    <div>
      <Button
        bgColor="bg-activeLinkBlack"
        onClick={() => setOpen(true)}
        className="cursor-pointer">
        Profile
      </Button>

      <Modal
        isVisible={open}
        close={setOpen}
        header
        center
        title="Profile"
        padding={false}>
        {business.logo && (
          <div className="border-b border-gray">
            <div className="flex justify-end py-4 px-4">
              <img src={business.logo} alt="logo" />
            </div>
          </div>
        )}
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Creator BVN</small>
            <small
              className={
                business?.user?.verification.bvn
                  ? 'text-green-500'
                  : 'text-red-500'
              }>
              {business?.user?.verification.bvn ? 'Verified' : 'Not Verified'}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Business Name</small>
            <small>{business.name}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Wallet Balance</small>
            <small> ₦{isCurrency(business.wallet ?? 0)}</small>
          </div>
        </div>

        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Reg. No.</small>
            <small> {business.regNumber ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Reg. Type</small>
            <small> {capitalized(business.type) ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Email</small>
            <small> {business.email ?? '----'}</small>
          </div>
        </div>

        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Phone</small>
            <small>
              {business.dialCode}
              {business.phone}
            </small>
          </div>
        </div>
        <div>
          <div className="flex justify-between items-center py-4 px-4">
            <small>Brand Color</small>
            <small>
              {business.brandColor}{' '}
              <Input type="color" disabled className="w-6" value="0xFF859812" />
            </small>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
