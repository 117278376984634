import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FullscreenModal } from '../../../../components/modal/fullscreenModal';
import { IBusiness } from '../../../../interface/business';
import { ITxnFilter } from '../../../../interface/txn';
import { useBusinessTransactions } from '../../../../services/transaction';
import BusinessInfo from './businessInfo';
import DeactivateAndActivateBusiness from './deactivateAndActivateBusiness';
import Transactions from './transactions';
import useUrlPagination from '../../../../utils/urlPagination';
import DeleteBusiness from './deleteBusiness';

interface IProp {
  business?: IBusiness;
  refetch?: any;
}

const BusinessDetails = ({ business, refetch }: IProp) => {
  const [filter, setFilter] = useState<ITxnFilter>();
  const [txnSize, setTxnSize] = useState(10);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>('Details');
  const tabs = ['Details', 'Transactions'];
  const { currentPage, setPage } = useUrlPagination();

  const { data: txn, isLoading } = useBusinessTransactions({
    id: business?._id,
    params: { currentPage, size: txnSize, ...filter },
  });

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          More details
        </Link>
      </div>
      <FullscreenModal
        isVisible={open}
        close={setOpen}
        header
        center
        title="Business details"
        padding={false}
      >
        {true && (
          <div className="font-medium text-sm px-4">
            <div className="flex flex-col lg:flex-row gap-4  my-6 item-start lg:items-center">
              <div className="flex-1">
                <h2 className="font-medium">Business ({business?.name})</h2>
              </div>
              <div className="flex justify-between flex-wrap gap-4">
                {!business?.approved && (
                  <DeleteBusiness
                    business={business!}
                    refetch={refetch}
                    closeModal={setOpen}
                  />
                )}

                <DeactivateAndActivateBusiness
                  business={business!}
                  refetch={refetch}
                />
              </div>
            </div>

            <ul className="flex flex-wrap gap-4 lg:gap-0 items-center lg:items-start text-grayText mb-4">
              {tabs.map((item: string, index: number) => (
                <li
                  key={index}
                  className={`${
                    activeTab === item
                      ? 'text-white bg-activeLinkBlack font-medium '
                      : ''
                  } 
             px-3 py-[.35rem] mx-1 rounded-md font-normal  hover:cursor-pointer`}
                  onClick={() => setActiveTab(item)}
                >
                  {item}
                </li>
              ))}
            </ul>

            {activeTab === 'Details' ? (
              <BusinessInfo business={business!} refetch={refetch} />
            ) : activeTab === 'Transactions' ? (
              <Transactions
                txn={txn?.data!}
                isLoading={isLoading}
                setTxnCurrentPage={setPage}
                setFilter={setFilter}
                size={txnSize}
                setTxnSize={setTxnSize}
                txnCurrentPage={currentPage}
                filter={filter!}
                // user={user!}
              />
            ) : (
              ''
            )}
          </div>
        )}
      </FullscreenModal>
    </>
  );
};

export default BusinessDetails;
