import React, { useState } from 'react';
import Input from '../../../../../components/input';
import {
  useRepush,
  useSafehavenTransferStatus,
} from '../../../../../services/user';
import Button from '../../../../../components/button';
import { useToast } from '../../../../../context/toast';
import { IRepushPayload, ITransferStatus } from '../../../../../interface/user';
import Switch from '../../../../../components/switch';

const Repush = () => {
  const [payload, setPayload] = useState<IRepushPayload>();
  const [safehavenPayload, setSafehavenPayload] = useState<ITransferStatus>();
  const [active, setActive] = useState<string>('providus');
  const { mutate, isLoading } = useRepush();
  const { refetch, isLoading: loadingTransferStatus } =
    useSafehavenTransferStatus({
      session_id: safehavenPayload?.session_id!,
      dynamic: safehavenPayload?.dynamic ? safehavenPayload?.dynamic : false,
    });
  const { addToast } = useToast();

  const submitTransStatus = () => {
    if (!safehavenPayload?.session_id) {
      addToast({ msg: 'Please enter session id', type: 'error' });
    } else {
      refetch();
    }
  };

  const submit = () => {
    if (!payload?.session_id) {
      addToast({ msg: 'Please enter session id', type: 'error' });
    } else if (!payload.settlement_id) {
      addToast({ msg: 'Please enter settlement id', type: 'error' });
    } else {
      mutate(payload, {
        onSuccess: () => {
          setPayload(undefined);
        },
      });
    }
  };

  return (
    <div className="md:w-[30rem] w-full text-sm">
      <div className="flex gap-2 items-center mb-4">
        {tabs.map((item, index) => (
          <div
            className={`${
              active === item.toLowerCase()
                ? 'bg-activeLinkBlack py-2 px-3 rounded-md'
                : ''
            } text-white text-sm  cursor-pointer`}
            key={index}
            onClick={() => setActive(item.toLowerCase())}
          >
            <p>{item}</p>
          </div>
        ))}
      </div>
      {active === 'providus' && (
        <>
          <div className="bg-dark rounded-t-md px-4 py-4">
            <div>
              <h5 className="text-sm mb-2">Enter Settlement Id</h5>
              <Input
                value={payload?.settlement_id}
                onChange={(e) =>
                  setPayload({ ...payload!, settlement_id: e.target.value })
                }
              />
            </div>
            <div className="mt-6">
              <h5 className="text-sm mb-2">Enter Session Id</h5>
              <Input
                value={payload?.session_id}
                onChange={(e) =>
                  setPayload({ ...payload!, session_id: e.target.value })
                }
              />
            </div>
          </div>

          <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save Changes
            </Button>
          </div>
        </>
      )}
      {active === 'safehaven' && (
        <>
          <div className="bg-dark rounded-t-md px-4 py-4">
            <div className="flex items-center justify-between">
              <h5 className="text-sm mb-2">Dynamic</h5>
              <Switch
                checked={safehavenPayload?.dynamic!}
                onCheck={() =>
                  setSafehavenPayload({
                    ...safehavenPayload!,
                    dynamic: !safehavenPayload?.dynamic,
                  })
                }
              />
            </div>
            <div className="mt-6">
              <h5 className="text-sm mb-2">Enter Session Id</h5>
              <Input
                value={safehavenPayload?.session_id}
                onChange={(e) =>
                  setSafehavenPayload({
                    ...safehavenPayload!,
                    session_id: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
            <Button
              onClick={submitTransStatus}
              loading={loadingTransferStatus}
              disabled={loadingTransferStatus}
            >
              Save Changes
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Repush;

const tabs = ['Providus', 'Safehaven'];
