import { useEffect, useState } from 'react';
import { Modal } from '../../../../../components/modal';
import Switch from '../../../../../components/switch';
import { useToast } from '../../../../../context/toast';
import {
  ISettingsResponse,
  ISubChild,
  ISubSubChild,
} from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import { capitalized } from '../../../../../utils/capitalize';
import Charges from './charge';

interface IMobileData {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const MobileData = ({ settingsData, refetch }: IMobileData) => {
  const mobileData = settingsData?.isp.mobileData;
  const { mutate, isLoading } = useUpdateSettingsData();
  const [active, setActive] = useState<boolean>(mobileData?.active!);
  const [toggleItems, setToggleItems] = useState<ISubChild[]>(
    mobileData?.data!
  );
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<ISubChild>({} as ISubChild);
  const { addToast } = useToast();
  const [charge, setCharge] = useState<string>(
    mobileData?.charge.toString() ?? ''
  );

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: 'Successfully Updated', type: 'success' });
  };

  const toggleChange = () => {
    setActive(!active);
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          mobileData: {
            active: !settingsData?.isp.mobileData.active,
            data: toggleItems,
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const submitCharges = () => {
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          mobileData: {
            ...settingsData?.isp.mobileData,
            charge: parseInt(charge),
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleDataChange = (selected: ISubChild) => {
    const activeItem = toggleItems.find((item) => item.name === selected.name);
    if (activeItem) {
      setToggleItems((prevToggleItems) =>
        prevToggleItems.map((obj) =>
          obj === activeItem ? { ...obj, visibility: !obj.visibility } : obj
        )
      );
    }
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          mobileData: {
            ...mobileData,
            data: mobileData?.data.map((item) =>
              item.name === selected.name
                ? { ...selected, visibility: !selected.visibility }
                : item
            ),
          },
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleSubDataChange = (selected: ISubSubChild) => {
    setEditItem({
      ...editItem,
      provider: editItem.provider.map((provider) =>
        provider.name === selected.name
          ? { ...provider, active: !provider.active }
          : provider
      ),
    });
    mutate(
      {
        isp: {
          ...settingsData?.isp!,
          mobileData: {
            ...mobileData,
            data: mobileData?.data.map((item) => {
              const updatedProviders = item.provider.map((provider) =>
                provider.name === selected.name
                  ? { ...provider, active: !provider.active }
                  : provider
              );
              return { ...item, provider: updatedProviders };
            }),
          },
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
        },
      }
    );
  };

  useEffect(() => {
    setActive(mobileData?.active || false);
    setToggleItems(mobileData?.data || []);
  }, [mobileData]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Activate Mobile Data</h5>
        <Switch checked={active} onCheck={toggleChange} />
      </div>

      <div className="rounded-md bg-dark mb-6">
        <Charges
          setCharge={setCharge}
          charge={charge}
          submit={submitCharges}
          loading={isLoading}
        />
      </div>

      {toggleItems?.map((item: ISubChild, index: number) => (
        <div key={index}>
          <div className="mb-2 bg-dark px-2 py-3 rounded-md flex justify-between items-center">
            <h5>{capitalized(item.name)}</h5>
            <div className="flex items-center">
              <div
                className="mr-5 cursor-pointer"
                onClick={() => {
                  setOpen(true);
                  setEditItem(item);
                }}
              >
                Edit
              </div>
              <span className="max-w-[150px] w-full grid place-items-center">
                <Switch
                  checked={item.visibility}
                  onCheck={() => handleDataChange(item)}
                />
              </span>
            </div>
          </div>
        </div>
      ))}

      {open && editItem && (
        <Modal
          isVisible={open}
          close={() => {
            setOpen(false);
            setEditItem({} as ISubChild);
          }}
          title={capitalized(editItem.name)}
          padding={false}
          header
          width="medium"
          center
        >
          {editItem?.provider?.map((item: ISubSubChild, index: number) => (
            <div key={index}>
              <div className="py-4 px-4 flex justify-between items-center text-xs">
                <div>{capitalized(item.name)}</div>
                <div className="flex items-center">
                  {item.rate !== null && (
                    <div className="bg-bColor py-1 px-2 gap-2 text-xs rounded-md flex mr-5">
                      <h5>Rate:</h5>
                      <span>{`${item.rate}%`}</span>
                    </div>
                  )}
                  <span className="max-w-[150px] w-full flex justify-end">
                    <Switch
                      checked={item.active}
                      onCheck={() => handleSubDataChange(item)}
                    />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default MobileData;
