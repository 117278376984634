import { useState } from "react";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import { Modal } from "../../../../../components/modal";
import Switch from "../../../../../components/switch";
import { useToast } from "../../../../../context/toast";
import { ISettingsResponse } from "../../../../../interface/settings";
import { useUpdateSettingsData } from "../../../../../services/settings";

interface IPayoneerInterface {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}
const Payoneer = ({ settingsData, refetch }: IPayoneerInterface) => {
  const payoneer = settingsData?.payoneer;
  const { mutate, isLoading } = useUpdateSettingsData();
  const [open, setOpen] = useState(false);
  const [openCharge, setOpenCharge] = useState(false);
  const [openRate, setOpenRate] = useState(false);
  const [openMarkdown, setOpenMarkdown] = useState(false);
  const [active, setActive] = useState<boolean>(payoneer?.active ?? false);
  const [manual, setManual] = useState<boolean>(payoneer?.manual ?? false);
  const [minFund, setMinFund] = useState<string>("");
  const [limit, setLimit] = useState<string>("");
  const [account, setAccount] = useState<string>("");
  const [fixed, setFixed] = useState<string>("");
  const [percent, setPercent] = useState<string>("");
  const [gbp, setgbp] = useState<string>("");
  const [usd, setusd] = useState<string>("");
  const [eur, seteur] = useState<string>("");
  const [gbpRate, setgbpRate] = useState<string>("");
  const [usdRate, setusdRate] = useState<string>("");
  const [eurRate, seteurRate] = useState<string>("");
  const { addToast } = useToast();

  const handleActiveChange = () => {
    setActive(!active);
    mutate(
      {
        payoneer: {
          ...payoneer,
          active: !payoneer?.active,
        },
      },
      {
        onSuccess: () => {
          refetch();

          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const handleManualChange = () => {
    setManual(!manual);
    mutate(
      {
        payoneer: {
          ...payoneer,
          manual: !payoneer?.manual,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const submit = () => {
    mutate(
      {
        payoneer: {
          ...payoneer,
          minFund: parseInt(minFund) || payoneer?.minFunding,
          limit: parseInt(limit) || payoneer?.limitPerDay,
          account: account || payoneer?.account,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const submitCharge = () => {
    mutate(
      {
        payoneer: {
          ...payoneer,
          charge: {
            fixed: parseInt(fixed) || payoneer?.charge.fixed,
            payoneerPercentage: payoneer?.charge.payoneerPercentage,
            percentage: parseInt(percent) || payoneer?.charge.percentage,
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpenCharge(false);
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const submitMarkdown = () => {
    mutate(
      {
        payoneer: {
          ...payoneer,
          markdown: {
            EUR: parseInt(eur) || payoneer?.markdown.EUR,
            GBP: parseInt(gbp) || payoneer?.markdown.GBP,
            USD: parseInt(usd) || payoneer?.markdown.USD,
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpenMarkdown(false);
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  const submitRate = () => {
    mutate(
      {
        payoneer: {
          ...payoneer,
          rates: {
            EUR: parseInt(eurRate) || payoneer?.rates.EUR,
            GBP: parseInt(gbpRate) || payoneer?.rates.GBP,
            USD: parseInt(usdRate) || payoneer?.rates.USD,
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpenRate(false);
          addToast({ msg: "Successfully Updated", type: "success" });
        },
      }
    );
  };

  return (
    <>
      <div className="md:w-96 w-full text-sm">
        <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
          <h5>Activate Payoneer</h5>
          <Switch checked={active} onCheck={handleActiveChange} />
        </div>

        <div className="py-4 mb-6 bg-transparent border border-grey px-2 rounded-md flex justify-between items-center">
          <h5>Manual</h5>
          <div className="flex gap-2">
            {manual && (
              <h6 onClick={() => setOpenRate(true)} className="cursor-pointer">
                Edit
              </h6>
            )}
            <Switch checked={manual} onCheck={handleManualChange} />
          </div>
        </div>
        <div>
          <div className="bg-dark rounded-t-md">
            <div className="flex justify-between px-4 py-4">
              <h5>Account</h5>
              <h4>{payoneer?.account}</h4>
            </div>
            <hr className="border-grey" />
            <div className="flex justify-between px-4 py-4">
              <h5>Limit Per Day</h5>
              <h4>{payoneer?.limitPerDay}</h4>
            </div>
            <hr className="border-grey" />
            <div className="flex justify-between py-4 px-4">
              <h5>Minimum Funding</h5>
              <h4>{payoneer?.minFunding}</h4>
            </div>
            <hr className="border-grey" />
            <div className="flex justify-between py-4 px-4">
              <h5>Charges</h5>
              <div className="bg-bColor py-1 px-2 gap-2 text-xs rounded-md flex">
                <span className="text-gray">Percent: </span>
                {payoneer?.charge.percentage}
                <span className="text-gray">|</span>
                <span className="text-gray">Fixed: </span>
                {payoneer?.charge.fixed}
              </div>
            </div>
            <hr className="border-grey" />
            <div className="flex justify-between py-4 px-4">
              <h5>Markdown</h5>
              <div className="bg-bColor py-1 px-2 gap-2 text-xs rounded-md flex">
                <span className="text-gray">EUR: </span>
                {payoneer?.markdown.EUR} <span className="text-gray">|</span>
                <span className="text-gray">GBP: </span>
                {payoneer?.markdown.GBP} <span className="text-gray">|</span>
                <span className="text-gray">USD: </span>
                {payoneer?.markdown.USD}
              </div>
            </div>
          </div>
          <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
            <Button onClick={() => setOpen(true)}>Edit Payoneer</Button>
          </div>
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        title={"Payoneer"}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Account</h5>
            <Input
              type="text"
              onChange={(e) => setAccount(e.target.value)}
              defaultValue={payoneer?.account}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Limit Per Day</h5>
            <Input
              type="text"
              onChange={(e) => setLimit(e.target.value)}
              defaultValue={payoneer?.limitPerDay}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5>Minimum Funding</h5>
            <Input
              type="text"
              onChange={(e) => setMinFund(e.target.value)}
              defaultValue={payoneer?.minFunding}
            />
          </div>

          <div className="flex gap-2 justify-between py-2 px-4">
            <h5>Charge</h5>
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
                setOpenCharge(true);
              }}
            >
              Edit
            </div>
          </div>

          <div className="flex gap-2 justify-between py-2 px-4">
            <h5>Markdown</h5>
            <div
              className="cursor-pointer"
              onClick={() => {
                setOpen(false);
                setOpenMarkdown(true);
              }}
            >
              Edit
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isVisible={openCharge}
        close={setOpenCharge}
        title={"Edit Charge"}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              onClick={submitCharge}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">Fixed</h5>
            <Input
              type="text"
              defaultValue={payoneer?.charge.fixed}
              onChange={(e) => setFixed(e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">Percentage</h5>
            <Input
              type="text"
              defaultValue={payoneer?.charge.percentage}
              onChange={(e) => setPercent(e.target.value)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isVisible={openMarkdown}
        close={setOpenMarkdown}
        title={"Edit Markdown"}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              onClick={submitMarkdown}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">EUR</h5>
            <Input
              type="text"
              defaultValue={payoneer?.markdown.EUR}
              onChange={(e) => seteur(e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">GBP</h5>
            <Input
              type="text"
              defaultValue={payoneer?.markdown.GBP}
              onChange={(e) => setgbp(e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">USD</h5>
            <Input
              type="text"
              defaultValue={payoneer?.markdown.USD}
              onChange={(e) => setusd(e.target.value)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        isVisible={openRate}
        close={setOpenRate}
        title={"Edit Rate"}
        padding={false}
        center
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              onClick={submitRate}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
      >
        <div className="text-sm">
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">EUR</h5>
            <Input
              type="text"
              defaultValue={payoneer?.rates.EUR}
              onChange={(e) => seteurRate(e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">GBP</h5>
            <Input
              type="text"
              defaultValue={payoneer?.rates.GBP}
              onChange={(e) => setgbpRate(e.target.value)}
            />
          </div>
          <div className="flex gap-2 flex-col py-2 px-4">
            <h5 className="mb-2">USD</h5>
            <Input
              type="text"
              defaultValue={payoneer?.rates.USD}
              onChange={(e) => setusdRate(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Payoneer;
