import { BrowserRouter } from 'react-router-dom';
import { Idle } from './components/idle';
import AppRouter from './routes';

function App() {
  return (
    <div className="bg-bColor min-h-screen font-body font-light text-white">
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
      <Idle />
    </div>
  );
}

export default App;
