import { FC, useContext} from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/auth";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

const ProtectedRoute: FC<IProps> = ({ children }) => {
  const { auth,secret } = useContext(AuthContext);

  if (!auth && !secret ) {
    return <Navigate to="/auth" replace />;
  }

  return <div>{children}</div>;
};

export default ProtectedRoute;
