import * as React from "react";
import { SVGProps } from "react";
const SvgBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.967 15.933 0 7.967 7.967 0 9.4 1.433 3.867 6.967H24v2H3.867L9.4 14.5l-1.433 1.433Z"
      fill="#fff"
    />
  </svg>
);
export default SvgBack;
