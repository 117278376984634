import React, { useContext, useState } from 'react';
import Button from '../../../../../../components/button';
import { Modal } from '../../../../../../components/modal';
import {
  useDeleteGiftcard,
  useGetGiftCardVoucher,
} from '../../../../../../services/settings';
import GlobalContext from '../../../../../../context/globalContext';

const DeleteGiftCard = () => {
  const { setProgressCount, giftCardDetails } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const { data, refetch } = useGetGiftCardVoucher();
  const { mutate: deleteGiftcard } = useDeleteGiftcard();

  const deleteGiftCardVouncher = () => {
    deleteGiftcard(giftCardDetails._id, {
      onSuccess: () => {
        setProgressCount(0);
        refetch();
      },
    });
  };

  return (
    <div>
      <Button className="bg-dark" onClick={() => setOpen(true)}>
        Delete Card
      </Button>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Delete Giftcard"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-between">
            <Button
              onClick={() => setOpen(false)}
              className="border border-grey"
              bgColor="bg-transparent"
            >
              Cancel
            </Button>
            <Button onClick={deleteGiftCardVouncher}>Yes</Button>
          </div>
        }
      >
        <div className="text-center">
          <h5>Are you sure you want to delete this gift card</h5>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteGiftCard;
