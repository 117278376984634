import { AxiosError, AxiosResponse } from 'axios';
import {
  Campaign,
  CampaignData,
  CreateEmailCampaignPayload,
  updateEmailCampaignPayload,
} from '../interface/mail-campaigns';
import { IResponse } from '../interface/response';
import Axios from './axios';
import { useToast } from '../context/toast';
import { useQuery, useMutation } from 'react-query';

const updateMailCampaign = async (payload: updateEmailCampaignPayload) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.patch(
    `/email-campaign`,
    payload
  );
  return response;
};

const createMailCampaign = async (payload: CreateEmailCampaignPayload) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.post(
    `/email-campaign`,
    payload
  );
  return response;
};

const getAllCampaigns = async (params: object) => {
  const response: AxiosResponse<IResponse<CampaignData<Campaign>>> =
    await Axios.get(`/email-campaign`, {
      params,
    });
  return response.data;
};

const getSingleCampaign = async (id?: string) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.get(
    `/email-campaign/${id}`
  );
  return response.data;
};

export const useGetAllCampaigns = (params: object) => {
  const { addToast } = useToast();
  return useQuery('all campaigns', () => getAllCampaigns(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching campaigns',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetSingleCampaign = (id: string) => {
  const { addToast } = useToast();
  return useQuery(['single campaigns', id], () => getSingleCampaign(id), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching campaign',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useCreateCampaign = () => {
  const { addToast } = useToast();

  return useMutation(createMailCampaign, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Campaign creation failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useUpdateCampaign = () => {
  const { addToast } = useToast();

  return useMutation(updateMailCampaign, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Failed to update campaign',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};
