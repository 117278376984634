import * as React from "react";
import { SVGProps } from "react";
const SvgChevronDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 11 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.65 6.507c-.117 0-.23-.017-.337-.05a.604.604 0 0 1-.288-.2L.5 1.757a.766.766 0 0 1-.2-.525c0-.2.075-.375.225-.525.15-.134.325-.2.525-.2s.375.066.525.2L5.65 4.782 9.75.707c.133-.15.3-.221.5-.213.2.008.375.08.525.213.15.15.225.329.225.537 0 .208-.075.38-.225.513l-4.5 4.5a.605.605 0 0 1-.287.2c-.109.033-.222.05-.338.05Z"
      fill="#747474"
    />
  </svg>
);
export default SvgChevronDown;
