import { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import Switch from '../../../../components/switch';
import { ITxn } from '../../../../interface/txn';
import { capitalized } from '../../../../utils/capitalize';
import {
  ActivityEnum,
  PurposeEnum,
  StatusEnum,
  TxnTypeEnum,
} from '../../../../utils/constants';
import { isCurrency } from '../../../../utils/isCurrency';
import { toSentenceCase } from '../../../../utils/toSentenceCase';
import Requery from './requery';
import ReverseBank from './reverseBank';
import ReverseBills from './reverseBills';

interface IProps {
  item: ITxn;
  text: string;
}

const TransactionDetails = ({ item, text }: IProps) => {
  const [open, setOpen] = useState(false);
  const [openRequeryModal, setOpenRequeryModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [reverseModal, setReverseModal] = useState(false);
  const [request, setRequest] = useState(false);
  const [getItem, setGetItem] = useState<any>();
  const [viewItemModal, setViewItemModal] = useState<boolean>(false);

  const [response, setResponse] = useState(false);

  const txn = Object.keys(item.view).map((key) => {
    let value = item.view[key];
    if (
      key === 'amount' ||
      key === 'cashback' ||
      key === 'total' ||
      key === 'amountInNaira' ||
      key === 'fundingFee' ||
      key === 'totalInNaira'
    ) {
      value = `₦${isCurrency(item.view[key])}`;
    }
    if (item.purpose === PurposeEnum.CARDS) {
      if (key === 'amount') {
        value = `$${isCurrency(item.view[key])}`;
      }
      if (key === 'rate') {
        value = `$${isCurrency(item.view[key])}/₦`;
      }
    }
    return {
      key: toSentenceCase(key),
      value,
    };
  });

  const requestChange = () => setRequest(!request);
  const responseChange = () => setResponse(!response);

  useEffect(() => {
    if (getItem?.key?.includes('Data')) {
      setViewItemModal(true);
      setOpen(false);
    }
  }, [getItem]);

  const notAllowedTypes: string[] = [
    PurposeEnum.TRANSFER,
    PurposeEnum.CARDS,
    PurposeEnum.PAYONEER,
  ];

  return (
    <>
      <Modal
        padding={true}
        isVisible={viewItemModal}
        center
        close={setViewItemModal}
        title="Data"
        header={true}
      >
        <div className="flex justify-center items-center">
          {getItem && <img src={getItem.value[0]} alt={`txn-${getItem.key}`} />}
        </div>
      </Modal>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          {text}
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title="Transaction details"
        header={true}
        showFooter={
          (item.activity === ActivityEnum.WITHDRAW &&
            item.type === TxnTypeEnum.BANK_TRANSFER &&
            !item.description.includes('NIP commission')) ||
          !notAllowedTypes.includes(item.purpose)
        }
        footer={
          <div className="flex justify-end gap-4 mr-4">
            {item.activity === ActivityEnum.WITHDRAW &&
              item.type === TxnTypeEnum.BANK_TRANSFER &&
              !item.description.includes('NIP commission') && (
                <Button
                  bgColor="bg-red-500"
                  onClick={() => {
                    setOpen(false);
                    setModal(true);
                  }}
                >
                  Reverse
                </Button>
              )}
            {item.activity === ActivityEnum.WITHDRAW &&
              item.type === TxnTypeEnum.BANK_TRANSFER &&
              !item.description.includes('NIP commission') && (
                <Button
                  bgColor="bg-activeLinkBlack"
                  onClick={() => {
                    setOpen(false);
                    setOpenRequeryModal(true);
                  }}
                >
                  Re-Query
                </Button>
              )}
            {!notAllowedTypes.includes(item.purpose) && (
              <Button
                bgColor="bg-red-500"
                onClick={() => {
                  setOpen(false);
                  setReverseModal(true);
                }}
              >
                Reverse Bill
              </Button>
            )}
          </div>
        }
      >
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Status</small>
            <small>
              {item.status === StatusEnum.FAILED && (
                <span className="text-red-500">
                  {capitalized(StatusEnum.FAILED)}
                </span>
              )}
              {item.status === StatusEnum.REFUNDED && (
                <span className="text-red-500">
                  {capitalized(StatusEnum.REFUNDED)}
                </span>
              )}
              {item.status === StatusEnum.SUCCESS && (
                <span className="text-green-500">
                  {capitalized(StatusEnum.SUCCESS)}
                </span>
              )}
              {item.status === StatusEnum.PENDING && (
                <span className=" text-amber-500">
                  {capitalized(StatusEnum.PENDING)}
                </span>
              )}
              {item.status === StatusEnum.PROCESSING && (
                <span className=" text-amber-500">
                  {capitalized(StatusEnum.PROCESSING)}
                </span>
              )}
              {item.status === StatusEnum.INITIATED && (
                <span className=" text-gray-500">
                  {capitalized(StatusEnum.INITIATED)}
                </span>
              )}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Customer</small>
            <Link
              to={`/app/user/${item.user?._id}`}
              onClick={() => setOpen(false)}
            >
              <small className="text-blue">{`${item.user?.firstName} ${item.user?.lastName}`}</small>
            </Link>
          </div>
        </div>
        {txn.map((data, index) => (
          <div key={index} className="border-b border-gray">
            <div
              className="flex justify-between py-4 px-4 gap-2"
              key={`txn-${index}`}
              onClick={() => setGetItem(data)}
            >
              <small>{capitalized(data.key)}</small>
              <small className="whitespace-pre-wrap">{data.value}</small>
            </div>
          </div>
        ))}
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Disbursed amount</small>
            <small>₦{isCurrency(item.disbursedAmount)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4  px-4">
            <small>Profit</small>
            <small>₦{isCurrency(item.settlement)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Provider</small>
            <small>{item.provider}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Initial Balance</small>
            <small>₦{isCurrency(item.meta?.initialBalance) ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Final Balance</small>
            <small>₦{isCurrency(item.meta?.finalBalance) ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>IP Address</small>
            <small>{item.meta?.ipAddress ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4 gap-4">
            <small>Device Info</small>
            <small
              className="text-right "
              style={{ whiteSpace: 'break-spaces' }}
            >
              {item.meta?.deviceIdInfo ?? '----'}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>App Version</small>
            <small>{item.meta?.appVersion ?? '----'}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="py-4  px-4">
            <div className="flex justify-between items-center mb-2">
              <small>Request Payload</small>
              <Switch checked={request} onCheck={requestChange} />
            </div>
            {request && (
              <pre>
                <CopyBlock
                  text={JSON.stringify(item.requestPayload, null, 2)}
                  showLineNumbers
                  theme={dracula}
                  wrapLines={true}
                  codeBlock
                />
              </pre>
            )}
          </div>
        </div>
        <div>
          <div className="py-4 px-4">
            <div className="flex justify-between items-center mb-2">
              <small>Response Payload</small>
              <Switch checked={response} onCheck={responseChange} />
            </div>

            {response && item.responsePayload && (
              <pre>
                <CopyBlock
                  text={JSON.stringify(item.responsePayload, null, 2)}
                  showLineNumbers
                  theme={dracula}
                  wrapLines={true}
                  codeBlock
                />
              </pre>
            )}
          </div>
        </div>
      </Modal>
      <ReverseBank item={item} setModal={setModal} modal={modal} />
      <ReverseBills
        item={item}
        setModal={setReverseModal}
        modal={reverseModal}
      />
      <Requery
        item={item}
        setModal={setOpenRequeryModal}
        modal={openRequeryModal}
      />
    </>
  );
};

export default TransactionDetails;
