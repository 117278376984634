export enum StatusEnum {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  INITIATED = 'INITIATED',
  REFUNDED = 'REVERSAL',
  REJECTED = 'REJECTED',
}

export enum PurposeEnum {
  POWER = 'POWER',
  GIFT_CARD = 'GIFTCARD',
  AIRTIME = 'AIRTIME',
  DATA = 'DATA',
  CABLE = 'CABLE',
  WALLET = 'WALLET',
  TRANSFER = 'TRANSFER',
  VAULT = 'VAULT',
  CHEAP_DATA = 'CHEAP DATA',
  AIRTIME_TO_CASH = 'AIRTIME TO CASH',
  CARDS = 'CARDS',
  PAYONEER = 'PAYONEER',
  BETTING = 'BETTING',
}

export enum ActivityEnum {
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
}

export enum TxnTypeEnum {
  BANK_TRANSFER = 'BANK_TRANSFER',
  VAULT = 'VAULT',
  WALLET = 'WALLET',
}
