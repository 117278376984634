import React, { useContext, useRef } from 'react';
import Button from '../../../../../../components/button';
import { Back, ChevronRight } from '../../../../../../components/icons';
import Switch from '../../../../../../components/switch';
import GlobalContext from '../../../../../../context/globalContext';
import {
  useGetGiftCardVoucher,
  useUpdateGiftCard,
} from '../../../../../../services/settings';
import DeleteGiftCard from './delete-giftcard';
import EditCardName from './edit-giftcard';
import SaveEditGiftCard from './save-edit-giftcard';

const GiftcardDetails = () => {
  const {
    progressCount,
    setProgressCount,
    giftCardDetails,
    setGiftCardDetails,
  } = useContext(GlobalContext);
  const uploadRef = useRef<HTMLInputElement>(null);

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setGiftCardDetails({ ...giftCardDetails, image: e.target.files[0] });
    }
  };

  const pauseCard = () => {
    setGiftCardDetails({
      ...giftCardDetails,
      active: !giftCardDetails.active,
    });
  };

  const switchAvailableCountries = (name: string) => {
    setGiftCardDetails({
      ...giftCardDetails,
      availableCountry: giftCardDetails.availableCountry.map((country) =>
        country.name === name
          ? { ...country, active: !country.active }
          : country
      ),
    });
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  return (
    <div>
      <div className="flex-1 mb-6">
        <div className="flex gap-4 items-center">
          <div
            className="p-1 bg-lightGray rounded-md cursor-pointer"
            onClick={handleBack}
          >
            <Back />
          </div>
          <h2 className="text-md font-medium">{giftCardDetails.brandName}</h2>
        </div>
      </div>
      <div className="rounded-md md:w-[30rem] w-full text-sm">
        <div className="flex justify-between mb-4">
          <div className="flex gap-2">
            <Button onClick={pauseCard}>
              {giftCardDetails.active ? 'Pause Card' : 'Unpause Card'}
            </Button>
            <DeleteGiftCard />
          </div>
          <SaveEditGiftCard />
        </div>
        <div className="grid grid-cols-2 gap-4 mb-2">
          <div className="flex flex-col gap-2">
            <h5>Brand Name</h5>
            <div className="bg-dark border-grey border border-[1px] px-2 py-3 rounded-lg flex justify-between">
              <h5>{giftCardDetails.brandName}</h5>
              <EditCardName
                title="Brand Name"
                defaultValue={giftCardDetails.brandName}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <h5>Processing Time</h5>
            <div className="bg-dark border-grey border border-[1px] px-2 py-3 rounded-lg flex justify-between">
              <h5>{giftCardDetails.processingTime}</h5>
              <EditCardName
                title="Processing Time"
                defaultValue={giftCardDetails.processingTime}
              />
            </div>
          </div>
        </div>

        <div className="mb-4">
          <h5 className="mb-2">Card Image</h5>
          <div className="bg-transparent border border-grey/60 w-40 h-28 rounded-md">
            <img
              src={
                typeof giftCardDetails.image === 'object'
                  ? URL.createObjectURL(giftCardDetails.image)
                  : giftCardDetails.image
              }
              alt="giftcard"
              className="object-cover w-40 h-28 rounded-md"
            />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => uploadRef.current?.click()}
          >
            <input
              type="file"
              accept="image/*"
              ref={uploadRef}
              hidden={true}
              name="file"
              onChange={onSelectFile}
            />
            <div className="text-blue">Change Image</div>
          </div>
        </div>

        <div>
          <h5 className="mb-2">
            Click to Add Values & Card Type to Available Counties
          </h5>

          {giftCardDetails.availableCountry?.map((country, index) => (
            <div
              className="flex justify-between px-2 py-4 items-center cursor-pointer rounded-md bg-dark border border-grey mb-4"
              key={index}
            >
              <div className="flex gap-2 items-center">
                <h5>{country.currency}</h5>
                <h5>{country.name}</h5>
                <div className="text-gray/80">|</div>
                <h5>Activate</h5>
                <Switch
                  checked={country.active}
                  onCheck={() => switchAvailableCountries(country.name!)}
                />
              </div>
              <ChevronRight />
            </div>
          ))}

          <Button className="bg-transparent border border-grey !py-3">
            Add More Countries
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GiftcardDetails;
