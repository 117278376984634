import { Account, IUser } from './user';

export interface IBusinessCount {
  allBusiness: number;
  verifiedBusiness: number;
  unverifiedBusiness: number;
  suspendedBusiness: number;
}

export interface IBusiness {
  name: string;
  points: number;
  wallet: number;
  logo: null;
  approved: boolean;
  suspend: boolean;
  default: boolean;
  address: string;
  country: string;
  dialCode: string;
  phone: string;
  email: string;
  city: string;
  state: string;
  preferredBank: string;
  regNumber: string;
  brandColor: string;
  account: Account[];
  verification: BizVerification;
  user: IUser;
  type: string;
  turnOver: string;
  social: ISocial;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface ISocial {
  instagram: string;
  twitter: string;
  facebook: string;
  whatsapp: string;
}

export interface BizVerification {
  certificate: Application;
  mermat: Application;
  application: Application;
  utilityBill: Application;
  idCard: IDCard;
}

export interface Application {
  file: string;
  validated: string;
  reasons: string;
}

export interface IDCard {
  file: string;
  validated: string;
  idType: string;
  idNo: string;
  reasons: string;
}

export enum VerificationEnum {
  PENDING = 'PENDING',
  IN_REVIEW = 'IN REVIEW',
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  REJECTED = 'REJECTED',
}

export enum BusinessType {
  NEW = 'NEW',
  REGISTERED = 'REGISTERED',
  FREELANCER = 'FREELANCER',
}

export interface IBusinessUpdateComponent {
  item: IBusiness;
  refetch: any;
}

export interface IDeactivateAndActivateBusiness {
  business: IBusiness;
  refetch: any;
}

export interface IBusinessInfo {
  business: IBusiness;
  refetch?: any;
}

export interface IBusinessDetailsprops {
  business: IBusiness;
  refetch?: any;
}

export interface IProprietor {
  firstName: string;
  lastName: string;
  middleName: string;
  gender: string;
  dob: string;
  nationality: string;
  identity: string;
  idNumber: string;
  idImage: string;
  occupation: string;
  address: string;
  city: string;
  state: string;
  phone: string;
  email: string;
}

export interface IBusinessDetails {
  address: string;
  city: string;
  state: string;
  name1: string;
  name2: string;
  name3: string;
  description: string;
  category: string;
}

export interface INewBusiness {
  proprietor: IProprietor[];
  business: IBusinessDetails;
  ipAddress: string;
  appVersion: string;
  deviceIdInfo: string;
  pin: string;
}

export interface INewBusinessResponse {
  proprietor: IProprietor[];
  business: IBusinessDetails;
  process: string;
  _id: string;
  user: IUser;
  reference: string;
  payment: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IBusinessReg {
  approvedName: string;
  regNumber: string;
  tin: string;
  certificate: File;
  mermat: File;
  application: File;
  utilityBill: File;
  idCard: File;
}
