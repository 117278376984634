import { createContext, FC, ReactNode, useState } from 'react';
import {
  ICreateGiftCard,
  ICurrencyProvider,
  ICurrencyType,
  IDevice,
  IGiftCardResponse,
  IOutboundTransfer,
} from '../interface/settings';
import { IGetGiftCardDataResponse } from '../interface/response';

type Props = {
  children?: ReactNode;
};

interface IGlobalContext {
  providerDetails: ICurrencyType;
  updateProviderDetails: (value: ICurrencyType) => void;
  activeProvider: ICurrencyProvider;
  updateActiveProvider: (value: ICurrencyProvider) => void;
  cardType: string;
  setCardType: (value: string) => void;
  progressCount: number;
  setProgressCount: (value: number) => void;
  giftCardInputs: ICreateGiftCard;
  setGiftCardInputs: (value: ICreateGiftCard) => void;
  view: string;
  setView: (value: string) => void;
  giftCardDetails: IGiftCardResponse;
  setGiftCardDetails: (value: IGiftCardResponse) => void;
  deviceDetails: IDevice;
  setDeviceDetails: (value: IDevice) => void;
  outboundTransfer: IOutboundTransfer;
  setOutboundTransfer: (v: IOutboundTransfer) => void;
}

const GlobalContext = createContext({} as IGlobalContext);
export const GlobalProvider = GlobalContext.Provider;

export const GlobalProviderContainer: FC<Props> = ({ children }) => {
  const [providerDetails, setUpdateProviderDetails] = useState<ICurrencyType>(
    {} as ICurrencyType
  );
  const [activeProvider, setUpdateActiveProvider] = useState<ICurrencyProvider>(
    {} as ICurrencyProvider
  );
  const [cardType, setCardType] = useState<string>('');
  const [progressCount, setProgressCount] = useState<number>(0);
  const [giftCardInputs, setGiftCardInputs] = useState<ICreateGiftCard>(
    {} as ICreateGiftCard
  );
  const [view, setView] = useState<string>('');
  const [giftCardDetails, setGiftCardDetails] = useState<IGiftCardResponse>(
    {} as IGiftCardResponse
  );
  const [deviceDetails, setDeviceDetails] = useState<IDevice>({} as IDevice);
  const [outboundTransfer, setOutboundTransfer] = useState<IOutboundTransfer>(
    {} as IOutboundTransfer
  );

  const updateProviderDetails = (value: ICurrencyType) => {
    setUpdateProviderDetails(value);
  };

  const updateActiveProvider = (value: ICurrencyProvider) => {
    setUpdateActiveProvider(value);
  };

  return (
    <GlobalProvider
      value={{
        providerDetails,
        updateProviderDetails,
        activeProvider,
        updateActiveProvider,
        cardType,
        setCardType,
        progressCount,
        setProgressCount,
        giftCardInputs,
        setGiftCardInputs,
        view,
        setView,
        giftCardDetails,
        setGiftCardDetails,
        deviceDetails,
        setDeviceDetails,
        outboundTransfer,
        setOutboundTransfer,
      }}
    >
      {children}
    </GlobalProvider>
  );
};

export default GlobalContext;
