import * as React from "react";
import { SVGProps } from "react";
const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m12.305 10.836-2.164-2.147a5.25 5.25 0 1 0-.81.81l2.146 2.148a.584.584 0 0 0 .828 0 .583.583 0 0 0 0-.811ZM6.058 9.5a4.083 4.083 0 1 1 0-8.167 4.083 4.083 0 0 1 0 8.167Z"
      fill="#D9D9D9"
    />
  </svg>
);
export default SvgSearch;
