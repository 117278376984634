import React, { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  tension: 0.4,
  responsive: true,
  scales: {
    x: {
      grid: {
        color: 'rgba(68, 68, 68, 0.2)',
      },
    },
    y: {
      grid: {
        color: 'rgba(68, 68, 68, 0.2)',
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom' as const,
      display: true,
      align: 'start' as const,
    },
    title: {
      display: false,
    },
  },
};

export interface IProps {
  data: IChartData;
}

export interface IChartData {
  labels: string[];
  datasets: IDataset[];
}

export interface IDataset {
  label: string;
  data: { [key: string]: number } | {};
  borderColor: string;
  backgroundColor: string;
}

export const LineChart: FC<IProps> = ({ data }) => {
  return <Line options={options} data={data} />;
};
