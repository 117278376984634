import React, { useState } from "react";
import { IAirtimeToCash } from "../../../../../interface/settings";
import ItemCard from "../../../../../components/itemCard";
import { Modal } from "../../../../../components/modal";
import { useUpdateSettingsData } from "../../../../../services/settings";
import Button from "../../../../../components/button";
import Input from "../../../../../components/input";
import { useToast } from "../../../../../context/toast";

export interface IProps {
  item?: IAirtimeToCash;
  open: boolean;
  setOpen: (v: boolean) => void;
  refetch: () => void;
}

const LimitPerDay = ({ item, open, setOpen, refetch }: IProps) => {
  const [limit, setLimit] = useState<string>("");
  const { mutate, isLoading } = useUpdateSettingsData();
  const { addToast } = useToast();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: "Successfully Updated", type: "success" });
    setOpen(false);
  };

  const submit = () => {
    mutate(
      {
        airtimeToCash: {
          ...item,
          perDay: parseInt(limit),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  return (
    <>
      <div className="rounded-md bg-dark mb-6" onClick={() => setOpen(true)}>
        <ItemCard title="Limit Per Day" hint={item?.perDay} />
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        title="Limit Per Day"
        width="medium"
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <Input
          type="text"
          onChange={(e) => setLimit(e.target.value)}
          defaultValue={item?.perDay}
        />
      </Modal>
    </>
  );
};

export default LimitPerDay;
