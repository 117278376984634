import React, { useEffect, useRef, useState } from 'react';
import Button from '../button';
import { CaretDown, ChevronDown } from '../icons';
import { IDropdownList } from '../dropdown';

interface ButtonDropdown {
  label: string;
  option: string[];
  onClick: (value?: string) => void;
  top?: boolean;
  defaultValue?: string;
  updateValue?: boolean;
}

const ButtonDropDOwn = ({
  option,
  onClick,
  label,
  defaultValue,
  updateValue = false,
  top = false,
}: ButtonDropdown) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(label);
  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    defaultValue && setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div className="relative" ref={wrapperRef} onClick={() => setOpen(!open)}>
      <Button
        iconright={<CaretDown className="w-3 h-3" />}
        className="bg-dark "
      >
        {selected}
      </Button>
      {open && (
        <ul
          className={`bg-dark border border-grey max-h-40 overflow-y-auto modalScrollBar w-full absolute z-50 text-grayText font-normal flex flex-col px-4 py-2 left-0`}
        >
          {option.map((item, index) => (
            <li
              key={index}
              className="p-2 text-xs text-center cursor-pointer"
              onClick={() => {
                onClick(item);
                updateValue && setSelected(item);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ButtonDropDOwn;
