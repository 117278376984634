import React from 'react';
import Button from '../../../../components/button';
import EmptyImage from '../../../../../src/assets/image/emptyImage.png';
import { isCurrency } from '../../../../utils/isCurrency';
import { format } from 'date-fns';
import { BusinessType, IBusinessInfo } from '../../../../interface/business';
import ViewIdCard from './viewIdCard';
import Socials from './socials';
import Utilities from './utilities';
import Profile from './profile';
import SuspendBusiness from './suspendAndRestoreBusiness';
import { Link } from 'react-router-dom';
import ApplicationForm from './applicationForm';
import Memorandum from './memorandum';
import ViewCertificate from './viewCertificate';
import ViewBvn from './viewBvn';
import ViewNin from './viewNin';

const BusinessInfo = ({ business, refetch }: IBusinessInfo) => {
  return (
    <div className="my-4">
      <div className="font-normal grid grid-cols-1 lg:grid-cols-[60%_38%] items-center gap-[2%]">
        <div className="border border-dimGray rounded-md bg-dimGray ">
          <div className="flex flex-col md:flex-row items-center gap-6 bg-lightGray rounded-md p-8">
            <div className=" h-[280px] max-w-[220px] w-full ">
              <img
                src={business?.logo ? business?.logo : EmptyImage}
                alt="user_image"
                className="h-full w-full object-cover rounded-lg "
              />
            </div>
            <div className="w-full">
              <div className="flex justify-between items-center flex-wrap gap-4  w-full">
                <Link
                  to={`/app/user/${business?.user?._id}`}
                  className="font-semibold text-white"
                >
                  {`${business?.user?.firstName} ${business?.user?.lastName}`}
                </Link>
                <div className="bg-bColor py-1 px-2 gap-2 rounded-md text-[8px] flex">
                  <div className="mx-2">
                    Approved:{' '}
                    {business?.approved ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    Suspend:{' '}
                    {business?.suspend ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                  <div>|</div>
                  <div className="mx-2">
                    Default:{' '}
                    {business.default ? (
                      <span className="text-green-700">ON</span>
                    ) : (
                      <span className="text-red-700">OFF</span>
                    )}
                  </div>
                </div>
              </div>

              <div className="rounded-md bg-xBlack mt-4">
                <p className=" p-3 border-b border-activeLinkBlack">
                  {business?.user?.email}
                </p>
                <p className=" p-3 border-b border-activeLinkBlack">
                  {business?.type}
                </p>
                <p className=" p-3 border-b border-activeLinkBlack">
                  {business.user?.dialCode! + business.user?.phone!}
                </p>
                <p className=" p-3 border-b border-activeLinkBlack whitespace-normal">
                  {business?.address || business?.city || business?.country
                    ? `${business?.address}, ${business?.city}, ${business?.state}`
                    : '----'}
                </p>
                {business.createdAt && (
                  <p className=" p-3 border-b border-activeLinkBlack">
                    {format(
                      new Date(business?.createdAt),
                      'MMM do, yyyy H:mma'
                    )}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="m-6 flex flex-wrap gap-4">
            <Profile business={business} refetch={refetch} />
            <Socials business={business} />
            <SuspendBusiness business={business} refetch={refetch} />
            <ViewBvn user={business?.user} />
            <ViewNin user={business?.user} />
          </div>
        </div>
        <div className="border border-grey rounded-md bg-dimGray p-6 md:h-full">
          <div className="flex flex-col gap-3">
            <ViewIdCard business={business} refetch={refetch} />
            <Utilities business={business} refetch={refetch} />
            {business.type === BusinessType.REGISTERED && (
              <>
                <ApplicationForm business={business} refetch={refetch} />
                <Memorandum business={business} refetch={refetch} />
                <ViewCertificate business={business} refetch={refetch} />
              </>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row flex-wrap lg:flex-nowrap items-center gap-6 my-8 h-full">
        <div className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/3 flex-1 h-full">
          <small className="opacity-70">Business Account</small>
          <h3 className="font-medium">
            ₦{isCurrency(business.wallet! ? business.wallet! : 0)}
          </h3>
          <div className="flex itens-center gap-2 mt-4">
            <Button
              className="  text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Credit
            </Button>
            <Button
              className=" text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Debit
            </Button>
          </div>
        </div>

        <div className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/3 flex-1 h-full ">
          <small className="opacity-70">Reserved Account</small>
          <h3 className="font-medium">
            ₦
            {isCurrency(
              business.user?.reservedAccount!
                ? business.user?.reservedAccount!
                : 0
            )}
          </h3>
          <div className="flex itens-center gap-2 mt-4">
            <Button
              className=" text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Credit
            </Button>
            <Button
              className=" text-grayText"
              bgColor="bg-activeLinkBlack opacity-40"
            >
              Debit
            </Button>
          </div>
        </div>

        <div className="border border-grey rounded-md bg-dimGray p-4 w-full lg:w-1/3 flex-1 h-full">
          <small className="opacity-70">Account Number</small>
          <h3 className="font-medium">
            {business?.account[0] ? business.account[0]?.accountNumber : '----'}
          </h3>
          <p className="text-md mt-6">
            {business?.account[0]
              ? `${business.account[0]?.bankName} (${business.account[0].accountName})`
              : '----'}
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 ">
        <div className="border border-grey rounded-md bg-dimGray p-4  w-full">
          <small className="opacity-70">Total Transaction</small>
          {/* <h3 className="font-medium">{txn.pagination.total ?? '0'}</h3> */}
        </div>

        <div className="border border-grey rounded-md bg-dimGray p-4  w-full">
          <small className="opacity-70">Total Transaction Amount </small>
          <h3 className="font-medium">----</h3>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;
