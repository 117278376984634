import { Editor } from '@monaco-editor/react';
import parse from 'html-react-parser';
import { useState } from 'react';
import Button from '../../../../../components/button';
import Dropdown from '../../../../../components/dropdown';
import { Modal } from '../../../../../components/modal';
import Textarea from '../../../../../components/textarea';
import { useToast } from '../../../../../context/toast';
import {
  CreateEmailCampaignPayload,
  MailCampaignRecipientEnum,
} from '../../../../../interface/mail-campaigns';
import { useCreateCampaign } from '../../../../../services/mail-campaigns';
import { capitalized } from '../../../../../utils/capitalize';
import { editorData } from '../../../../../utils/editorData';
import Input from '../../../../../components/input';

const MarketCampaigns = () => {
  const [htmlContent, setHtmlContent] = useState<string>(editorData);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [zoomLevel, setZoomLevel] = useState<number>(0.9);
  const { mutate, isLoading } = useCreateCampaign();
  const [payload, setPayload] = useState<CreateEmailCampaignPayload>();
  const { addToast } = useToast();

  const recipientOptions = Object.entries(MailCampaignRecipientEnum).map(
    ([label, value]) => ({
      label: capitalized(label).replaceAll('_', ' '),
      value,
    })
  );

  const handleEmailAddressesChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const emails = e.target.value
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email);
    setPayload({ ...payload!, recipientEmails: emails, body: htmlContent });
  };

  const submit = () => {
    mutate(payload!, {
      onSuccess: () => {
        setOpenModal(false);
        addToast({ msg: 'Campaign successfully sent', type: 'success' });
      },
    });
  };

  return (
    <div>
      <div className="flex justify-end">
        <Button bgColor="bg-activeLinkBlack" onClick={() => setOpenModal(true)}>
          Send Campaign
        </Button>
      </div>
      <div className="w-full grid lg:grid-cols-2 gap-4 mt-6">
        <Editor
          height={'100vh'}
          defaultLanguage="html"
          theme="vs-dark"
          value={htmlContent}
          onChange={(value) => setHtmlContent(value || '')}
          options={{
            minimap: { enabled: false },
          }}
        />
        <div className="h-screen overflow-y-auto relative">
          <div className="flex space-x-2 absolute top-4 left-6 z-[5]">
            <div
              className="flex justify-center items-center bg-activeLinkBlack rounded-full h-5 w-5 text-xl cursor-pointer"
              onClick={() => setZoomLevel((prev) => Math.max(0.5, prev - 0.1))}
            >
              -
            </div>
            <span className="text-activeLinkBlack">
              {(zoomLevel * 100).toFixed(0)}%
            </span>
            <div
              className="flex justify-center items-center bg-activeLinkBlack rounded-full h-5 w-5 text-xl cursor-pointer"
              onClick={() => setZoomLevel((prev) => Math.min(2, prev + 0.1))}
            >
              +
            </div>
          </div>
          <div
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: 'top left',
              width: `${100 / zoomLevel}%`,
            }}
          >
            {parse(htmlContent)}
          </div>
        </div>
      </div>

      <Modal
        padding={false}
        isVisible={openModal}
        center
        width="medium"
        close={setOpenModal}
        title="Send Email"
        header
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Send Email
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2 p-4">
          <div className="py-2">
            <small className="text-xs">Subject</small>
            <Input
              onChange={(e) =>
                setPayload({ ...payload!, subject: e.target.value })
              }
              type="text"
            />
          </div>

          <div className="py-2">
            <small className="w-1/4">Recipients</small>
            <Dropdown
              label={'Recipient Options'}
              option={recipientOptions}
              onChange={(e) => setPayload({ ...payload!, recipient: e?.value })}
            />
          </div>

          {payload?.recipient === MailCampaignRecipientEnum.SELECTED_USERS && (
            <div className="py-2">
              <small className="w-1/4 ">Email Addresses</small>
              <Textarea
                placeholder="Enter up to 10 email addresses, separated by commas"
                onChange={handleEmailAddressesChange}
              />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MarketCampaigns;
