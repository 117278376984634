import React, { useContext, useState } from 'react';
import Button from '../../button';
import { IUpdateCardBalance } from '../../../interface/cards';
import { useUpdateCardBalance } from '../../../services/cards';
import Input from '../../input';
import { Modal } from '../../modal';
import ToastContext from '../../../context/toast';

interface IProps {
  cardId: string;
  refetch: () => void;
}

const EditCard = ({ cardId, refetch }: IProps) => {
  const [open, setOpen] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [payload, setPayload] = useState<IUpdateCardBalance>(
    {} as IUpdateCardBalance
  );
  const { mutate, isLoading } = useUpdateCardBalance();
  const { addToast } = useContext(ToastContext);

  const submit = () => {
    if (!payload.amount) {
      addToast({
        msg: 'Please enter an amount',
        type: 'error',
      });
      return;
    } else if (!payload?.secret) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
      return;
    }
    mutate(
      {
        ...payload,
        cardId,
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({
            msg: 'Successfully Updated',
            type: 'success',
          });
        },
      }
    );
  };

  return (
    <>
      <div className=" w-auto">
        <Button
          className=" text-grayText"
          bgColor="bg-activeLinkBlack"
          onClick={() => setOpen(true)}
        >
          Edit
        </Button>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        width="medium"
        header
        padding
        overflow
        title="Edit Card Balance"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div className="py-2">
            <small className="text-xs">Amount</small>
            <Input
              defaultValue={payload?.amount}
              onChange={(e) => {
                setPayload({ ...payload!, amount: Number(e?.target.value) });
              }}
            />
          </div>
          <div className="py-2">
            <p className="pb-2">
              <small className="w-1/4">Access key</small>
            </p>
            <Input
              name="secret"
              type={showToken ? 'text' : 'password'}
              value={payload?.secret}
              onChange={(e) =>
                setPayload({ ...payload!, secret: e.target.value })
              }
              trailing={
                <p
                  onClick={() => setShowToken(!showToken)}
                  className="text-sm text-blue"
                >
                  Show
                </p>
              }
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EditCard;
