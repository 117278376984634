import { AxiosResponse, AxiosError } from 'axios';
import Axios from './axios';
import {
  ICreateGiftCardDataResponse,
  IGetGiftCardDataResponse,
  IResponse,
} from '../interface/response';
import { useToast } from '../context/toast';
import { useMutation, useQuery } from 'react-query';

const updateGiftCard = async ({
  id,
  payload,
}: {
  id: string;
  payload: FormData;
}) => {
  const response: AxiosResponse<IResponse<IGetGiftCardDataResponse>> =
    await Axios.patch(`/gift-card-voucher/${id}`, payload);
  return response.data;
};

const createGiftCard = async (payload: FormData) => {
  const response: AxiosResponse<IResponse<ICreateGiftCardDataResponse>> =
    await Axios.post('/gift-card-voucher', payload);
  return response.data;
};

const getGiftCard = async () => {
  const response: AxiosResponse<IResponse<IGetGiftCardDataResponse[]>> =
    await Axios.get('/gift-card-voucher');
  return response.data;
};

export const useCreateGiftCard = () => {
  const { addToast } = useToast();

  return useMutation(createGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error creating giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useGetGiftCard = () => {
  const { addToast } = useToast();

  return useQuery('giftCard', getGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error getting giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useUpdateGiftCard = () => {
  const { addToast } = useToast();

  return useMutation(updateGiftCard, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error updating giftcard',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};
