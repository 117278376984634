import Button from '../button';
import { ReconciliationResponse } from '../../interface/user';
import { format } from 'date-fns';
import { isCurrency } from '../../utils/isCurrency';

const CardReconciliationData = ({
  data,
}: {  data?: ReconciliationResponse;
}) => {
  return (
    <div>
      <div className="border-b border-grey">
        <div className=" py-4 px-4 flex flex-col gap-6 items-center">
          <div className="border border-slate-300/20 py-4 px-4 rounded-xl w-full">
            <p className="font-medium text-base text-grayText">Total Credits</p>
            <div className="flex items-center">
              <div className="py-2 w-[33%]">
                <small className="text-xs">Amount</small>
                <p>{isCurrency(data?.totalCredits.amount!)}</p>
              </div>
              <div className="py-2  w-[33%]">
                <small className="text-xs">Currency</small>
                <p>{data?.totalCredits.currency} </p>
              </div>
              <div className="py-2 w-[33%]">
                <small className="text-xs">Count</small>
                <p>{data?.totalCredits.count} </p>
              </div>
            </div>
          </div>

          <div className="border border-slate-300/20 py-4 px-4 rounded-xl w-full">
            <p className="font-medium text-base text-grayText">Total Debits</p>
            <div className="flex items-center ">
              <div className="py-2 w-[33%]">
                <small className="text-xs">Amount</small>
                <p>{isCurrency(data?.totalDebits.amount!)} </p>
              </div>
              <div className="py-2  w-[33%]">
                <small className="text-xs">Currency</small>
                <p>{data?.totalDebits.currency} </p>
              </div>
              <div className="py-2 w-[33%]">
                <small className="text-xs">Count</small>
                <p>{data?.totalDebits.count} </p>
              </div>
            </div>
          </div>
          <div className="border border-slate-300/20 py-4 px-4 rounded-xl w-full">
            <p className="font-medium text-base text-grayText">
              Amount Difference
            </p>
            <div className="flex items-center ">
              <div className="py-2 w-[33%]">
                <small className="text-xs">Amount</small>
                <p>{isCurrency(data?.totalCredits.amount! - data?.totalDebits.amount!)} </p>
              </div>
            </div>
          </div>
          <div className="border border-slate-300/20 py-4 px-4 rounded-xl w-full">
            <p className="font-medium text-base text-grayText">Date Range</p>
            <div className="flex items-center gap-14">
              <div className="py-2 w-[50%]">
                <small className="text-xs">From</small>
                <p>
                  {format(
                    new Date(data?.dateRange.from!),
                    'MMM do, yyyy H:mma'
                  )}{' '}
                </p>
              </div>
              <div className="py-2  w-[50%]">
                <small className="text-xs">To</small>
                <p>
                  {format(new Date(data?.dateRange.to!), 'MMM do, yyyy H:mma')}{' '}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReconciliationData;
