import React, { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { useDeleteNotifications } from '../../../../services/notifications';
import ToastContext from '../../../../context/toast';

interface IDeleteNotification {
  id: string;
  refetch: () => void;
}

const DeleteNotification: FC<IDeleteNotification> = ({ id, refetch }) => {
  const [open, setOpen] = useState(false);

  const { isLoading, mutate } = useDeleteNotifications();
  const { addToast } = useContext(ToastContext);

  const deleteNotification = () => {
    mutate(id, {
      onSuccess: () => {
        addToast({
          msg: 'Successfully Deleted',
          type: 'success',
        });
        setOpen(false);
        refetch();
      },
    });
  };

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-red-500"
        >
          Delete
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        width="medium"
      >
        <div className="border-b border-grey">
          <div className="px-4 py-4 flex flex-col gap-6 items-center">
            <h4>Are you sure you want to Delete this Notification?</h4>
            <div className="flex gap-4">
              <Button
                onClick={deleteNotification}
                disabled={isLoading}
                loading={isLoading}
              >
                Yes, Continue
              </Button>
              <Button
                onClick={() => setOpen(false)}
                bgColor="bg-activeLinkBlack"
                disabled={isLoading}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteNotification;
