import React, { useEffect, useState } from "react";
import { BarChart } from "..";
import {
  useUserSummaryMonth,
  useUserSummaryRange,
  useUserSummaryYear,
} from "../../../services/dashboard";
import ChartDropDown from "../../chartDropDown";

const UsersBarChart = () => {
  let date = new Date();
  let rangeStartDate = `${date.getDate() - 5}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let rangeEndDate = new Date().toLocaleDateString().replaceAll("/", "-");

  const [openDropDown, setopenDropDown] = useState<boolean>(false);
  const [period, setPeriod] = useState("This month");
  const [allUsersLabel, setAllUsersLabel] = useState<{ [key: string]: number }>(
    {}
  );
  const [bvnUsersLabel, setBvnUsersLabel] = useState<{ [key: string]: number }>(
    {}
  );
  const [ninUsersLabel, setNinUsersLabel] = useState<{ [key: string]: number }>(
    {}
  );
  const [range, setRange] = useState<{ startDate: string; endDate: string }>({
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  const { data: bvnUsersYear } = useUserSummaryYear({ bvn: true });
  const { data: ninUsersYear } = useUserSummaryYear({ nin: true });
  const { data: allUsersYear } = useUserSummaryYear({});
  const { data: bvnUsersMonth } = useUserSummaryMonth({ bvn: true });
  const { data: ninUsersMonth } = useUserSummaryMonth({ nin: true });
  const { data: allUsersMonth } = useUserSummaryMonth({});
  const { data: bvnUsersRange } = useUserSummaryRange({ ...range, bvn: true });
  const { data: ninUsersRange } = useUserSummaryRange({ ...range, nin: true });
  const { data: allUsersRange } = useUserSummaryRange(range);

  const barChartData = {
    labels: [],
    datasets: [
      {
        label: "All Users",
        data: allUsersLabel,
        backgroundColor: "#ff6384",
      },
      {
        label: "Users with BVN",
        data: bvnUsersLabel,
        backgroundColor: "#089bff",
      },
      {
        label: "Users with NIN",
        data: ninUsersLabel,
        backgroundColor: "#4ac0c0",
      },
    ],
  };

  useEffect(() => {
    if (
      bvnUsersMonth &&
      ninUsersMonth &&
      allUsersMonth &&
      period === "This month"
    ) {
      setAllUsersLabel(allUsersMonth!.data.data);
      setBvnUsersLabel(bvnUsersMonth!.data.data);
      setNinUsersLabel(ninUsersMonth!.data.data);
    }
    if (
      bvnUsersYear &&
      ninUsersYear &&
      allUsersYear &&
      period === "This year"
    ) {
      setAllUsersLabel(allUsersYear!.data.data);
      setBvnUsersLabel(bvnUsersYear!.data.data);
      setNinUsersLabel(ninUsersYear!.data.data);
    }

    if (bvnUsersRange && ninUsersRange && allUsersRange && period === "Range") {
      setAllUsersLabel(allUsersRange!.data.data);
      setBvnUsersLabel(bvnUsersRange!.data.data);
      setNinUsersLabel(ninUsersRange!.data.data);
    }
  }, [bvnUsersMonth, ninUsersMonth, allUsersMonth, period]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">Users Chart</h3>
        <ChartDropDown
          open={openDropDown}
          setOpen={setopenDropDown}
          value={period}
          setValue={setPeriod}
          data={periodArray}
          range={range!}
          setRange={setRange}
        />
      </div>
      <BarChart data={barChartData} />
    </div>
  );
};

export default UsersBarChart;

const periodArray = [
  {
    label: "Range",
  },
  {
    label: "This month",
  },
  {
    label: "This year",
  },
];
