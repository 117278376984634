import React from 'react';
import { ChevronRight } from '../icons';

interface IItemCard {
  title: string;
  markup?: any;
  rate?: any;
  hint?: string | number;
  showIcon?: boolean;
}

const ItemCard = ({
  title,
  markup,
  rate,
  hint,
  showIcon = true,
}: IItemCard) => {
  return (
    <div className="flex justify-between p-5 items-center cursor-pointer">
      <h5>{title}</h5>
      <div className="flex gap-2 items-center">
        {rate && (
          <div className="bg-bColor py-1 px-2 gap-2 text-xs rounded-md flex">
            <span className="text-gray">C-Rate: </span>₦{rate}{' '}
            <span className="text-gray">|</span> ₦{markup ?? 0}
          </div>
        )}
        {hint && (
          <div className="bg-bColor py-1 px-2 gap-2 text-xs rounded-md flex">
            <div>{hint}</div>
          </div>
        )}
        {showIcon && <ChevronRight />}
      </div>
    </div>
  );
};

export default ItemCard;
