import React, { useEffect, useState } from 'react';
import { BarChart } from '..';
import ChartDropDown from '../../chartDropDown';
import {
  useSettlementSumaryMonth,
  useSettlementSumaryYear,
  useSettlementSummaryRange,
} from '../../../services/dashboard';
import { totalSettlement } from '../../../utils/totalSettlement';
import { isCurrency } from '../../../utils/isCurrency';

const UsersSettlement = () => {
  let date = new Date();
  let rangeStartDate = `${date.getDate() - 5}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let rangeEndDate = new Date().toLocaleDateString().replaceAll('/', '-');

  const [openDropDown, setopenDropDown] = useState<boolean>(false);
  const [period, setPeriod] = useState('This month');
  const [allSettlementLabel, setAllSettlementLabel] = useState<{
    [key: string]: number;
  }>({});
  const [range, setRange] = useState<{ startDate: string; endDate: string }>({
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  const { data: settlementYear } = useSettlementSumaryYear();
  const { data: settlementMonth } = useSettlementSumaryMonth();
  const { data: settlementRange } = useSettlementSummaryRange(range);

  const barChartData = {
    labels: [],
    datasets: [
      {
        label: 'Settlement',
        data: allSettlementLabel,
        backgroundColor: '#ff6384',
      },
    ],
  };

  useEffect(() => {
    if (settlementMonth && period === 'This month') {
      setAllSettlementLabel(settlementMonth!.data.data);
    }
    if (settlementYear && period === 'This year') {
      setAllSettlementLabel(settlementYear!.data.data);
    }

    if (settlementRange && period === 'Range') {
      setAllSettlementLabel(settlementRange!.data.data);
    }
  }, [period, settlementMonth, settlementRange, settlementYear]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">
          Settlement Chart{' '}
          <span className="font-normal text-xs">
            (₦
            {isCurrency(totalSettlement(allSettlementLabel) ?? 0)})
          </span>
        </h3>
        <ChartDropDown
          open={openDropDown}
          setOpen={setopenDropDown}
          value={period}
          setValue={setPeriod}
          data={periodArray}
          range={range!}
          setRange={setRange}
        />
      </div>
      <BarChart data={barChartData} />
    </div>
  );
};

export default UsersSettlement;

const periodArray = [
  {
    label: 'Range',
  },
  {
    label: 'This month',
  },
  {
    label: 'This year',
  },
];
