import React, { useEffect, useState } from "react";
import Button from "../../../../../components/button";
import { Modal } from "../../../../../components/modal";
import { useTransactionsStatus } from "../../../../../services/transaction";
import { Loading } from "../../../../../components/icons";
import { Link } from "react-router-dom";

interface ISafeHavenRequery {
  sessionId: string;
}

function Requery({ sessionId }: ISafeHavenRequery) {
  const [open, setOpen] = useState(false);
  const [shouldRequery, setShouldRequery] = useState(false);
  const {data, isLoading, refetch} = useTransactionsStatus(sessionId, shouldRequery);

  const handleRequery = () => {
    setShouldRequery(true);
    refetch();
  };

  useEffect(() => {
    if (!isLoading && data?.success) {
      setOpen(false);
    }
  }, [isLoading, data]);

  return (
    <>
      <div className=" w-auto">
        <Button bgColor={`bg-activeLinkBlack`} onClick={() => setOpen(true)}>
          Requery
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center ">
          <p className="pb-4 whitespace-normal">
            Are your sure you want to requery {sessionId}
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link
              to=""
              onClick={handleRequery}
              className="text-blue"
            >
              Yes, Requery
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
}

export default Requery;
