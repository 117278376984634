import { useContext, useState } from 'react';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import ToastContext from '../../../../context/toast';
import { IUserProps } from '../../../../interface/user';
import { useCreditUser } from '../../../../services/user';

interface IUserWalletProps extends IUserProps {
  refetch: () => void;
  role: string;
}

const CreditUserWallet = ({ user, refetch, role }: IUserWalletProps) => {
  const [open, setOpen] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [accessKey, setAccessKey] = useState<string>('');
  const [amount, setAmount] = useState<number>();
  const { addToast } = useContext(ToastContext);

  const { mutate, isLoading } = useCreditUser();

  const submit = () => {
    if (!accessKey) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
      return;
    } else if (!amount) {
      addToast({
        msg: 'Please enter amount',
        type: 'error',
      });
      return;
    } else {
      mutate(
        {
          userId: user?._id!,
          amount: amount,
          secret: accessKey,
        },
        {
          onSuccess: () => {
            setOpen(false);
            addToast({
              msg: 'User Successfully Credited',
              type: 'success',
            });
            setAccessKey('')
            refetch();
          },
        }
      );
    }
  };

  return (
    <>
      <div className=" w-auto">
        <Button
          bgColor={`bg-activeLinkBlack ${
            role === 'SUPER_ADMIN' ? '' : 'opacity-40'
          }`}
          onClick={() => setOpen(true)}
        >
          Credit
        </Button>
      </div>
      <Modal
        isVisible={open}
        header
        width="medium"
        close={setOpen}
        title="Credit user wallet"
        padding={false}
        showFooter
        footer={
          <div className="flex justify-end">
            <Button type="submit" loading={isLoading} onClick={submit}>
              Save
            </Button>
          </div>
        }
        center
      >
        <form>
          <div className="p-2 border-b border-grey flex flex-col">
            <p className="pb-2">
              <small className="w-1/4">Amount</small>
            </p>
            <Input
              name="wallet"
              type="number"
              leading="₦"
              onChange={(e) => setAmount(Number(e.target.value))}
            />
            <p className="py-2">
              <small className="w-1/4">Access key</small>
            </p>
            <Input
              name="secret"
              type={showToken ? 'text' : 'password'}
              value={accessKey}
              onChange={(e) => setAccessKey(e.target.value)}
              trailing={
                <p
                  onClick={() => setShowToken(!showToken)}
                  className="text-sm text-blue"
                >
                  Show
                </p>
              }
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CreditUserWallet;
