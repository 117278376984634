import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { useToast } from '../context/toast';
import { IList, IResponse } from '../interface/response';
import {
  IBusiness,
  IBusinessCount,
  INewBusinessResponse,
} from '../interface/business';
import Axios from './axios';

export interface IBizApproval {
  id: string;
  body: object;
}

export interface IRegBiz {
  id: string;
  body: FormData;
}

export interface IDeleteBiz {
  secret: string;
}

const getBusinessCount = async () => {
  const response: AxiosResponse<IResponse<IBusinessCount>> = await Axios.get(
    '/business/count'
  );
  return response.data;
};

const business = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<IBusiness>>> = await Axios.get(
    '/business/all',
    { params }
  );
  return response.data;
};

const businessReg = async (params: object) => {
  const response: AxiosResponse<IResponse<IList<INewBusinessResponse>>> =
    await Axios.get('/business-registration/all', { params });
  return response.data;
};

const updateBusinessReg = async (params: IBizApproval) => {
  const response: AxiosResponse<IResponse<INewBusinessResponse>> =
    await Axios.patch(`/business-registration/${params.id}`, {
      ...params.body,
    });
  return response;
};

const deleteBusiness = async ({
  payload,
  id,
}: {
  payload: IDeleteBiz;
  id: string;
}) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.delete(
    `/business/${id}`,
    { data: payload }
  );
  return response;
};

const businessApproval = async (params: IBizApproval) => {
  const response: AxiosResponse<IResponse<IBusiness>> = await Axios.patch(
    `/business/approve/${params.id}`,
    { ...params.body }
  );
  return response;
};

const businessUpdate = async (params: IBizApproval) => {
  const response: AxiosResponse<IResponse<IBusiness>> = await Axios.patch(
    `/business/${params.id}`,
    { ...params.body }
  );
  return response;
};

export const useDeleteBusiness = () => {
  const { addToast } = useToast();

  return useMutation(deleteBusiness, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error deleting business',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useBusinessCount = () => {
  const { addToast } = useToast();
  return useQuery('BusinessCount', getBusinessCount, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching business',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
  });
};

export const useBusiness = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['business', params], () => business(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching user',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useBusinessReg = (params: object) => {
  const { addToast } = useToast();
  return useQuery(['business-reg', params], () => businessReg(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching business',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useUpdateBusinessReg = () => {
  const { addToast } = useToast();

  return useMutation(updateBusinessReg, {
    onSuccess: async (
      response: AxiosResponse<IResponse<INewBusinessResponse>>
    ) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useBusinessApproval = () => {
  const { addToast } = useToast();

  return useMutation(businessApproval, {
    onSuccess: async (response: AxiosResponse<IResponse<IBusiness>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useBusinessUpdate = () => {
  const { addToast } = useToast();

  return useMutation(businessUpdate, {
    onSuccess: async (response: AxiosResponse<IResponse<IBusiness>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};
