import React, { useContext, useState } from 'react';
import Button from '../../../../../../components/button';
import { Modal } from '../../../../../../components/modal';
import GlobalContext from '../../../../../../context/globalContext';
import { useUpdateGiftCard } from '../../../../../../services/gift-card';
import { useGetGiftCardVoucher } from '../../../../../../services/settings';
import deleteGiftcard from './delete-giftcard';

const SeveEditGiftCard = () => {
  const { setProgressCount, giftCardDetails } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const { data, refetch } = useGetGiftCardVoucher();
  const { mutate: updateGiftcard } = useUpdateGiftCard();

  const saveChnages = () => {
    const formData = new FormData();
    formData.append('image', giftCardDetails.image);
    const { image, ...dataInputs } = giftCardDetails;
    formData.append('data', JSON.stringify(dataInputs));
    updateGiftcard(
      { id: giftCardDetails._id, payload: formData },
      {
        onSuccess: () => {
          setProgressCount(0);
          refetch();
        },
      }
    );
  };

  return (
    <div>
      <Button
        className="bg-transparent border border-grey"
        onClick={() => setOpen(true)}
      >
        Save All Changes
      </Button>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Save Changes"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-between">
            <Button
              onClick={() => setOpen(false)}
              className="border border-grey"
              bgColor="bg-transparent"
            >
              Cancel
            </Button>
            <Button onClick={saveChnages}>Yes</Button>
          </div>
        }
      >
        <div className="text-center">
          <h5>Are you sure you want to save these changes</h5>
        </div>
      </Modal>
    </div>
  );
};

export default SeveEditGiftCard;
