import React, { useContext, useEffect, useState } from 'react';
import Input from '../../../../../components/input';
import { Modal } from '../../../../../components/modal';
import Switch from '../../../../../components/switch';
import {
  IBankCharge,
  IBankProvider,
  ISettingsResponse,
} from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import { capitalized } from '../../../../../utils/capitalize';
import { useToast } from '../../../../../context/toast';
import { Back, Close } from '../../../../../components/icons';
import Button from '../../../../../components/button';
import GlobalContext from '../../../../../context/globalContext';
import OutboundTransfers from './outbound-transfers';
import ItemCard from '../../../../../components/itemCard';

interface IBank {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const Bank = ({ settingsData, refetch }: IBank) => {
  const bankData = settingsData?.bank;
  const figurData = settingsData?.figur;
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useUpdateSettingsData();
  const [editItem, setEditItem] = useState<IBankProvider>({} as IBankProvider);
  const [editChargeOpen, setEditChargeOpen] = useState<boolean>(false);
  const [editCollectionOpen, setEditCollectionOpen] = useState<boolean>(false);
  const { addToast } = useToast();
  const { progressCount, setProgressCount, setOutboundTransfer } =
    useContext(GlobalContext);

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: 'Successfully Updated', type: 'success' });
  };

  const submit = () => {
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === editItem.name
              ? { ...provider, NIP: editItem.NIP, rate: editItem.rate }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleVirtualAcctChange = (itemEdit: IBankProvider) => {
    setEditItem({
      ...itemEdit,
      virtualAccount: !editItem.virtualAccount,
    });
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === itemEdit.name
              ? { ...provider, virtualAccount: !provider.virtualAccount }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleBusinessChange = (itemEdit: IBankProvider) => {
    setEditItem({
      ...itemEdit,
      business: !editItem.business,
    });
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === itemEdit.name
              ? { ...provider, business: !provider.business }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handlePersonalChange = (itemEdit: IBankProvider) => {
    setEditItem({
      ...itemEdit,
      personal: !editItem.personal,
    });
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === itemEdit.name
              ? { ...provider, personal: !provider.personal }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleDynamicChange = (itemEdit: IBankProvider) => {
    setEditItem({
      ...itemEdit,
      dynamic: !editItem.dynamic,
    });
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === itemEdit.name
              ? { ...provider, dynamic: !provider.dynamic }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleTransferChange = (itemEdit: IBankProvider) => {
    setEditItem({
      ...itemEdit,
      transfer: !editItem.transfer,
    });
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === itemEdit.name
              ? { ...provider, transfer: !provider.transfer }
              : provider
          ),
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const submitCharge = () => {
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === editItem.name
              ? { ...provider, charge: editItem.charge }
              : provider
          ),
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setEditChargeOpen(false);
        },
      }
    );
  };

  const submitCollection = () => {
    mutate(
      {
        bank: {
          provider: bankData?.provider.map((provider) =>
            provider.name === editItem.name
              ? { ...provider, collection: editItem?.collection }
              : provider
          ),
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setEditCollectionOpen(false);
        },
      }
    );
  };

  const addCharges = () => {
    const newCharges = {
      limit: 0,
      amount: 0,
    };
    setEditItem({ ...editItem, charge: [...editItem.charge, newCharges] });
  };

  const deleteCharge = (index: number) => {
    setEditItem({
      ...editItem,
      charge: editItem.charge.filter((item, itemIndex) => itemIndex !== index),
    });
  };

  const handleAddChargeInputChange = (
    value: string,
    index: number,
    inputName: string
  ) => {
    setEditItem((prevEditItem) => {
      const updatedCharge: IBankCharge[] = [...prevEditItem.charge];
      if (inputName === 'limit') {
        updatedCharge[index].limit = parseInt(value);
      } else if (inputName === 'amount') {
        updatedCharge[index].amount = parseInt(value);
      }
      return { ...prevEditItem, charge: updatedCharge };
    });
  };

  const handleFoward = () => {
    setProgressCount(progressCount + 1);
  };

  const handleBack = () => {
    setProgressCount(0);
  };

  useEffect(() => {
    setProgressCount(0);
  }, []);
  return (
    <div className="md:w-[36rem] w-full text-sm">
      {progressCount === 0 ? (
        <div>
          {bankData?.provider.map((item: IBankProvider, index) => (
            <div key={index}>
              <div className="mb-2 bg-dark px-2 py-3 rounded-md flex justify-between items-center">
                <div>{capitalized(item.name)}</div>
                <div className="flex gap-1 items-center text-xs">
                  <div className="bg-bColor py-1 px-2 gap-2 rounded-md text-[8px] flex">
                    <div className="mx-2">
                      Account:{' '}
                      {item.virtualAccount === true ? (
                        <span className="text-green-700">ON</span>
                      ) : (
                        <span className="text-red-700">OFF</span>
                      )}
                    </div>
                    <div>|</div>
                    <div className="mx-2">
                      Personal:{' '}
                      {item.personal === true ? (
                        <span className="text-green-700">ON</span>
                      ) : (
                        <span className="text-red-700">OFF</span>
                      )}
                    </div>
                    <div>|</div>
                    <div className="mx-2">
                      Business:{' '}
                      {item.business === true ? (
                        <span className="text-green-700">ON</span>
                      ) : (
                        <span className="text-red-700">OFF</span>
                      )}
                    </div>
                    <div>|</div>
                    <div className="mx-2">
                      Transfer:{' '}
                      {item.transfer === true ? (
                        <span className="text-green-700">ON</span>
                      ) : (
                        <span className="text-red-700">OFF</span>
                      )}
                    </div>
                    <div>|</div>
                    <div className="mx-2">
                      Dynamic:{' '}
                      {item.dynamic === true ? (
                        <span className="text-green-700">ON</span>
                      ) : (
                        <span className="text-red-700">OFF</span>
                      )}
                    </div>
                  </div>
                  <div
                    className="mx-5 cursor-pointer"
                    onClick={() => {
                      setOpen(true);
                      setEditItem(item);
                    }}
                  >
                    Edit
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="rounded-md bg-dark w-full text-sm mt-6">
            <div
              onClick={() => {
                handleFoward();
                setOutboundTransfer(figurData?.outboundTransfer!);
              }}
            >
              <ItemCard
                title={figurData?.outboundTransfer ? 'Outbound Transfer' : ''}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="bg-dark rounded-md flex items-center justify-center w-7 h-7 mb-6 cursor-pointer"
            onClick={() => handleBack()}
          >
            <Back />
          </div>
          {progressCount === 1 && <OutboundTransfers />}
        </div>
      )}

      <Modal
        isVisible={open}
        close={setOpen}
        title={capitalized(editItem.name)}
        padding={false}
        center
        header
        width="medium"
      >
        <div>
          <div className="flex justify-between items-center py-4 px-4">
            <div>Virtual Account</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem.virtualAccount}
                onCheck={() => {
                  handleVirtualAcctChange(editItem);
                }}
              />
            </span>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>Transfer</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem.transfer}
                onCheck={() => {
                  handleTransferChange(editItem);
                }}
              />
            </span>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>Business</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem.business}
                onCheck={() => {
                  handleBusinessChange(editItem);
                }}
              />
            </span>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>Personal</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem.personal}
                onCheck={() => {
                  handlePersonalChange(editItem);
                }}
              />
            </span>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>Dynamic</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem.dynamic}
                onCheck={() => {
                  handleDynamicChange(editItem);
                }}
              />
            </span>
          </div>
          {editItem?.collection && (
            <>
              <hr className="w-full border-gray" />
              <div className="flex justify-between py-4 px-4">
                <div>Collection</div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setEditCollectionOpen(true);
                    setOpen(false);
                  }}
                >
                  Edit
                </div>
              </div>
            </>
          )}
          <hr className="w-full border-gray" />
          <div className="flex justify-between py-4 px-4">
            <div>Charge</div>
            <div
              className="cursor-pointer"
              onClick={() => {
                setEditChargeOpen(true);
                setOpen(false);
              }}
            >
              Edit
            </div>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>NIP</div>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setEditItem({ ...editItem, NIP: parseInt(e.target.value) })
                }
                defaultValue={editItem.NIP}
                trailing="Save"
                submit={submit}
              />
            </div>
          </div>
          <hr className="w-full border-gray" />
          <div className="flex justify-between items-center py-4 px-4">
            <div>Rate</div>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setEditItem({ ...editItem, rate: parseInt(e.target.value) })
                }
                defaultValue={editItem.rate}
                trailing="Save"
                submit={submit}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isVisible={editChargeOpen}
        close={setEditChargeOpen}
        title={'Charge'}
        center
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              className="!py-3"
              onClick={submitCharge}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
        width="medium"
      >
        <div className="flex flex-col gap-2">
          <div className="grid grid-cols-[45%_45%_8%] gap-4">
            <div>Limit</div>
            <div>Amount</div>
          </div>

          {editItem?.charge?.map((item, index) => (
            <div
              className="grid grid-cols-[45%_45%_8%] gap-4 items-center"
              key={index}
            >
              <Input
                defaultValue={item.limit}
                onChange={(e) =>
                  handleAddChargeInputChange(e.target.value, index, 'limit')
                }
              />
              <Input
                defaultValue={item.amount}
                onChange={(e) =>
                  handleAddChargeInputChange(e.target.value, index, 'amount')
                }
              />
              {index !== 0 && (
                <Close
                  className="cursor-pointer"
                  onClick={() => deleteCharge(index)}
                />
              )}
            </div>
          ))}
          <Button
            className="bg-transparent border-blue border-[1px] !py-3"
            onClick={addCharges}
          >
            Add More Charges
          </Button>
        </div>
      </Modal>
      <Modal
        isVisible={editCollectionOpen}
        close={setEditCollectionOpen}
        title={'Collection'}
        center
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              className="!py-3"
              onClick={submitCollection}
              loading={isLoading}
              disabled={isLoading}
            >
              Save
            </Button>
          </div>
        }
        width="medium"
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-between items-center py-2">
            <div>Visible</div>
            <span className="max-w-[150px] w-full flex justify-end">
              <Switch
                checked={editItem?.collection?.visible}
                onCheck={() =>
                  setEditItem({
                    ...editItem,
                    collection: {
                      ...editItem?.collection,
                      visible: !editItem?.collection?.visible,
                    },
                  })
                }
              />
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center py-2">
          <div>Percent</div>
          <div className="w-2/5">
            <Input
              type="text"
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  collection: {
                    ...editItem?.collection,
                    percent: parseInt(e.target.value),
                  },
                })
              }
              defaultValue={editItem?.collection?.percent}
            />
          </div>
        </div>
        <div className="flex justify-between items-center py-2">
          <div>Cap</div>
          <div className="w-2/5">
            <Input
              type="text"
              onChange={(e) =>
                setEditItem({
                  ...editItem,
                  collection: {
                    ...editItem?.collection,
                    cap: parseInt(e.target.value),
                  },
                })
              }
              defaultValue={editItem?.collection?.cap}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Bank;
