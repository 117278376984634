import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "../../../../components/modal";
import { IUserProps } from "../../../../interface/user";
import { useGetUserNIN } from "../../../../services/user";

const UserNinDetails = ({ user }: IUserProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const { data } = useGetUserNIN({
    enabled: user?.verification.nin ? true : false,
    id: user?._id,
  });

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-xs bg-black p-1 items-center justify-center flex rounded-md"
        >
          NIN
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title={`${user?.firstName} ${user?.lastName}`}
        header={true}
      >
        <div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Customer</small>
              <small>{`${data?.data.firstName} ${data?.data.lastName}`}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Email</small>
              <small>{data?.data.email}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Identity</small>
              <small>{data?.data.identity}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Gender</small>
              <small>{data?.data.gender}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Email</small>
              <small>{data?.data.email}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Date of birth</small>
              <small>{data?.data.dob}</small>
            </div>
          </div>
          <div className="flex justify-between py-4 px-4">
            <small>Phone</small>
            <small>{data?.data.phone}</small>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UserNinDetails;
