import { useContext, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import AuthContext from '../../../context/auth';
import { IUser } from '../../../interface/user';
import { Bars, Close, Logo } from '../../icons';

interface IMainLayout {
  userData: IUser;
}

const MainLayout = ({ userData }: IMainLayout) => {
  const [menu, setMenu] = useState<boolean>(false);
  const { setLogout } = useContext(AuthContext);
  const nav = [
    'Dashboard',
    'User',
    'Transaction',
    'Business',
    'Settings',
    'Notifications',
  ];

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const lastSplitItem = splitLocation[splitLocation.length - 1];

  const switchMenu = (identifier: string) => {
    switch (identifier) {
      case 'dashboard':
        return 'app';

      default:
        return identifier;
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full bg-dark ">
        <div className="container">
          <div className="flex items-center justify-between lg:justify-end py-4 relative">
            <div className="mr-6">
              <Logo width={100} height={30} />
            </div>
            <div
              className={`${
                menu ? 'flex' : 'hidden lg:flex'
              } bg-dark flex flex-col lg:flex-row items-center justify-end lg:justify-between w-full fixed lg:static top-0 left-0 z-[5] h-screen lg:h-fit py-4 lg:py-0`}
            >
              <span
                onClick={() => setMenu(false)}
                className={`${
                  menu ? 'block  lg:hidden ' : 'hidden '
                }  absolute right-8 top-6`}
              >
                <Close className={` !relativefill-white`} />
              </span>

              <div className={`  flex-1 lg:flex pt-8 lg:pt-0`}>
                <ul className="flex flex-col lg:flex-row gap-4 lg:gap-0 items-center lg:items-start text-grayText">
                  {nav.map((item: string, index: number) => (
                    <li
                      key={index}
                      className={`${
                        lastSplitItem === switchMenu(item.toLocaleLowerCase())
                          ? 'text-white bg-activeLinkBlack font-medium'
                          : ''
                      } 
                      px-3 py-[.35rem] mx-1 rounded-md `}
                      onClick={() => setMenu(false)}
                    >
                      <Link to={item.toLowerCase()}>{item}</Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={`flex `}>
                <ul className="flex gap-4 lg:gap-0 items-center lg:items-start">
                  <li className="mx-2 hidden lg:block py-sm">
                    <Link to="">
                      {`${userData.firstName || ''} ${userData.lastName || ''}`}
                    </Link>
                  </li>
                  <li className="mx-2 bg-activeLinkBlack py-sm px-4 rounded-md font-normal">
                    <Link to="" onClick={() => setLogout()}>
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <span onClick={() => setMenu(true)}>
              <Bars
                className={`${
                  menu ? 'hidden  ' : 'block lg:hidden '
                } lg:hidden fill-white h-6 w-6`}
              />
            </span>
          </div>
        </div>
      </div>
      {lastSplitItem.includes('settings') ? (
        <div>
          <Outlet />
        </div>
      ) : (
        <div className="flex-1">
          <div className="container">
            <div className="my-10">
              <Outlet />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MainLayout;
