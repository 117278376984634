import React, { useState } from "react";
import {
  IAirtimeToCash,
  INetwork,
  INetworkProvider,
} from "../../../../../../interface/settings";
import ItemCard from "../../../../../../components/itemCard";
import { useUpdateSettingsData } from "../../../../../../services/settings";
import { Modal } from "../../../../../../components/modal";
import Button from "../../../../../../components/button";
import Switch from "../../../../../../components/switch";
import Input from "../../../../../../components/input";
import { capitalize } from "lodash";
import { useToast } from "../../../../../../context/toast";

export interface IProps {
  item?: IAirtimeToCash;
  refetch: () => void;
}

const Network = ({ item, refetch }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openProvider, setOpenProvider] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<INetwork>();
  const [provider, setProvider] = useState<INetworkProvider[]>();
  const { mutate, isLoading } = useUpdateSettingsData();

  const { addToast } = useToast();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: "Successfully Updated", type: "success" });
  };

  const submit = () => {
    mutate(
      {
        airtimeToCash: {
          ...item,
          network: item?.network.map((item) =>
            item.name === editItem?.name ? editItem : item
          ),
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpen(false);
        },
      }
    );
  };

  const handleToggleSwitch = (index: number) => {
    const newProviderStates = [...editItem?.provider!];
    newProviderStates[index].active = !newProviderStates[index].active;
    setProvider(newProviderStates);
  };

  const handleMarkupChange = (index: number, value: string) => {
    const newProviderStates = [...editItem?.provider!];
    newProviderStates[index].markup = parseInt(value);
    setProvider(newProviderStates);
  };

  const submitProvider = () => {
    mutate(
      {
        airtimeToCash: {
          ...item,
          network: item?.network.map((item) =>
            item.name === editItem?.name
              ? { ...editItem, provider: provider }
              : item
          ),
        },
      },
      {
        onSuccess: () => {
          handleMutationSuccess();
          setOpenProvider(false);
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      {item?.network.map((network: INetwork, index) => (
        <div
          className="rounded-md bg-dark"
          key={network.id}
          onClick={() => {
            setOpen(true);
            setEditItem(network);
          }}
        >
          <ItemCard title={network.name} />
          {item.network.length - 1 !== index && <hr className="border-grey" />}
        </div>
      ))}

      {open && editItem && (
        <Modal
          isVisible={open}
          close={setOpen}
          center
          title={editItem.name}
          width="medium"
          header
          showFooter
          footer={
            <div className="flex justify-end">
              <Button onClick={submit} loading={isLoading} disabled={isLoading}>
                Save
              </Button>
            </div>
          }
        >
          <div className="flex flex-col gap-4">
            <div className=" flex justify-between items-center ">
              <h5>Activate {editItem.name}</h5>
              <Switch
                checked={editItem.active}
                onCheck={() =>
                  setEditItem({ ...editItem, active: !editItem.active })
                }
              />
            </div>
            <div className="flex justify-between items-center ">
              <h5>ID</h5>
              <div className="w-[20%]">
                <Input
                  type="text"
                  onChange={(e) =>
                    setEditItem({ ...editItem, id: parseInt(e.target.value) })
                  }
                  defaultValue={editItem.id}
                />
              </div>
            </div>
            <div className="flex justify-between items-center ">
              <h5>Provider</h5>
              <h5
                onClick={() => {
                  setOpenProvider(true);
                  setOpen(false);
                  setProvider(editItem.provider);
                }}
                className="cursor-pointer"
              >
                Edit
              </h5>
            </div>
          </div>
        </Modal>
      )}
      {openProvider && provider?.length! > 0 && (
        <Modal
          isVisible={openProvider}
          close={setOpenProvider}
          center
          title="Provider"
          width="medium"
          header
          showFooter
          footer={
            <div className="flex justify-end">
              <Button
                onClick={submitProvider}
                loading={isLoading}
                disabled={isLoading}
              >
                Save
              </Button>
            </div>
          }
        >
          <div className="grid grid-cols-3 gap-2 mb-4">
            <h5>Name</h5>
            <h5>Active</h5>
            <h5>Markup</h5>
          </div>
          {provider?.map((item: INetworkProvider, index) => (
            <div key={index}>
              <div className="grid grid-cols-3 gap-2 items-center">
                <h5>{capitalize(item.name)}</h5>
                <Switch
                  checked={provider[index].active}
                  onCheck={() => handleToggleSwitch(index)}
                />
                <Input
                  defaultValue={item.markup}
                  onChange={(e) => handleMarkupChange(index, e.target.value)}
                />
              </div>
              {index !== provider.length - 1 && <hr className="border-gray" />}
            </div>
          ))}
        </Modal>
      )}
    </div>
  );
};

export default Network;
