import * as React from "react";
import { SVGProps } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.823 7 3.675-3.67a.586.586 0 1 0-.829-.827L7 6.178 3.331 2.503a.586.586 0 1 0-.828.828L6.178 7l-3.675 3.668a.583.583 0 0 0 .19.956.584.584 0 0 0 .638-.127l3.67-3.675 3.668 3.675a.582.582 0 0 0 .829 0 .582.582 0 0 0 0-.829L7.823 7Z"
      fill="#D9D9D9"
    />
  </svg>
);
export default SvgClose;
