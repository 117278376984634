import copy from "copy-to-clipboard";
import { format } from "date-fns";
import _ from "lodash";
import { ChangeEvent, useEffect, useMemo } from "react";
import Dropdown from "../../../../components/dropdown";
import Input from "../../../../components/input";
import Pagination from "../../../../components/pagination";
import { useToast } from "../../../../context/toast";
import { ITxn } from "../../../../interface/txn";
import { IUserTransactionsProps } from "../../../../interface/user";
import { capitalized } from "../../../../utils/capitalize";
import { PurposeEnum, StatusEnum } from "../../../../utils/constants";
import { isCurrency } from "../../../../utils/isCurrency";
import { replaceExtraCharacters } from "../../../../utils/replaceExtraCharacters";
import { serviceColors } from "../../../../utils/serviceColors";
import TransactionDetails from "../../transaction/details";
import { ArrowUp, ArrowDown } from "../../../../components/icons";

const Transactions = ({
  txn,
  isLoading,
  setFilter,
  filter,
  setTxnCurrentPage,
  txnCurrentPage,
  size,
  setTxnSize,
  user,
}: IUserTransactionsProps) => {
  const { addToast } = useToast();
  const statusOptions = Object.entries(StatusEnum).map(([label, value]) => ({
    label: capitalized(label),
    value,
  }));

  const purposeOptions = Object.entries(PurposeEnum).map(([label, value]) => ({
    label: capitalized(label).replace(/_/g, " "),
    value,
  }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setFilter({ ...filter, search: e.target.value });

  const debouncedResults = useMemo(() => {
    return _.debounce(handleChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const withdrawalCopy = (item: ITxn) => {
    const copiedText = `
    Mode of withdrawal: Bank transfer\nAccount No: ${
      item.view.destinationAccountNumber
    }\nAccount Name: ${item.view.destinationAccountName}\nAmount: ₦${isCurrency(
      item.view.amount
    )}\nBank: ${item.view.destinationBankName}\nDate: ${
      item.view.date
    }\nSession ID: ${item.responsePayload.data.TransactionReference}}`;
    copy(copiedText as string);
    addToast({ msg: "Copied!", type: "success" });
  };

  return (
    <>
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-sm xl:grid-cols-[1.5fr_1fr] gap-4 items-center pb-4">
          <div className="grid md:grid-cols-2  lg:grid-cols-4 gap-4">
            <Input
              type="date"
              className="text-xs"
              leading={<span className="opacity-50">Start</span>}
              onChange={(e) =>
                setFilter({ ...filter, startDate: e.target.value.trim() })
              }
            />

            <Input
              type="date"
              className="text-xs"
              leading={<span className="opacity-50">End</span>}
              onChange={(e) =>
                setFilter({ ...filter, endDate: e.target.value.trim() })
              }
            />
            <Dropdown
              label="Type"
              option={purposeOptions}
              onChange={(e) => setFilter({ ...filter, purpose: e?.value })}
            />
            <Dropdown
              label="Status"
              option={statusOptions}
              onChange={(e) => setFilter({ ...filter, status: e?.value })}
            />
          </div>
        </div>
        <div className="flex gap-1 mb-4 font-normal">
          <small className="text-lightGray2">Transactions</small>
          <small className="text-grayText">({txn?.pagination.total})</small>
        </div>
      </div>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto tableScrollBar">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-activeLinkBlack ">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Purpose
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Description
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Activity
                    </th>

                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Number
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Amount
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Disbursed Amount
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Settlement
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Initial Balance
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Final Balance
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Transaction ID
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Customer
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      More
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    txn?.response.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          txn?.response.length - 1 === index
                            ? ""
                            : "border-b border-grey"
                        }
                      >
                        <td className="text-sm p-4">
                          {item.createdAt &&
                            format(
                              new Date(item.createdAt),
                              "MMM do, yyyy H:mma"
                            )}
                        </td>
                        <td className="text-sm p-4 flex gap-2">
                          {capitalized(item.purpose)}
                          <span>
                            {item.purpose.includes(PurposeEnum.CHEAP_DATA) ||
                            item.purpose.includes(PurposeEnum.AIRTIME) ||
                            item.purpose.includes(PurposeEnum.DATA) ||
                            item.purpose.includes(PurposeEnum.POWER) ||
                            item.purpose.includes(PurposeEnum.TRANSFER) ||
                            item.purpose.includes(PurposeEnum.CABLE) ? (
                              <div
                                className={`${
                                  item.purpose.includes(PurposeEnum.TRANSFER)
                                    ? serviceColors(
                                        item.view.destinationBankName ||
                                          item.view.sourceBankName
                                      )
                                    : serviceColors(
                                        replaceExtraCharacters(
                                          item.view.service
                                        )
                                      )
                                } p-1 text-xs rounded-md flex`}
                              >
                                <div>
                                  {item.purpose.includes(PurposeEnum.TRANSFER)
                                    ? item.view.destinationBankName ||
                                      item.view.sourceBankName
                                    : replaceExtraCharacters(item.view.service)}
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </span>
                        </td>
                        <td className="text-sm p-4">
                          {capitalized(item.description)}
                        </td>
                        <td className="text-sm p-4 flex gap-2 items-center">
                          <div
                            className={`p-1 items-center justify-center flex rounded-md ${
                              item.activity === "DEPOSIT"
                                ? "bg-green-500"
                                : "bg-red-500"
                            }`}
                          >
                            {item.activity === "DEPOSIT" ? (
                              <ArrowUp />
                            ) : (
                              <ArrowDown />
                            )}
                          </div>
                          <span>{capitalized(item.activity)}</span>
                        </td>
                        <td className="text-sm p-4">
                          {item.view.meter ??
                            item.view.sourceAccountNumber ??
                            item.view.destinationAccountNumber ??
                            item.view.cardNumber ??
                            item.view.phone}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.amount ?? 0)}
                          {item.purpose === PurposeEnum.CARDS &&
                            ` / $${isCurrency(item.view.amount ?? 0)}`}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.disbursedAmount ?? 0)}
                          {item.purpose === PurposeEnum.CARDS &&
                            ` / $${isCurrency(item.view.amount ?? 0)}`}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.settlement ?? 0)}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.meta?.initialBalance ?? 0)}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.meta?.finalBalance ?? 0)}
                        </td>

                        <td className="text-sm p-4 flex items-center gap-4">
                          <p>{item.reference}</p>{" "}
                          {/* {item.purpose === PurposeEnum.TRANSFER &&
                          item.description === 'Bank transfer' ? (
                            !loadingBvn ? (
                              <span
                                className="cursor-pointer"
                                onClick={() => withdrawalCopy(item)}>
                                <Copy className="h-4 w-4" />
                              </span>
                            ) : (
                              '...'
                            )
                          ) : (
                            ''
                          )} */}
                        </td>
                        <td className="text-sm p-4">
                          {item.status === StatusEnum.FAILED && (
                            <span className="text-red-500">
                              {capitalized(StatusEnum.FAILED)}
                            </span>
                          )}
                          {item.status === StatusEnum.REFUNDED && (
                            <span className="text-red-500">
                              {capitalized(StatusEnum.REFUNDED)}
                            </span>
                          )}
                          {item.status === StatusEnum.SUCCESS && (
                            <span className="text-green-500">
                              {capitalized(StatusEnum.SUCCESS)}
                            </span>
                          )}
                          {item.status === StatusEnum.PENDING && (
                            <span className=" text-amber-500">
                              {capitalized(StatusEnum.PENDING)}
                            </span>
                          )}
                          {item.status === StatusEnum.PROCESSING && (
                            <span className=" text-amber-500">
                              {capitalized(StatusEnum.PROCESSING)}
                            </span>
                          )}
                          {item.status === StatusEnum.INITIATED && (
                            <span className=" text-gray-500">
                              {capitalized(StatusEnum.INITIATED)}
                            </span>
                          )}
                        </td>
                        <td className=" p-4 ">
                          <TransactionDetails item={item} text="More Details" />
                        </td>
                        <td className="text-sm p-4 ">
                          <div
                            className={`${
                              item.business
                                ? "bg-amber-500 text-black"
                                : "bg-green-500 text-white"
                            } rounded-full w-fit  px-2`}
                          >
                            {item.business ? "Business" : "Personal"}
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {txn?.pagination.total! > 0 && (
          <Pagination
            totalItems={txn?.pagination.total!}
            itemsPerPage={size}
            setPage={setTxnCurrentPage}
            page={txnCurrentPage}
            setSize={setTxnSize}
          />
        )}
      </div>
    </>
  );
};

export default Transactions;
