import React, { useContext, useState } from 'react';
import Button from '../button';
import { Modal } from '../modal';
import Input from '../input';
import { CardVariant, ICreateCard } from '../../interface/cards';
import Switch from '../switch';
import { useCreateCard } from '../../services/cards';
import ToastContext from '../../context/toast';
import { capitalized } from '../../utils/capitalize';
import Dropdown, { IDropdownList } from '../dropdown';

interface ICreateCardProps {
  userId: string;
}

const CreateCard = ({ userId }: ICreateCardProps) => {
  const [open, setOpen] = useState(false);
  const [payload, setPayload] = useState<ICreateCard>({} as ICreateCard);
  const [openSecretModal, setOpenSecretModal] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const { mutate, isLoading } = useCreateCard();
  const { addToast } = useContext(ToastContext);

  const variationOptions = Object.entries(CardVariant).map(
    ([label, value]) => ({
      label: capitalized(label).replaceAll('_', ' '),
      value,
    })
  );

  const submit = () => {
    if (!payload?.secret) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
      return;
    }

    mutate(
      {
        ...payload,
        userId,
        business: payload.business || false,
        chargeUser: payload.chargeUser || false,
      },
      {
        onSuccess: () => {
          setOpenSecretModal(false);
          addToast({
            msg: 'Successfully Updated',
            type: 'success',
          });
        },
      }
    );
  };

  const openAccessModal = () => {
    if (!payload.amount) {
      addToast({
        msg: 'Please enter an amount',
        type: 'error',
      });
      return;
    }
    if (!payload.variant) {
      addToast({
        msg: 'Please select a card variant',
        type: 'error',
      });
      return;
    }
    if (!payload.cardName) {
      addToast({
        msg: 'Please enter a card name',
        type: 'error',
      });
      return;
    } else {
      setOpen(false);
      setOpenSecretModal(true);
    }
  };

  return (
    <div className=" w-auto">
      <Button
        bgColor="bg-dimGray"
        onClick={() => {
          setOpen(true);
        }}
      >
        Create Card
      </Button>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        width="medium"
        header
        padding
        overflow
        title="Create Card"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={openAccessModal}>Create</Button>
          </div>
        }
      >
        <div className="flex flex-col gap-2">
          <div className="py-2">
            <small className="text-xs">Card Name</small>
            <Input
              defaultValue={payload?.cardName}
              onChange={(e) => {
                setPayload({ ...payload!, cardName: e?.target.value });
              }}
            />
          </div>
          <div className="py-2">
            <small className="text-xs">Amount</small>
            <Input
              defaultValue={payload?.amount}
              onChange={(e) => {
                setPayload({ ...payload!, amount: Number(e?.target.value) });
              }}
            />
          </div>
          <div className="py-2">
            <small className="text-xs">Variant</small>
            <Dropdown
              label={'Variant'}
              defaultValue={payload.variant}
              option={variationOptions}
              onChange={(e) => setPayload({ ...payload!, variant: e?.value })}
            />
          </div>
          <div className="py-2 flex justify-between items-center">
            <small className="text-xs">Charge User</small>
            <Switch
              checked={payload?.chargeUser!}
              onCheck={() =>
                setPayload({ ...payload!, chargeUser: !payload?.chargeUser })
              }
            />
          </div>
          <div className="py-2 flex justify-between items-center">
            <small className="text-xs">Business</small>
            <Switch
              checked={payload?.business!}
              onCheck={() =>
                setPayload({ ...payload!, business: !payload?.business })
              }
            />
          </div>
        </div>
      </Modal>
      <Modal
        padding={false}
        isVisible={openSecretModal}
        center
        close={setOpenSecretModal}
        title="Enter Access Key"
        header
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="p-4">
          <p className="pb-2">
            <small className="w-1/4 text-textDimGray">Access key</small>
          </p>
          <Input
            name="secret"
            type={showToken ? 'text' : 'password'}
            value={payload?.secret}
            onChange={(e) =>
              setPayload({ ...payload!, secret: e.target.value })
            }
            trailing={
              <p
                onClick={() => setShowToken(!showToken)}
                className="text-sm text-blue"
              >
                Show
              </p>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateCard;
