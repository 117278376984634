import { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery } from "react-query";
import { useToast } from "../context/toast";
import { IResponse } from "../interface/response";
import {
  AccountNumbersResponse,
  IBusinessSummaryMonthResponse,
  IBusinessSummaryParams,
  IBusinessSummaryRangeParams,
  IBusinessSummaryRangeResponse,
  IBusinessSummaryYearResponse,
  ICardCountResponse,
  ICardSettlementResponse,
  ICheapDataGBResponse,
  ICheapDataSummaryParams,
  ICheapDataSummaryResponse,
  IDashboard,
  ITopupPayload,
  ITopupResponse,
  IUserSummaryParams,
  IUserSummaryRangeParams,
  IVendorBalance,
} from "../interface/dashboard";
import Axios from "./axios";

// User summary requests
const getUsersSummaryYear = async ({ bvn, nin }: IUserSummaryParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryYearResponse>> =
    await Axios.get("/user/summary/year", {
      params: {
        bvn,
        nin,
      },
    });
  return response.data;
};

const getUsersSummaryMonth = async ({ bvn, nin }: IUserSummaryParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryMonthResponse>> =
    await Axios.get("/user/summary/month", {
      params: {
        bvn,
        nin,
      },
    });
  return response.data;
};

const getUserSummaryRange = async ({
  startDate,
  endDate,
  bvn,
  nin,
}: IUserSummaryRangeParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryRangeResponse>> =
    await Axios.get("/user/summary/range", {
      params: {
        startDate,
        endDate,
        bvn,
        nin,
      },
    });
  return response.data;
};

// Business summary requests
const getBusinessSummaryRange = async ({
  startDate,
  endDate,
  type,
  activity,
}: IBusinessSummaryRangeParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryRangeResponse>> =
    await Axios.get("/transaction/summary/range", {
      params: {
        activity,
        type,
        startDate,
        endDate,
      },
    });
  return response.data;
};

const getBusinessSummaryMonth = async ({
  type,
  activity,
}: IBusinessSummaryParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryMonthResponse>> =
    await Axios.get("/transaction/summary/month", {
      params: {
        activity,
        type,
      },
    });
  return response.data;
};

const getBusinessSummaryYear = async ({
  type,
  activity,
}: IBusinessSummaryParams) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryYearResponse>> =
    await Axios.get("/transaction/summary/year", {
      params: {
        activity,
        type,
      },
    });
  return response.data;
};

//Settlement Request
const getSettlementSummaryMonth = async () => {
  const response: AxiosResponse<IResponse<IBusinessSummaryMonthResponse>> =
    await Axios.get("/transaction/settlement/summary/month");
  return response.data;
};

const getVendorsBalance = async () => {
  const response: AxiosResponse<IResponse<IVendorBalance>> = await Axios.get(
    "/transaction/balance"
  );
  return response.data;
};

const getSettlementSummaryYear = async () => {
  const response: AxiosResponse<IResponse<IBusinessSummaryYearResponse>> =
    await Axios.get("/transaction/settlement/summary/year");
  return response.data;
};

const getSettlementSummaryRange = async ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const response: AxiosResponse<IResponse<IBusinessSummaryRangeResponse>> =
    await Axios.get("/transaction/settlement/summary/range", {
      params: {
        startDate,
        endDate,
      },
    });
  return response.data;
};

const getDashboardCount = async (params?: object) => {
  const response: AxiosResponse<IResponse<IDashboard>> = await Axios.get(
    "/transaction/dashboard",
    { params }
  );
  return response.data;
};

const getAccountNumbers = async () => {
  const response: AxiosResponse<IResponse<AccountNumbersResponse[]>> =
    await Axios.get("/transaction/account-number");
  return response.data;
};

const getCheapDataSummary = async ({
  startDate,
  endDate,
  period,
  provider,
}: ICheapDataSummaryParams) => {
  const response: AxiosResponse<IResponse<ICheapDataSummaryResponse>> =
    await Axios.get("/transaction/cheap-data/summary", {
      params: {
        startDate,
        endDate,
        period,
        provider,
      },
    });
  return response.data;
};

const getCheapDataGigabytesSummary = async ({
  startDate,
  endDate,
  period,
  provider,
}: ICheapDataSummaryParams) => {
  const response: AxiosResponse<IResponse<ICheapDataGBResponse>> =
    await Axios.get("/transaction/cheap-data/summary/gigabyte", {
      params: {
        startDate,
        endDate,
        period,
        provider,
      },
    });
  return response.data;
};

const topup = async ({ payload }: { payload: ITopupPayload }) => {
  const response: AxiosResponse<IResponse<ITopupResponse>> = await Axios.post(
    "/transaction/admin/transfer/top-up/",
    payload
  );
  return response;
};

const getCardCount = async ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const response: AxiosResponse<IResponse<ICardCountResponse>> =
    await Axios.get("/transaction/card/count/", {
      params: {
        startDate,
        endDate,
      },
    });
  return response.data;
};

const getCardSettlement = async ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const response: AxiosResponse<IResponse<ICardSettlementResponse>> =
    await Axios.get("/transaction/card/sum/", {
      params: {
        startDate,
        endDate,
      },
    });
  return response.data;
};

// `````````````````````````````````````````````````````

export const useDashboardCount = (params?: object) => {
  const { addToast } = useToast();
  return useQuery(["dashboard", params], () => getDashboardCount(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || "Error fetching Dashboard",
          type: "error",
        });
      } else {
        addToast({ msg: `${err}` || "An Error has occured", type: "error" });
      }
    },
  });
};

// Settlement Summary hooks
export const useSettlementSumaryYear = () => {
  const { addToast } = useToast();

  return useQuery(["SettlementSummaryYear"], () => getSettlementSummaryYear(), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || "Error fetching summary data",
          type: "error",
        });
      } else {
        addToast({ msg: `${err}` || "An Error has occured", type: "error" });
      }
    },
  });
};

export const useSettlementSumaryMonth = () => {
  const { addToast } = useToast();

  return useQuery(
    ["SettlementSummaryMonth"],
    () => getSettlementSummaryMonth(),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useGetVendorBalance = () => {
  const { addToast } = useToast();

  return useQuery(["vendor balance"], getVendorsBalance, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || "Error fetching vendors balance",
          type: "error",
        });
      } else {
        addToast({ msg: `${err}` || "An Error has occured", type: "error" });
      }
    },
  });
};

export const useSettlementSummaryRange = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  const { addToast } = useToast();

  return useQuery(
    ["SettlementSummaryRange", startDate, endDate],
    () => getSettlementSummaryRange({ startDate, endDate }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

// Business Summary hooks
export const useBusinessSummaryYear = ({
  type,
  activity,
}: IBusinessSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["BusinessSummaryYear", type, activity],
    () => getBusinessSummaryYear({ type, activity }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useBusinessSummaryMonth = ({
  type,
  activity,
}: IBusinessSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["BusinessSummaryMonth", type, activity],
    () => getBusinessSummaryMonth({ type, activity }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useBusinessSummaryRange = ({
  startDate,
  endDate,
  type,
  activity,
}: IBusinessSummaryRangeParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["BusinessSummaryRangeDeposit", startDate, endDate, activity, type],
    () => getBusinessSummaryRange({ startDate, endDate, activity, type }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

// User summary hooks
export const useUserSummaryYear = ({ bvn, nin }: IUserSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["UserSummaryYear", bvn, nin],
    () => getUsersSummaryYear({ bvn, nin }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useUserSummaryMonth = ({ bvn, nin }: IUserSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["UserSummaryMonth", bvn, nin],
    () => getUsersSummaryMonth({ bvn, nin }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useUserSummaryRange = ({
  startDate,
  endDate,
  nin,
  bvn,
}: IUserSummaryRangeParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["UsersSummaryRange", startDate, endDate, nin, bvn],
    () => getUserSummaryRange({ startDate, endDate, nin, bvn }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useGetAccountNumbers = () => {
  const { addToast } = useToast();

  return useQuery(["get account numbers"], getAccountNumbers, {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || "Error fetching account balance",
          type: "error",
        });
      } else {
        addToast({ msg: `${err}` || "An Error has occured", type: "error" });
      }
    },
  });
};

export const useCheapDataSummary = ({
  startDate,
  endDate,
  period,
  provider,
}: ICheapDataSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["CheapDataSummary", startDate, endDate, period, provider],
    () => getCheapDataSummary({ startDate, endDate, period, provider }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useCheapDataGigabytesSummary = ({
  startDate,
  endDate,
  period,
  provider,
}: ICheapDataSummaryParams) => {
  const { addToast } = useToast();

  return useQuery(
    ["CheapDataGigabytesSummary", startDate, endDate, period, provider],
    () => getCheapDataGigabytesSummary({ startDate, endDate, period, provider }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useTopup = () => {
  const { addToast } = useToast();

  return useMutation(topup, {
    onSuccess: async (response: AxiosResponse<IResponse<ITopupResponse>>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: "success" });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || "Topup not successful",
          type: "error",
        });
      } else {
        addToast({ msg: `${err}`, type: "error" });
      }
    },
  });
};

export const useCardCountSummary = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const { addToast } = useToast();

  return useQuery(
    ["CardCountSummary", startDate, endDate],
    () => getCardCount({ startDate, endDate }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};

export const useCardSettlementSummary = ({
  startDate,
  endDate,
}: {
  startDate?: string;
  endDate?: string;
}) => {
  const { addToast } = useToast();

  return useQuery(
    ["CardSettlementSummary", startDate, endDate],
    () => getCardSettlement({ startDate, endDate }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || "Error fetching summary data",
            type: "error",
          });
        } else {
          addToast({ msg: `${err}` || "An Error has occured", type: "error" });
        }
      },
    }
  );
};
