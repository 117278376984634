import * as React from "react";
import { SVGProps } from "react";
const SvgVisaWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 45 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.95 14.256h-3.607L18.598.252h3.607L19.95 14.256ZM13.307.252l-3.44 9.632-.408-2.076L8.247 1.5S8.098.252 6.534.252H.847L.78.488c1.332.338 2.604.878 3.774 1.603l3.137 12.165h3.759L17.189.252h-3.882ZM41.686 14.255H45L42.112.251h-2.903a1.644 1.644 0 0 0-1.001.247 1.67 1.67 0 0 0-.663.798l-5.383 12.959h3.763l.741-2.08h4.597l.423 2.08Zm-3.97-4.953 1.897-5.245 1.064 5.245h-2.962ZM32.444 3.619l.515-2.997A10.284 10.284 0 0 0 29.715 0c-1.79 0-6.042.79-6.042 4.638 0 3.619 4.99 3.664 4.99 5.563 0 1.9-4.475 1.559-5.954.375l-.538 3.128a9.995 9.995 0 0 0 4.078.794c2.462 0 6.177-1.288 6.177-4.795 0-3.641-5.035-3.982-5.035-5.563 0-1.581 3.51-1.383 5.053-.521Z"
      fill="#fff"
    />
    <path
      d="M9.459 7.812 8.247 1.5S8.098.253 6.534.253H.847L.78.488a13.966 13.966 0 0 1 5.357 2.716A10.92 10.92 0 0 1 9.46 7.812Z"
      fill="#fff"
    />
  </svg>
);
export default SvgVisaWhite;
