import { useContext, useEffect, useState } from 'react';
import Button from '../../../../../../../components/button';
import Input from '../../../../../../../components/input';
import ItemCard from '../../../../../../../components/itemCard';
import { Modal } from '../../../../../../../components/modal';
import Switch from '../../../../../../../components/switch';
import GlobalContext from '../../../../../../../context/globalContext';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../../services/settings';
import { useToast } from '../../../../../../../context/toast';

const Beneficiaries = () => {
  const [open, setOpen] = useState(false);
  const { data, refetch } = useGetSettings();
  const { mutate, isLoading } = useUpdateSettingsData();
  const figurData = data?.data.figur;
  const [limit, setLimit] = useState<string>('');
  const { outboundTransfer, setOutboundTransfer } = useContext(GlobalContext);
  const { addToast } = useToast();

  const submit = () => {
    mutate(
      {
        figur: {
          ...figurData,
          outboundTransfer: {
            ...figurData?.outboundTransfer,
            beneficiary: {
              ...outboundTransfer.beneficiary,
              limit: Number(limit),
            },
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
          addToast({ msg: 'Successfully Updated', type: 'success' });
          setOutboundTransfer({
            ...outboundTransfer,
            beneficiary: {
              ...outboundTransfer.beneficiary,
              limit: Number(limit),
            },
          });
        },
      }
    );
  };

  useEffect(() => {
    if (outboundTransfer) {
      setLimit(String(outboundTransfer.beneficiary.limit));
    }
  }, [outboundTransfer, setLimit]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Beneficiaries" />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Beneficiaries"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <div className="flex justify-between">
              <h5>Active</h5>
              <Switch
                checked={outboundTransfer.beneficiary.active}
                onCheck={() =>
                  setOutboundTransfer({
                    ...outboundTransfer,
                    beneficiary: {
                      ...outboundTransfer.beneficiary,
                      active: !outboundTransfer.beneficiary.active,
                    },
                  })
                }
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <h5>BVN name match only</h5>
              <Switch
                checked={outboundTransfer.beneficiary.bvnNameMatchOnly}
                onCheck={() =>
                  setOutboundTransfer({
                    ...outboundTransfer,
                    beneficiary: {
                      ...outboundTransfer.beneficiary,
                      bvnNameMatchOnly:
                        !outboundTransfer.beneficiary.bvnNameMatchOnly,
                    },
                  })
                }
              />
            </div>
          </div>
          <div>
            <div className="flex justify-between">
              <h5>Limit</h5>
            </div>
            <Input
              parentClassName="mt-2"
              defaultValue={limit}
              onChange={(e) => setLimit(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Beneficiaries;
