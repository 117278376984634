import { useEffect, useState } from 'react';
import Button from '../../../../../components/button';
import { Loading } from '../../../../../components/icons';
import { Modal } from '../../../../../components/modal';
import { ITxn } from '../../../../../interface/txn';
import { useReverseBills } from '../../../../../services/transaction';
import { isCurrency } from '../../../../../utils/isCurrency';

interface IProps {
  item: ITxn;
  setModal: (value: boolean) => void;
  modal: boolean;
}

const ReverseBills = ({ item, setModal, modal }: IProps) => {
  const [enableQuery, setEnableQuery] = useState<boolean>(false);

  const { isLoading, isSuccess } = useReverseBills({
    id: String(item?._id),
    enable: enableQuery,
    setEnable: setEnableQuery,
  });

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setModal(false);
    }
  }, [isSuccess, isLoading, setModal]);

  return (
    <>
      <Modal
        isVisible={modal}
        close={setModal}
        center
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={() => setEnableQuery(true)}>Yes, Reverse</Button>
          </div>
        }
      >
        <div className="text-center ">
          <p className="pb-4">
            Are your sure you want to reverse ₦
            {isCurrency(item?.disbursedAmount)} to {item.user?.firstName}?
          </p>
          {isLoading && <Loading />}
        </div>
      </Modal>
    </>
  );
};

export default ReverseBills;
