import { AxiosError, AxiosResponse } from 'axios';
import { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/auth';
import { useToast } from '../context/toast';
import { IAccessToken, IAuth, ILogin } from '../interface/auth';
import { IResponse, ITokenResponse } from '../interface/response';
import { IUser } from '../interface/user';
import Axios from './axios'; 

const login = async (payload: ILogin) => {
  const response: AxiosResponse<IResponse<IAuth>> = await Axios.post(
    '/auth/login',
    payload
  );
  return response;
};

const token = async (payload: IAccessToken) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    '/user/access',
    payload
  );
  return response;
};


export const useAuthentication = () => {
  const [userData, setUserData] = useState<IUser>({} as IUser);
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { setAuthAndCache } = useContext(AuthContext);

  return useMutation(login, {
    onSuccess: async (response: AxiosResponse<IResponse<IAuth>>) => {
      const { data } = response;
      setUserData(data.data.user);
      setAuthAndCache(`Bearer ${data.data.accessToken}`);
      addToast({ msg: data.message!, type: 'success' });
      navigate('/token');
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not login',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useAccessToken = () => { 
  const { addToast } = useToast();
  const navigate = useNavigate();  
  const { setSecretToken } = useContext(AuthContext);
  
  return useMutation(token, {
    onSuccess: async (response: AxiosResponse<IResponse<any>>) => {
      const { data } = response;
      setSecretToken("secret")

      addToast({ msg: data.message!, type: 'success' });
      navigate('/');
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Could not verify token',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};
