import { useState } from 'react';
import Button from '../../../../components/button';
import Dropdown from '../../../../components/dropdown';
import { File } from '../../../../components/icons';
import Textarea from '../../../../components/textarea';
import {
  IBusinessUpdateComponent,
  VerificationEnum,
} from '../../../../interface/business';
import { useBusinessUpdate } from '../../../../services/business';
import { capitalized } from '../../../../utils/capitalize';
import { Modal } from '../../../../components/modal';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import { getExtension } from '../../../../utils/getFileExtension';

const IDCard = ({ item, refetch }: IBusinessUpdateComponent) => {
  const [viewFile, setViewFile] = useState<boolean>(false);
  const [data, setData] = useState({
    reasons: item.verification.idCard.reasons ?? '----',
    validated: item.verification.idCard.validated ?? '----',
  });

  const verificationOptions = Object.entries(VerificationEnum).map(
    ([label, value]) => ({
      label: capitalized(label).replaceAll('_', ' '),
      value,
    })
  );

  const extension = getExtension(item.verification.idCard.file ?? '');

  const { mutate, isLoading } = useBusinessUpdate();

  const handleSubmit = () => {
    mutate(
      {
        id: item._id,
        body: {
          'verification.idCard.reasons': data.reasons,
          'verification.idCard.validated': data.validated,
        },
      },
      {
        onSuccess: refetch,
      }
    );
  };
  return (
    <div>
      <Modal
        isVisible={viewFile}
        close={setViewFile}
        width="large"
        padding
        header
        center
        title="ID card"
      >
        <div>
          {extension === 'pdf' ? (
            <DocViewer
              documents={[{ uri: item.verification.idCard.file }]}
              pluginRenderers={DocViewerRenderers}
            />
          ) : (
            <img src={item.verification.idCard.file} alt="utilty bill" />
          )}
        </div>
      </Modal>
      <div className="flex flex-col py-4 px-4">
        <div className="flex justify-between items-center py-2">
          <small>File</small>
          {item.verification?.idCard?.file ? (
            <div onClick={() => setViewFile(true)}>
              <File height={24} width={24} fill="#fff" />
            </div>
          ) : (
            <small className="text-red-500">N/A</small>
          )}
        </div>
        <div className="flex justify-between items-center py-2">
          <small>ID Number</small>
          <small>{item.verification?.idCard?.idNo ?? '----'}</small>
        </div>
        <div className="flex justify-between items-center py-2">
          <small>ID Type</small>
          <small>{item.verification?.idCard?.idType ?? '----'}</small>
        </div>

        <div className="flex justify-between items-center py-2">
          <small>Verification</small>
          <div className="flex items-center gap-2">
            {data.validated === VerificationEnum.ACCEPTED && (
              <div className="h-6 w-6 rounded-full bg-green-500"></div>
            )}
            <small>
              <Dropdown
                top={true}
                label="Verification"
                defaultValue={capitalized(data.validated).replaceAll('_', ' ')}
                option={verificationOptions}
                onChange={(e) => {
                  setData({ ...data, validated: e?.value });
                }}
              />
            </small>
          </div>
        </div>

        <div className="py-2">
          <small className="text-xs">Reasons</small>
          <Textarea
            defaultValue={data.reasons}
            onChange={(e) => {
              setData({ ...data, reasons: e?.target.value });
            }}
          />
        </div>
        <Button
          loading={isLoading}
          disabled={isLoading}
          onClick={handleSubmit}
          className="!py-3"
        >
          update
        </Button>
      </div>
    </div>
  );
};

export default IDCard;
