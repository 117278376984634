import copy from "copy-to-clipboard";
import { useToast } from "../../context/toast";
import { useGetAccountNumbers } from "../../services/dashboard";
import { Copy } from "../icons";
import { AccountNumbersResponse } from "../../interface/dashboard";
import Button from "../button";

interface VendorsAccountsProps {
  setSelectedAccount: (value: AccountNumbersResponse) => void;
  openTopup: () => void;
}

const VendorsAccounts = ({
  setSelectedAccount,
  openTopup,
}: VendorsAccountsProps) => {
  const { data } = useGetAccountNumbers();

  const { addToast } = useToast();

  const copyText = (accountNumber: string) => {
    copy(accountNumber);
    addToast({ msg: "Copied!", type: "success" });
  };

  return (
    <div className="pt-4">
      {data?.data.map((item, index) => (
        <div key={index} className={` px-4 pb-4 `}>
          <div className="border border-grey rounded-md bg-dimGray py-2 px-4 flex gap-6 items-center justify-between !text-xs">
            <div className="flex items-center gap-2">
              <div>
                <p>{item.name}</p>
                <div className="flex items-center gap-1">
                  <p>{item.bank}</p>{" "}
                  <div
                    onClick={() => copyText(item.account)}
                    className="flex items-center gap-2 bg-dimGray px-2 py-1 rounded-lg cursor-pointer"
                  >
                    <p className="font-medium text-xs bg-black p-1 rounded-md">
                      {item.account}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Button
              bgColor="bg-blue !py-2"
              onClick={() => {
                setSelectedAccount(item);
                openTopup();
              }}
            >
              Topup
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default VendorsAccounts;
