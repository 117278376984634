export interface IGetUserCardsResponse {
  account: string;
  balance: number;
  billingAddress: IBillingAddress;
  brand: string;
  card: string;
  variant: string;
  cardName: string;
  createdAt: string;
  currency: string;
  customer: string;
  expiryMonth: string;
  expiryYear: string;
  is2FAEnrolled: boolean;
  isDeleted: boolean;
  last4: string;
  maskedPan: string;
  name: string;
  status: StatusEnum;
  type: string;
  updatedAt: string;
  user: string;
  __v: number;
  _id: string;
}

export interface IBillingAddress {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
}

export enum StatusEnum {
  Canceled = 'canceled',
  Active = 'active',
  InActive = 'inactive',
}

export interface ICardBalanceResponse {
  availableBalance: number;
  currentBalance: number;
}

interface ICardAccount {
  accountName: string;
  accountNumber: string;
  accountType: string;
  availableBalance: number;
  bankCode: string;
  business: string;
  createdAt: string;
  currency: string;
  currentBalance: number;
  customer: string;
  isDefault: boolean;
  isDeleted: boolean;
  provider: string;
  providerReference: string;
  referenceCode: string;
  type: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface ICardTransactionResponse {
  account: ICardAccount;
  amount: number;
  business: string;
  createdAt: string;
  customer: string;
  exchangeCurrency: string;
  exchangeRate: number;
  isDeleted: boolean;
  narration: string;
  paymentReference: string;
  provider: string;
  providerChannel: string;
  runningBalance: number;
  transactionReference: string;
  type: string;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface ICreateCard {
  cardName: string;
  amount: number;
  secret: string;
  chargeUser: boolean;
  userId: string;
  business: boolean;
  variant: string;
}

export interface IFundCard {
  cardId: string;
  amount: number;
  secret: string;
  chargeFromUser: boolean;
  business: boolean;
  variant: string;
}

export interface IWithdrawCard {
  cardId: string;
  amount: number;
  secret: string;
  withdrawToUser: boolean;
  business: boolean;
  variant: string;
}

export interface IUpdateCardBalance {
  cardId: string;
  amount: number;
  secret: string;
}

export enum CardVariant {
  SUDO = 'sudo',
  MIDEN = 'miden',
}
