import React, { useState } from 'react';
import { useGetAllCampaigns } from '../../../../../services/mail-campaigns';
import { Campaign } from '../../../../../interface/mail-campaigns';
import { format } from 'date-fns';
import Pagination from '../../../../../components/pagination';
import useUrlPagination from '../../../../../utils/urlPagination';
import EditCampaigns from '../editCampaigns';
import ViewCampaign from '../viewCampaignBody';
import { formatRecipient } from '../../../../../utils/capitalize';

const AllCampaigns = () => {
  const [size, setSize] = useState(10);
  const { currentPage, setPage } = useUrlPagination();

  const { data, isLoading, refetch } = useGetAllCampaigns({
    currentPage,
    size,
  });

  return (
    <div>
      <h1 className="text-xs font-medium">All campaigns</h1>
      <div className="rounded-md font-normal bg-dimGray border border-grey mt-6">
        <div className="overflow-x-auto tableScrollBar">
          <div className="inline-block min-w-full">
            <div className="overflow-x-auto">
              <table className="table-auto min-w-full">
                <thead className="bg-lightGray">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Subject
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Recipients
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Total
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Total Sent
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Total Failed
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                    <th className="text-sm font-medium text-left p-4 opacity-50"></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data?.data.campaigns.map((item: Campaign, index) => (
                      <tr key={item._id} className={'border-b border-grey'}>
                        <td className="text-sm p-4">
                          {item.createdAt &&
                            format(
                              new Date(item.createdAt),
                              'MMM do, yyyy H:mma'
                            )}
                        </td>
                        <td className="text-sm p-4">{item.subject}</td>
                        <td className="text-sm p-4">
                          {formatRecipient(item.recipient)}
                        </td>
                        <td className="text-sm p-4">{item.total}</td>
                        <td className="text-sm p-4">{item.totalSent}</td>{' '}
                        <td className="text-sm p-4">{item.totalFailed}</td>
                        <td className="text-sm p-4">
                          {formatRecipient(item.status)}
                        </td>
                        <td className="text-sm p-4 text-primary cursor-pointer">
                          <EditCampaigns refetch={refetch} campaign={item} />
                        </td>
                        <td className="text-sm p-4 text-primary cursor-pointer">
                          <ViewCampaign campaign={item} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data?.data.pagination.total! > 0 && (
          <Pagination
            totalItems={data?.data.pagination.total!}
            itemsPerPage={size}
            setPage={setPage}
            page={currentPage}
            setSize={setSize}
          />
        )}
      </div>
    </div>
  );
};

export default AllCampaigns;
