import React, { useEffect, useRef, useState } from "react";
import { IChartDropDown } from "../../interface/dashboard";
import Button from "../button";
import { CaretDown } from "../icons";

const ChartDropDown = ({
  data,
  value,
  setValue,
  open,
  setOpen,
  range,
  setRange,
}: IChartDropDown) => {
  const [selectRange, setSelectRange] = useState(false);
  const wrapperRef = useRef<any>(null);
  const wrapperRef2 = useRef<any>(null);

  const setPeriod = (type: string) => {
    switch (type) {
      case "Range":
        setOpen(false);
        setSelectRange(true);
        break;

      default:
        setValue(type);
        setOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setOpen(false);
      }
      if (wrapperRef2.current && !wrapperRef2.current?.contains(event.target)) {
        setSelectRange(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef, wrapperRef2]);

  return (
    <div ref={wrapperRef} className="relative w-fit ">
      <span
        onClick={() => {
          setOpen(!open);
          setSelectRange(false);
        }}
        className="flex items-center gap-1 hover:bg-ldark/10 px-4 py-2 cursor-pointer"
      >
        <p className="font-medium">{value}</p>
        <CaretDown className="h-3 w-3 fill-ldark" />
      </span>

      {open && (
        <div className="flex flex-col items-center w-32 bg-dark border border-grey absolute top-[100%] right-0">
          <>
            {data.map((item, index) => {
              return (
                <p
                  key={index}
                  onClick={() => {
                    setPeriod(item.label);
                  }}
                  className="font-medium opacity-70 cursor-pointer w-full text-center text-xs py-2"
                >
                  {item.label}
                </p>
              );
            })}
          </>
        </div>
      )}
      <>
        {selectRange && (
          <div
            ref={wrapperRef2}
            className="w-44 bg-dark border border-grey absolute top-[100%] right-0 py-2 font-medium"
          >
            <div className="px-2 pb-3">
              <p className="opacity-70 text-xs">Start Date:</p>
              <input
                type="date"
                className=" bg-inherit"
                onChange={(e) =>
                  setRange({ ...range, startDate: e.target.value })
                }
              />
            </div>
            <div className="px-2 ">
              <p className="opacity-70 text-xs">End Date:</p>
              <input
                type="date"
                className=" bg-inherit"
                onChange={(e) =>
                  setRange({ ...range, endDate: e.target.value })
                }
              />
            </div>
            <div className="px-2 pt-3 flex flex-col">
              <Button
                onClick={() => {
                  setSelectRange(false);
                  setValue("Range ");
                  setTimeout(() => {
                    setValue("Range");
                  }, 1000);
                }}
              >
                Filter
              </Button>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default ChartDropDown;
