import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Loading } from '../../../../components/icons';
import { Modal } from '../../../../components/modal';
import { IDeactivateAndActivateBusiness } from '../../../../interface/business';
import { useBusinessApproval } from '../../../../services/business';

const DeactivateAndActivateBusiness = ({
  business,
  refetch,
}: IDeactivateAndActivateBusiness) => {
  const [open, setOpen] = useState(false);
  const [approved, setApproved] = useState(business.approved);
  const { mutate, isLoading } = useBusinessApproval();

  const handleApproval = () => {
    mutate(
      {
        id: business._id,
        body: {
          approved: !approved,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
        },
      }
    );
    setApproved(!approved);
  };

  return (
    <>
      <div className=" w-auto">
        <Button
          className="font-medium !py-2"
          onClick={() => setOpen(true)}
          bgColor={business.approved ? 'bg-red-500' : 'bg-blue'}
          disabled={isLoading}>
          {business.approved ? ' Disapprove' : 'Approve'}
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center px-4">
          <p className="pb-4">
            Are your sure you want to{' '}
            {business.approved ? ' disapprove' : 'approve'} this business?
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link to="" onClick={handleApproval} className="text-blue">
              Yes, {business.approved ? ' disapprove' : 'approve'}
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DeactivateAndActivateBusiness;
