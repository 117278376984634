import { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Loading } from '../../../../components/icons';
import { Modal } from '../../../../components/modal';
import { IUserProps } from '../../../../interface/user';
import { useUpdateUser } from '../../../../services/user';

const DeactivateUser = ({ user }: IUserProps) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading, isSuccess } = useUpdateUser();

  if (isSuccess) {
    setOpen(false);
  }

  return (
    <>
      <div className=" w-auto">
        <Button
          className="font-medium !py-2"
          onClick={() => setOpen(true)}
          bgColor="bg-red-500"
          disabled={!user || user.deactivate}
        >
          Deactivate User
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center px-4">
          <p className="pb-4">
            Are your sure you want to deactivate this user?
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link
              to=""
              onClick={() =>
                mutate({ _id: user?._id, deactivate: !user?.deactivate })
              }
              className="text-blue"
            >
              Yes, Deactivate
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DeactivateUser;
