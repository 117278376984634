import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const useUrlPagination = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);

  const setPage = useCallback(
    (page: any) => {
      setCurrentPage(page);
      searchParams.set("page", page);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      navigate(newUrl, { replace: true });
    },
    [navigate, searchParams, setCurrentPage, location.pathname]
  );

  useEffect(() => {
    if (searchParams.get("page") !== null) {
      const pageFromUrl = Number(searchParams.get("page"));
      setCurrentPage(pageFromUrl);
    } else {
      setPage(currentPage);
    }
  }, [location]);

  useEffect(() => {
    const handlePopstate = () => {
      const pageFromUrl = Number(searchParams.get("page"));
      setCurrentPage(pageFromUrl);
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [searchParams, setCurrentPage]);

  return { currentPage, setPage };
};

export default useUrlPagination;
