import { useState } from 'react';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { IBvnResponse, IUserProps } from '../../../../interface/user';
import { useGetUserNIN } from '../../../../services/user';

interface IUserNinDetails {
  ninDetails: IBvnResponse;
}

const ViewNin = ({ ninDetails }: IUserNinDetails) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className=" w-auto">
        <Button
          onClick={() => {
            setOpen(true);
          }}
          className="!px-3"
        >
          View
        </Button>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        padding={false}
        width="medium"
        header
        title="NIN"
      >
        <div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Customer</small>
              <small>{`${ninDetails?.firstName} ${ninDetails?.lastName}`}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Identity</small>
              <small>{ninDetails?.identity}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Gender</small>
              <small>{ninDetails?.gender}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Email</small>
              <small>{ninDetails?.email}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Date of birth</small>
              <small>{ninDetails?.dob}</small>
            </div>
          </div>
          <div className="flex justify-between py-4 px-4">
            <small>Phone</small>
            <small>{ninDetails?.phone}</small>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewNin;
