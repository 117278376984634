import { useContext, useEffect, useState } from 'react';
import { Back } from '../../../../../components/icons';
import ItemCard from '../../../../../components/itemCard';
import Switch from '../../../../../components/switch';
import GlobalContext from '../../../../../context/globalContext';
import { useToast } from '../../../../../context/toast';
import { ISettingsResponse } from '../../../../../interface/settings';
import { useUpdateSettingsData } from '../../../../../services/settings';
import CardProviders from './card-providers';
import CardTypes from './card-types';
import { capitalize } from 'lodash';

interface IProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const Cards = ({ settingsData, refetch }: IProps) => {
  const { setCardType, progressCount, setProgressCount, cardType } =
    useContext(GlobalContext);
  const cards = settingsData?.cards;
  const [active, setActive] = useState<boolean>(cards?.sudoSwitch!);
  const { mutate } = useUpdateSettingsData();
  const { addToast } = useToast();

  const updateSudoSwitch = () => {
    setActive(!active);
    mutate(
      {
        cards: {
          ...settingsData?.cards,
          sudoSwitch: !settingsData?.cards.sudoSwitch,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const handleForward = () => {
    setProgressCount(progressCount + 1);
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  useEffect(() => {
    setProgressCount(0);
  }, [setProgressCount]);

  return (
    <>
      {progressCount === 0 ? (
        <div className="md:w-96 w-full">
          <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>Sudo Switch</h5>
            <Switch checked={active} onCheck={updateSudoSwitch} />
          </div>
          <div className="rounded-md bg-dark text-sm">
            <div
              onClick={() => {
                setCardType('usd');
                handleForward();
              }}
            >
              <ItemCard title={cards?.currency.usd ? 'Dollar Card' : ''} />
            </div>
            <hr className="border-grey" />
            <div
              onClick={() => {
                setCardType('ngn');
                handleForward();
              }}
            >
              <ItemCard title={cards?.currency.ngn ? 'Naira Card' : ''} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="flex gap-2 items-center  mb-6">
            <div
              className="bg-dark rounded-md flex items-center justify-center w-7 h-7 cursor-pointer"
              onClick={() => handleBack()}
            >
              <Back />
            </div>
            <span>{`${capitalize(cardType)} Card`}</span>
          </div>

          {progressCount === 1 && <CardTypes />}
          {progressCount === 2 && <CardProviders />}
        </div>
      )}
    </>
  );
};

export default Cards;
