import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Back } from "../../../../components/icons";
import { ITxnFilter } from "../../../../interface/txn";
import { useBusiness } from "../../../../services/business";
import { useTransactions } from "../../../../services/transaction";
import { useUser } from "../../../../services/user";
import Business from "./business";
import Cards from "./cards";
import DeactivateUser from "./deactivateUser";
import Transactions from "./transactions";
import UserInfo from "./userInfo";
import useUrlPagination from "../../../../utils/urlPagination";
import MessageUser from "./messageUser";

const UserDetails = () => {
  const [filter, setFilter] = useState<ITxnFilter>();
  const [txnSize, setTxnSize] = useState(10);
  const [businessSize, setBusinessSize] = useState(10);
  const [activeTab, setActiveTab] = useState<string>("Customer Details");
  const { currentPage, setPage } = useUrlPagination();

  let { id } = useParams();
  const { data } = useUser(id);

  const user = data?.data;
  const tabs = ["Customer Details", "Transactions", "Business", "Cards"];

  const { data: txn, isLoading } = useTransactions({
    currentPage: activeTab === "Transactions" ? currentPage : 1,
    size: txnSize,
    user: id,
    ...filter,
  });

  const {
    data: business,
    isLoading: LoadBiz,
    refetch,
  } = useBusiness({
    currentPage: activeTab === "Business" ? currentPage : 1,
    size: businessSize,
    user: id,
  });

  useEffect(() => {
    if (filter) setPage(1);
  }, [filter]);

  return (
    <>
      {user && business && txn && (
        <div className="font-medium text-sm">
          <div className="flex flex-col lg:flex-row gap-4 my-12 item-start lg:items-center">
            <div className="flex-1">
              <div className="flex gap-4 items-center">
                <Link to={"/app/user"} className="p-2 bg-lightGray rounded-md">
                  <Back />
                </Link>
                <h2 className="font-medium">
                  Customers ({user?.firstName + " " + user?.lastName})
                </h2>
              </div>
            </div>
            <div className="flex flex-wrap gap-4">
              <MessageUser user={user} button={true} />
              <DeactivateUser user={data?.data} />
            </div>
          </div>

          <ul className="flex flex-wrap gap-4 lg:gap-0 items-center lg:items-start text-grayText mb-4">
            {tabs.map((item: string, index: number) => (
              <li
                key={index}
                className={`${
                  activeTab === item
                    ? "text-white bg-activeLinkBlack font-medium "
                    : ""
                } 
             px-3 py-[.35rem] mx-1 rounded-md font-normal  hover:cursor-pointer`}
                onClick={() => {
                  setActiveTab(item);
                  setPage(1);
                }}
              >
                {item}
              </li>
            ))}
          </ul>

          {activeTab === "Customer Details" ? (
            <UserInfo user={user!} txn={txn?.data!} />
          ) : activeTab === "Transactions" ? (
            <Transactions
              txn={txn?.data!}
              isLoading={isLoading}
              setTxnCurrentPage={setPage}
              setFilter={setFilter}
              size={txnSize}
              setTxnSize={setTxnSize}
              txnCurrentPage={currentPage}
              filter={filter!}
              user={user!}
            />
          ) : activeTab === "Business" ? (
            <Business
              business={business?.data!}
              isLoading={LoadBiz}
              setBusinessCurrentPage={setPage}
              size={businessSize}
              setBusinessSize={setBusinessSize}
              refetch={refetch}
              businessCurrentPage={currentPage}
            />
          ) : activeTab === "Cards" ? (
            <Cards user={user!} />
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default UserDetails;
