import React, { useContext, useState } from 'react';
import ItemCard from '../../../../components/itemCard';
import { IUser, IServices } from '../../../../interface/user';
import { useUpdateUser } from '../../../../services/user';
import ToastContext from '../../../../context/toast';
import { Modal } from '../../../../components/modal';
import Button from '../../../../components/button';
import Switch from '../../../../components/switch';
import { startCase } from 'lodash';

interface IProps {
  user: IUser;
  refetch: () => void;
}

const Services = ({ user, refetch }: IProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { mutate, isLoading } = useUpdateUser();
  const [userServices, setUserServices] = useState<IServices>(user.services);
  const { addToast } = useContext(ToastContext);

  const handleServiceToggle = (service: keyof IServices) => {
    setUserServices((prevServices) => ({
      ...prevServices,
      [service]: !prevServices[service],
    }));
  };

  const submit = () => {
    mutate(
      { _id: user._id, services: userServices },
      {
        onSuccess: () => {
          setOpen(false);
          refetch();
          addToast({
            msg: 'Successfully Updated',
            type: 'success',
          });
        },
      }
    );
  };

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Services" />
      </div>

      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title="Services"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col text-sm">
          {Object.entries(userServices).map(([service, value]) => (
            <div key={service}>
              <div className="flex justify-between p-4">
                <h5>{startCase(service)}</h5>
                <Switch
                  checked={value}
                  onCheck={() =>
                    handleServiceToggle(service as keyof IServices)
                  }
                />
              </div>
              <hr className="border-gray/70" />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Services;
