import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../../../../components/button';
import GlobalContext from '../../../../../../context/globalContext';
import { Modal } from '../../../../../../components/modal';
import { currencies } from '../../../../../../services/currencies';
import Input from '../../../../../../components/input';
import Dropdown from '../../../../../../components/dropdown';

const AddCounty = () => {
  const { giftCardInputs, setGiftCardInputs } = useContext(GlobalContext);
  const [open, setOpen] = useState(false);
  const [countryName, setCountryName] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  const getCurrencies = () => {
    return currencies
      ? currencies.map((currency) => {
          return {
            label: currency.code,
            value: currency.code,
          };
        })
      : [];
  };
  const [firstCurrency] = getCurrencies();

  const handleAddCountry = () => {
    const newCountry = {
      name: countryName,
      currency: currency,
      active: true,
    };
    setGiftCardInputs({
      ...giftCardInputs,
      availableCountry: [...giftCardInputs.availableCountry, newCountry],
    });
    setOpen(false);
  };

  useEffect(() => {
    setCurrency(firstCurrency.label);
  }, [firstCurrency]);

  return (
    <div>
      <Button
        className="bg-transparent w-full border-blue border-[1px] !py-3"
        onClick={() => setOpen(true)}
      >
        Add More Countries
      </Button>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Add Country"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={handleAddCountry}>Save Changes</Button>
          </div>
        }
      >
        <div className="grid grid-cols-[65%_30%] gap-4 mb-2">
          <Input
            placeholder="Enter Country Name"
            onChange={(e) => setCountryName(e.target.value)}
          />

          <Dropdown
            label={''}
            defaultValue={firstCurrency.label}
            option={getCurrencies()}
            onChange={(e) => setCurrency(e?.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddCounty;
