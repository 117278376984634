import { ReactNode } from "react";
import Button from "../button";
import Close from "../icons/Close";
import { Loading } from "../icons";

interface IProps {
  children: ReactNode;
  isVisible: boolean;
  close: (value: boolean) => void;
  padding?: boolean;
  title?: string;
  center?: boolean;
  overlay?: boolean;
  header?: boolean;
  footer?: JSX.Element;
  showFooter?: boolean;
  loading?: boolean;
}

export const FullscreenModal = ({
  children,
  isVisible,
  close,
  padding = true,
  title,
  center,
  overlay,
  header = false,
  footer,
  showFooter,
  loading,
}: IProps) => {
  if (!isVisible) {
    return null;
  } else {
    document.body.style.overflow = "hidden";
  }

  return (
    <div
      className={`fixed top-0 right-0 overflow-y-auto ${
        overlay ? "z-[9]" : "z-[8]"
      } h-full w-full bg-black/40 flex justify-center items-center   
      `}
    >
      <div
        className={`bg-lightGray pt-4 mx-4 relative max-w-[1440px]  rounded-md  w-full`}
      >
        {header ? (
          <div className="flex justify-between border-b border-gray">
            <div className="flex gap-2 px-4 pb-4">
              {title && <div>{title}</div>}
              {/* {loading && <Loading />} */}
            </div>
            <Button
              onClick={() => {
                document.body.style.overflow = "scroll";
                close(false);
              }}
              className="px-1 py-1 right-4 top-4 absolute bg-transparent"
            >
              <Close />
            </Button>
          </div>
        ) : (
          <>
            <Button
              onClick={() => {
                document.body.style.overflow = "scroll";
                close(false);
              }}
              className="px-1 py-1 right-4 top-4 absolute bg-transparent"
            >
              <Close />
            </Button>
            {title && (
              <div className="px-4 pb-4 border-b border-grey">{title}</div>
            )}
          </>
        )}
        <div className="max-h-[calc(100vh-150px)] overflow-y-auto modalScrollBar rounded-b-md">
          <div className={`${padding ? "p-4" : ""} `}>{children}</div>
        </div>
        {showFooter && (
          <div className="px-4 py-4 bg-dark rounded-b-md">{footer}</div>
        )}
      </div>
    </div>
  );
};
