import * as React from "react";
import { SVGProps } from "react";
const SvgCaretDown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.65 5.25.32.75h8.66l-4.33 4.5Z" fill="#D9D9D9" />
  </svg>
);
export default SvgCaretDown;
