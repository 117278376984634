import React, { useContext, useRef, useState } from 'react';
import Button from '../../../../../../components/button';
import { ChevronRight } from '../../../../../../components/icons';
import GlobalContext from '../../../../../../context/globalContext';
import { useToast } from '../../../../../../context/toast';
import { useCreateGiftCard } from '../../../../../../services/gift-card';
import { useGetGiftCardVoucher } from '../../../../../../services/settings';
import { capitalized } from '../../../../../../utils/capitalize';
import AddCounty from './add-country';
import CardType from './card-type';
import EditCardType from './edit-card-type';
import EditGiftCard from './edit-giftcard-name';

const PreviewGiftCard = () => {
  const { giftCardInputs, setGiftCardInputs, setProgressCount } =
    useContext(GlobalContext);
  const [activeIndex, setActiveIndex] = useState(-1);
  const uploadRef = useRef<HTMLInputElement>(null);
  const { mutate, isLoading } = useCreateGiftCard();
  const { data, refetch } = useGetGiftCardVoucher();
  const { addToast } = useToast();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setGiftCardInputs({ ...giftCardInputs, image: e.target.files[0] });
    }
  };

  const handleToggle = (index: number) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };

  const handleDelete = (index: number) => {
    setGiftCardInputs({
      ...giftCardInputs,
      availableCountry: giftCardInputs.availableCountry.filter(
        (item, itemIndex) => index !== itemIndex
      ),
    });
  };

  const handleDeleteCardValue = (countryIndex: number, cardIndex: number) => {
    setGiftCardInputs({
      ...giftCardInputs,
      availableCountry: giftCardInputs.availableCountry.map(
        (country, index) => {
          if (index === countryIndex) {
            const updatedCard = country.card?.filter(
              (item, cardIdx) => cardIdx !== cardIndex
            );
            return { ...country, card: updatedCard };
          }
          return country;
        }
      ),
    });
  };

  const save = () => {
    if (giftCardInputs.availableCountry.length === 0) {
      addToast({ msg: 'Please add at least 1 countries', type: 'error' });
    } else if (!giftCardInputs.brandName) {
      addToast({ msg: 'Please add a brand name', type: 'error' });
    } else if (!giftCardInputs.processingTime) {
      addToast({ msg: 'Please add processing time', type: 'error' });
    } else if (!giftCardInputs.image) {
      addToast({ msg: 'Please add an image', type: 'error' });
    } else {
      const formData = new FormData();
      formData.append('image', giftCardInputs.image);
      const { image, ...dataInputs } = giftCardInputs;
      formData.append('data', JSON.stringify(dataInputs));

      mutate(formData, {
        onSuccess: () => {
          setProgressCount(0);
          refetch();
        },
      });
    }
  };

  return (
    <div className="rounded-md md:w-96 w-full flex flex-col gap-4 text-sm">
      <div className="bg-dark border-grey border border-[1px] px-2 py-3 rounded-lg flex justify-between">
        <h5>{giftCardInputs.brandName}</h5>
        <EditGiftCard
          title="Brand Name"
          defaultValue={giftCardInputs.brandName}
        />
      </div>

      <div className="bg-dark border-grey border border-[1px] px-2 py-3 rounded-lg flex justify-between">
        <h5>{giftCardInputs.processingTime}</h5>
        <EditGiftCard
          title="Processing Time"
          defaultValue={giftCardInputs.processingTime}
        />
      </div>

      <div className="bg-dark border-grey border border-[1px] px-2 py-3 rounded-lg flex justify-between">
        <h5>{giftCardInputs.image.name}</h5>
        <div
          className="cursor-pointer"
          onClick={() => uploadRef.current?.click()}
        >
          <input
            type="file"
            accept="image/*"
            ref={uploadRef}
            hidden={true}
            name="file"
            onChange={onSelectFile}
          />
          <div className="text-blue">Change</div>
        </div>
      </div>

      <div>
        <h5 className="mb-2">
          Click to Add Values & Card Type to Available Counties
        </h5>

        {giftCardInputs.availableCountry.map((item, index) => (
          <div
            className="px-2 py-4 items-center rounded-md bg-dark border border-grey mb-4"
            key={index}
          >
            <div className="flex justify-between items-center">
              <div className="flex items-center gap-2">
                <div className="bg-grey py-1 px-2 text-xs rounded-md flex ">
                  {item.currency}
                </div>
                <h5>{item.name}</h5>
                <h6
                  className="text-red-500 cursor-pointer"
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </h6>
              </div>
              <ChevronRight
                onClick={() => handleToggle(index)}
                className={`${
                  index === activeIndex ? 'rotate-90' : ''
                } transition-transform duration-300 transform cursor-pointer `}
              />
            </div>
            {index === activeIndex &&
              item.card?.map((cardItem, cardIndex) => (
                <div
                  className="border border-grey rounded-md py-1 px-2 mt-2"
                  key={cardIndex}
                >
                  <div className="flex justify-between items-center">
                    <h5>{capitalized(cardItem.type)}</h5>
                    <div className="flex gap-2 items-center">
                      <EditCardType
                        countryIndex={index}
                        index={cardIndex}
                        item={cardItem}
                      />
                      <h5
                        className="text-red-500 cursor-pointer"
                        onClick={() => handleDeleteCardValue(index, cardIndex)}
                      >
                        Delete
                      </h5>
                    </div>
                  </div>
                  <div className="mt-2">
                    {cardItem.predefinedDenomination.map(
                      (denomination, denominationIndex) => (
                        <div
                          className="flex gap-2 mb-1"
                          key={denominationIndex}
                        >
                          <h6>Value: {denomination.value}</h6>
                          <div>|</div>
                          <h6>C-Rate: {denomination.cRate}</h6>
                          <div>|</div>
                          <h6>V-Rate: {denomination.vRate}</h6>
                        </div>
                      )
                    )}
                    {cardItem.customDenomination.map(
                      (denomination, denominationIndex) => (
                        <div className="flex gap-2" key={denominationIndex}>
                          <h6 className="flex gap-1">
                            Custom Value: {denomination.range.min}{' '}
                            <span>-</span>
                            {denomination.range.max}
                          </h6>
                          <div>|</div>
                          <h6>C-Rate: {denomination.cRate}</h6>
                          <div>|</div>
                          <h6>V-Rate: {denomination.vRate}</h6>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            {index === activeIndex && <CardType index={index} />}
          </div>
        ))}
        <AddCounty />
      </div>

      <div className="flex justify-end">
        <Button
          className="!py-3"
          onClick={save}
          disabled={isLoading}
          loading={isLoading}
        >
          Save all changes
        </Button>
      </div>
    </div>
  );
};

export default PreviewGiftCard;
