import React, { useEffect, useState } from 'react';
import { LineChart } from '..';
import {
  useBusinessSummaryMonth,
  useBusinessSummaryRange,
  useBusinessSummaryYear,
} from '../../../services/dashboard';
import ChartDropDown from '../../chartDropDown';

const Revenuechart = () => {
  let date = new Date();
  let rangeStartDate = `${date.getDate() - 5}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let rangeEndDate = new Date().toLocaleDateString().replaceAll('/', '-');

  const [period, setPeriod] = useState('This month');
  const [openChartDropDown, setOpenChartDropDown] = useState<boolean>(false);
  const [chartLabel1, setChartLabel1] = useState<{ [key: string]: number }>({});
  const [chartLabel2, setChartLabel2] = useState<{ [key: string]: number }>({});
  const [range, setRange] = useState<{ startDate: string; endDate: string }>({
    startDate: rangeStartDate,
    endDate: rangeEndDate,
  });

  const { data: yearDepositSummary } = useBusinessSummaryYear({
    activity: 'DEPOSIT',
  });
  const { data: yearWithDrawSummary } = useBusinessSummaryYear({
    activity: 'WITHDRAW',
  });
  const { data: monthDepositSummary } = useBusinessSummaryMonth({
    activity: 'DEPOSIT',
  });
  const { data: monthWithDrawSummary } = useBusinessSummaryMonth({
    activity: 'WITHDRAW',
  });
  const { data: rangeWithDrawSummary } = useBusinessSummaryRange({
    ...range,
    activity: 'WITHDRAW',
  });
  const { data: rangeDepositSummary } = useBusinessSummaryRange({
    ...range,
    activity: 'DEPOSIT',
  });

  useEffect(() => {
    if (
      monthDepositSummary &&
      monthWithDrawSummary &&
      period === 'This month'
    ) {
      setChartLabel1(monthWithDrawSummary!.data.data);
      setChartLabel2(monthDepositSummary!.data.data);
    }

    if (yearDepositSummary && period === 'This year') {
      setChartLabel1(yearWithDrawSummary!.data.data);
      setChartLabel2(yearDepositSummary!.data.data);
    }

    if (rangeWithDrawSummary && rangeDepositSummary && period === 'Range') {
      setChartLabel1(rangeWithDrawSummary!.data.data);
      setChartLabel2(rangeDepositSummary!.data.data);
    }
  }, [monthDepositSummary, monthWithDrawSummary, period]);

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Withdraw',
        data: chartLabel1,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Deposit',
        data: chartLabel2,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">Revenue</h3>
        <ChartDropDown
          open={openChartDropDown}
          setOpen={setOpenChartDropDown}
          value={period}
          setValue={setPeriod}
          data={periodArray}
          range={range!}
          setRange={setRange}
        />
      </div>
      <LineChart data={data} />
    </div>
  );
};

export default Revenuechart;

const periodArray = [
  {
    label: 'Range',
  },
  {
    label: 'This month',
  },
  {
    label: 'This year',
  },
];
