import { IList } from './response';
import { IUser } from './user';

export interface ITxnFilter {
  search?: string;
  status?: string;
  purpose?: string;
  endDate?: string;
  startDate?: string;
  approved?: boolean;
  suspend?: boolean;
}

export interface ITxn {
  _id: string;
  user: IUser;
  reference: string;
  amount: number;
  disbursedAmount: number;
  gatewayCharge: number;
  smsCharge: number;
  settlement: number;
  cashback: number;
  activity: string;
  type: string;
  business?: string;
  paymentGateway: string;
  description: string;
  provider: string;
  purpose: string;
  status: string;
  requestPayload: any;
  responsePayload: any;
  view: any;
  meta: any;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface IDeclinePayoneerPayload {
  payload: { status: string; secret: string };
  id: string;
}

export interface IApprovePayoneerPayload {
  payload: { status: string; secret: string };
  id: string;
}

interface IMetadata {
  ThirdPartyReference: string;
}

export interface IPostingHistory {
  EntryCode: string;
  ReferenceNumber: string;
  ReversalReferenceNumber: string;
  AccountNumber: string;
  LinkedAccountNumber: null;
  RealDate: string;
  Amount: number;
  OpeningBalance: number;
  BalanceAfter: number;
  Narration: string;
  InstrumentNumber: string;
  PostingRecordType: number;
  PostedBy: string;
  FinancialDate: string;
  FinancialDateToBackdate: null;
  IPAddress: null;
  Merchant: string;
  RecipientName: null;
  SenderName: null;
  RecipientBank: null;
  SenderBank: null;
  UserID: null;
  HasCOTWaiver: boolean;
  ForceDebit: boolean;
  PostingType: number;
  TransactionMethod: number;
  SessionId: string;
  Charge: number;
  BeneficiaryName: string;
  AllowChangeCategory: boolean;
  CategoryId: number;
  CategorySet: boolean;
  TagId: number;
  BeneficiaryReference: string;
  GoalTitle: null;
  PhoneNumberRecharged: null;
  BillId: null;
  Tier0Waiver: boolean;
  DetailOfClosure: null;
  ReasonForClosure: null;
  ClosedBy: null;
  MetaData: any;
}

export interface IData {
  PostingsHistory: IPostingHistory[];
  Message: null;
  StatusCode: string;
  TotalRecordInStore: number;
  TotalDebit: number;
  TotalCredit: number;
}

export interface IKudaTxn {
  Status: boolean;
  Message: string;
  Data: IData;
}

interface IAccount {
  accountBalance: number;
  accountName: string;
  accountNumber: string;
  accountProduct: string;
  accountType: string;
  _id: string;
}

export interface ISafeHavenTxn {
  account: IAccount;
  amount: number;
  cbaTransactionId: string;
  client: string;
  narration: string;
  paymentReference: string;
  provider: string;
  providerChannel: string;
  runningBalance: number;
  transactionDate: string;
  type: string;
  valueDate: string;
  _id: string;
}

export enum TypeEnum {
  Credit = 'Credit',
  Debit = 'Debit',
}
