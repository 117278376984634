import { useEffect, useState } from 'react';
import Button from '../../../../../components/button';
import { Loading } from '../../../../../components/icons';
import { Modal } from '../../../../../components/modal';
import { ITxn } from '../../../../../interface/txn';
import { useRequery } from '../../../../../services/transaction';

interface IProps {
  item: ITxn;
  setModal: (value: boolean) => void;
  modal: boolean;
}

const Requery = ({ item, setModal, modal }: IProps) => {
  const [enableQuery, setEnableQuery] = useState<boolean>(false);
  const [successMessage, setsuccessMessage] = useState<string>('');
  const [errorMessage, seterrorMessage] = useState<string>('');

  const { isLoading } = useRequery({
    reference: item.responsePayload?.data?.RequestReference,
    enable: enableQuery,
    setEnable: setEnableQuery,
    setsuccessMessage,
    seterrorMessage,
  });

  useEffect(() => {
    if (!modal) {
      seterrorMessage('');
      setsuccessMessage('');
    }
  }, [modal, seterrorMessage, setsuccessMessage]);

  return (
    <>
      <Modal
        isVisible={modal}
        close={setModal}
        center
        showFooter
        footer={
          <div className="flex justify-end">
            <Button
              className="text-white w-auto"
              onClick={() => setEnableQuery(true)}
            >
              Yes, Re-query
            </Button>
          </div>
        }
      >
        <div className="flex justify-center items-center flex-col">
          {isLoading ? (
            <Loading />
          ) : successMessage ? (
            <div className="text-white">{successMessage}</div>
          ) : errorMessage ? (
            <div className="text-red-500">{errorMessage}</div>
          ) : (
            <div className="flex flex-col gap-4">
              <p className="pb-4">
                Are your sure you want to Re-query this transaction?
              </p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Requery;
