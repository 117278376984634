import { useContext, useEffect, useState } from 'react';
import { Modal } from '../modal';
import { useIdleTimer } from 'react-idle-timer';
import Button from '../button';
import AuthContext from '../../context/auth';

export function Idle() {
  const { setLogout, auth } = useContext(AuthContext);

  const timeout = 1000 * 60 * 45;
  const promptTimeout = 1000 * 15;

  const [open, setOpen] = useState(false);

  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    setOpen(false);
    setRemaining(0);
    setLogout();
  };

  const onActive = () => {
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    crossTab: true,
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const reset = () => {
    setOpen(false);
    activate();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <Modal
      isVisible={open && auth !== null}
      center
      close={reset}
      width="medium"
      overlay
      showFooter
      footer={
        <div className="flex justify-between mx-4">
          <Button
            className="bg-transparent border-gray text-white"
            onClick={reset}
          >
            I'm Still Here
          </Button>
          <Button onClick={setLogout}>Logout</Button>
        </div>
      }
    >
      <div className="text-center w-full">
        <p>Logging you out in {remaining} seconds</p>
      </div>
    </Modal>
  );
}
