import React, { useContext } from 'react';
import Switch from '../../../../../../components/switch';
import GlobalContext from '../../../../../../context/globalContext';
import Beneficiaries from './beneficiaries';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../services/settings';
import { useToast } from '../../../../../../context/toast';

const OutboundTransfers = () => {
  const { data, refetch } = useGetSettings();
  const { mutate } = useUpdateSettingsData();
  const figurData = data?.data.figur;
  const { outboundTransfer, setOutboundTransfer } = useContext(GlobalContext);

  const { addToast } = useToast();

  const toggle = () => {
    setOutboundTransfer({
      ...outboundTransfer,
      bvnNameMatchOnly: !outboundTransfer.bvnNameMatchOnly,
    });
    mutate(
      {
        figur: {
          ...figurData,
          outboundTransfer: {
            ...figurData?.outboundTransfer,
            bvnNameMatchOnly: !figurData?.outboundTransfer.bvnNameMatchOnly,
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>BVN name match only</h5>
        <Switch checked={outboundTransfer.bvnNameMatchOnly} onCheck={toggle} />
      </div>
      <div className="rounded-md bg-dark">
        <Beneficiaries />
      </div>
    </div>
  );
};

export default OutboundTransfers;
