import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import Button from "../../../../components/button";
import Dropdown, { IDropdownList } from "../../../../components/dropdown";
import Input from "../../../../components/input";
import { Modal } from "../../../../components/modal";
import {
  IMessageUserPayload,
  IUser,
  IUserProps,
  TrapTypeEnum,
} from "../../../../interface/user";
import {
  useUser,
  useUpdateUser,
  useMessageUser,
} from "../../../../services/user";
import { Loading, Send } from "../../../../components/icons";
import { Link } from "react-router-dom";
import Textarea from "../../../../components/textarea";
import { useToast } from "../../../../context/toast";

interface MessageUserProps {
  user: IUser;
  button: boolean;
}

const MessageUser = ({ user, button }: MessageUserProps) => {
  const { addToast } = useToast();
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useMessageUser();
  const [payload, setPayload] = useState<IMessageUserPayload>();

  const sendMessage = () => {
    if (!payload?.title) {
      addToast({
        msg: "Title is required",
        type: "error",
      });
      return;
    }
    if (!payload?.message) {
      addToast({
        msg: "Message is required",
        type: "error",
      });
      return;
    }

    mutate(
      { message: payload?.message!, title: payload?.title!, user: user?._id! },
      {
        onSuccess: () => {
          setPayload(undefined);
          setOpen(false);
        },
      }
    );
  };

  return (
    <div>
      <div className=" w-auto">
        {button ? (
          <Button
            bgColor="bg-blue !py-2"
            onClick={() => {
              setOpen(true);
            }}
          >
            Message
          </Button>
        ) : (
          <div
            onClick={() => {
              setOpen(true);
            }}
            className="text-xs bg-black p-[0.4rem] items-center justify-center flex rounded-md cursor-pointer"
          >
            <Send className="h-[11px] w-[11px]" />
          </div>
        )}

        <Modal
          isVisible={open}
          close={setOpen}
          title="Message"
          header
          padding
          overflow
          width="medium"
          showFooter
          footer={
            <div className="flex justify-end">
              <Button loading={isLoading} onClick={sendMessage}>
                Message
              </Button>
            </div>
          }
          center
        >
          <div className="flex flex-col gap-2">
            <div className="py-2">
              <small className="text-xs">Title</small>
              <Input
                defaultValue={payload?.title}
                onChange={(e) => {
                  setPayload({ ...payload!, title: e?.target.value });
                }}
              />
            </div>

            <div className="py-2">
              <span className="flex items-center justify-between">
                <small className="text-xs">Message</small>

                <small className="text-xs">
                  {payload?.message ? payload?.message.length : 0}/160
                </small>
              </span>
              <Textarea
                defaultValue={payload?.message}
                maxLength={160}
                onChange={(e) => {
                  setPayload({ ...payload!, message: e?.target.value });
                }}
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MessageUser;
