import { Outlet } from "react-router-dom";
import { Logo } from "../../icons";

const AuthLayout = () => {
  return (
    <div className="h-screen flex flex-col">
      <div className="w-full bg-dark border-b border-grey"></div>
      <div className="flex-1">
        <div className=" flex h-full justify-center items-center">
          <div className="w-full max-w-md mx-4 my-16">
            <div className="flex items-center gap-1 justify-center mb-20">
              <Logo className=" h-7 w-24 mb-1 " />
              <h1 className="text-ash font-medium ">Console</h1>
            </div>

            <div className=" ">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
