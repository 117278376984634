import { IPagination } from './response';

export enum MailCampaignRecipientEnum {
  ALL_USERS_EXCLUDING_LOCKED = 'all_users_excluding_locked',
  ALL_USERS_INCLUDING_LOCKED = 'all_users_including_locked',
  SELECTED_USERS = 'selected_users',
}
export enum MailCampaignStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
}

export interface CreateEmailCampaignPayload {
  subject: string;
  body: string;
  recipient: string;
  recipientEmails?: string[];
}

export interface updateEmailCampaignPayload {
  campaignId: string;
  status: string;
}

export interface EmailCampaignPayload {
  subject: string;
  body: string;
  recipient: string;
  recipientEmails: string[];
  failedEmails: any[];
  total: number;
  totalSent: number;
  totalFailed: number;
  status: string;
  _id: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface CampaignData<T = null> {
  campaigns: T[];
  pagination: IPagination;
}

export interface Campaign {
  _id: string;
  subject: string;
  body: string;
  recipient: string;
  total: number;
  totalSent: number;
  totalFailed: number;
  status: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
