import React, { FC } from "react";
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "bottom" as const,
      display: true,
      align: "start" as const,
    },
    title: {
      display: false,
    },
  },
};

export interface IProps {
  data: IChartData;
}

export interface IChartData {
  labels: string[];
  datasets: IDataset[];
}

export interface IDataset {
  data: number[];
  backgroundColor: string[];
}

export const PieChart: FC<IProps> = ({ data }) => {
  return <Doughnut options={options} data={data} />;
};
