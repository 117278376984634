import React from 'react';
import Pagination from '../../../../components/pagination';
import { capitalized } from '../../../../utils/capitalize';
import { format } from 'date-fns';
import { isCurrency } from '../../../../utils/isCurrency';
import { IUserBusinessProps } from '../../../../interface/user';
import BusinessDetails from '../../business/details';

const Business = ({
  business,
  isLoading,
  setBusinessCurrentPage,
  businessCurrentPage,
  refetch,
  size,
  setBusinessSize,
}: IUserBusinessProps) => {
  return (
    <>
      <h2 className="font-medium text-grayText mb-6">
        Business ({business?.pagination.total})
      </h2>
      <div className="rounded-md font-normal bg-dimGray border border-grey">
        <div className="overflow-x-auto pb-4 border border-grey">
          <div className=" inline-block min-w-full">
            <div className="overflow-x-auto ">
              <table className="table-auto min-w-full">
                <thead className="bg-lightGray">
                  <tr className="border-b border-grey">
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Date
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Name
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Type
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Approved
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Wallet
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      Status
                    </th>
                    <th className="text-sm font-medium text-left p-4 opacity-50">
                      More
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    business?.response.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          business?.response.length - 1 === index
                            ? ''
                            : 'border-b border-grey'
                        }>
                        <td className="text-sm p-4">
                          {item.createdAt &&
                            format(
                              new Date(item.createdAt),
                              'MMM do, yyyy H:mma'
                            )}
                        </td>
                        <td className="text-sm p-4">
                          {capitalized(item.name)}
                        </td>
                        <td className="text-sm p-4">
                          {capitalized(item.type)}
                        </td>
                        <td className="text-sm p-4">
                          {item.approved ? (
                            <div className="text-green-500">Yes</div>
                          ) : (
                            <div className="text-red-500">No</div>
                          )}
                        </td>
                        <td className="text-sm p-4">
                          ₦{isCurrency(item.wallet ?? 0)}
                        </td>
                        <td className="text-sm p-4">
                          {item.suspend ? (
                            <div className="text-red-500">Inactive</div>
                          ) : (
                            <div className="text-green-500">Active</div>
                          )}
                        </td>
                        <td className=" p-4">
                          <BusinessDetails business={item} refetch={refetch} />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {business?.pagination.total! > 0 && (
          <Pagination
            totalItems={business?.pagination.total!}
            itemsPerPage={size}
            setPage={setBusinessCurrentPage}
            page={businessCurrentPage}
            setSize={setBusinessSize}
          />
        )}
      </div>
    </>
  );
};

export default Business;
