import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Loading } from '../../../../components/icons';
import { Modal } from '../../../../components/modal';
import { IUserProps } from '../../../../interface/user';
import { useUpdateUser, useUser } from '../../../../services/user';

const BanUser = ({ user }: IUserProps) => {
  const [open, setOpen] = useState(false);
  const { refetch } = useUser(user?._id!);
  const { mutate, isLoading, isSuccess } = useUpdateUser();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setOpen(false);
      refetch();
    }
  }, [isLoading, isSuccess, refetch]);

  return (
    <>
      <div className=" w-auto">
        <Button
          bgColor="bg-activeLinkBlack"
          onClick={() => setOpen(true)}
          disabled={!user}
        >
          {!user?.suspend ? 'Suspend user' : 'Restore user'}
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center ">
          <p className="pb-4">
            Are your sure you want to {user?.suspend ? 'restore' : 'suspend'}{' '}
            this user?
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link
              to=""
              onClick={() =>
                mutate({ _id: user?._id, suspend: !user?.suspend })
              }
              className="text-blue"
            >
              Yes, {user?.suspend ? 'restore' : 'suspend'}
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
};

export default BanUser;
