import { useContext, useEffect, useState } from 'react';
import Switch from '../../../../../../components/switch';
import GlobalContext from '../../../../../../context/globalContext';
import { useToast } from '../../../../../../context/toast';
import {
  ICurrencyProvider,
  ICurrencyType,
  ISingleCurrencyType,
} from '../../../../../../interface/settings';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../services/settings';
import { capitalized } from '../../../../../../utils/capitalize';
import CardCreationFee from './card-creation-fee';
import CardLimits from './cardLimits';
import Charges from './charges';
import CreationLimit from './creationLimit';
import FundingMarkup from './funding-markup';
import SwitchProvider from './switchProvider';
import WithdrawalMarkup from './withdrawal-markup';

const CardProviders = () => {
  const { providerDetails, cardType, updateProviderDetails } =
    useContext(GlobalContext);
  const { addToast } = useToast();
  const { mutate, isLoading } = useUpdateSettingsData();
  const { data, refetch } = useGetSettings();
  const [selectedProvider, setSelectedProvider] = useState<string>('Sudo');
  const [currentProvider, setCurrentProvider] = useState<ISingleCurrencyType>({
    providers: providerDetails.providers[0],
    withdrawal: providerDetails.withdrawal.sudo,
    funding: providerDetails.funding.sudo,
    creationLimit: providerDetails.creationLimit.sudo,
    active: providerDetails.active.sudo,
    type: providerDetails.type,
  });
  const settingsData = data?.data;

  const [usdDetails, setUsdDetails] = useState<ICurrencyType[]>(
    settingsData?.cards.currency.usd!
  );

  const [ngnDetails, setngnDetails] = useState<ICurrencyType[]>(
    settingsData?.cards.currency.ngn!
  );

  const updateProviderValue = (key: keyof ICurrencyProvider, value: any) => {
    const updatedCurrency = cardType === 'usd' ? usdDetails : ngnDetails;
    const updatedProviderDetails = updatedCurrency.map((item) => {
      if (item.type === currentProvider.type) {
        return {
          ...item,
          providers: item.providers.map((provider) =>
            provider.name === selectedProvider.toLowerCase()
              ? { ...provider, [key]: value }
              : provider
          ),
        };
      }
      return item;
    });

    if (updatedProviderDetails) {
      mutate(
        {
          cards: {
            ...settingsData?.cards,
            currency: {
              ngn: cardType === 'ngn' ? updatedProviderDetails : ngnDetails,
              usd: cardType === 'usd' ? updatedProviderDetails : usdDetails,
            },
          },
        },
        {
          onSuccess: () => {
            refetch();
            addToast({ msg: 'Successfully Updated', type: 'success' });
            updateProviderDetails({
              ...providerDetails,
              providers:
                updatedProviderDetails.find(
                  (item) => item.type === currentProvider.type
                )?.providers || providerDetails.providers,
            });
            setCurrentProvider((prev) => ({
              ...prev,
              providers:
                updatedProviderDetails
                  .find((item) => item.type === currentProvider.type)
                  ?.providers.find(
                    (provider) =>
                      provider.name === selectedProvider.toLowerCase()
                  ) || prev.providers,
            }));
          },
        }
      );
    }
  };

  const updateValue = (
    key: keyof ICurrencyType,
    value: any,
    subKey: 'sudo' | 'miden'
  ) => {
    const updatedCurrency = cardType === 'usd' ? usdDetails : ngnDetails;

    const updatedProviderDetails = updatedCurrency.map((currency) => {
      if (currency.type === currentProvider.type) {
        return Object.assign({}, currency, {
          [key]: Object.assign({}, currency[key], { [subKey]: value }),
        });
      }
      return currency;
    });

    mutate(
      {
        cards: {
          ...settingsData?.cards,
          currency: {
            ngn: cardType === 'ngn' ? updatedProviderDetails : ngnDetails,
            usd: cardType === 'usd' ? updatedProviderDetails : usdDetails,
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
          updateProviderDetails({
            ...providerDetails,
            [key]: Object.assign({}, providerDetails[key], { [subKey]: value }),
            providers:
              updatedProviderDetails.find(
                (item) => item.type === currentProvider.type
              )?.providers || providerDetails.providers,
          });
          setCurrentProvider((prev) => ({
            ...prev,
            [key]: Object.assign({}, prev[key], { [subKey]: value }),
          }));
        },
      }
    );
  };

  const activateUSDWithdrawal = () => {
    const updatedValue = !currentProvider.providers.usdWithdrawal;
    setCurrentProvider((prev) => ({
      ...prev,
      providers: {
        ...prev.providers,
        usdWithdrawal: updatedValue,
      },
    }));
    updateProviderValue('usdWithdrawal', updatedValue);
  };

  const activateWithdrawals = () => {
    const updatedValue = !currentProvider.withdrawal;
    setCurrentProvider((prev) => ({
      ...prev,
      withdrawal: updatedValue,
    }));
    updateValue(
      'withdrawal',
      updatedValue,
      selectedProvider.toLowerCase() as 'sudo' | 'miden'
    );
  };

  const activateFunding = () => {
    const updatedValue = !currentProvider.funding;
    setCurrentProvider((prev) => ({
      ...prev,
      funding: updatedValue,
    }));
    updateValue(
      'funding',
      updatedValue,
      selectedProvider.toLowerCase() as 'sudo' | 'miden'
    );
  };

  const activateCard = () => {
    const updatedValue = !currentProvider.active;
    setCurrentProvider((prev) => ({
      ...prev,
      active: updatedValue,
    }));
    updateValue(
      'active',
      updatedValue,
      selectedProvider.toLowerCase() as 'sudo' | 'miden'
    );
  };

  const activateUSDFunding = () => {
    const updatedValue = !currentProvider.providers.usdFunding;
    setCurrentProvider((prev) => ({
      ...prev,
      providers: {
        ...prev.providers,
        usdFunding: updatedValue,
      },
    }));
    updateProviderValue('usdFunding', updatedValue);
  };

  const activateCardProvider = () => {
    const updatedValue = !currentProvider.providers.active;
    setCurrentProvider((prev) => ({
      ...prev,
      providers: {
        ...prev.providers,
        active: updatedValue,
      },
    }));
    updateProviderValue('active', updatedValue);
  };

  useEffect(() => {
    if (selectedProvider === 'Sudo') {
      setCurrentProvider({
        providers: providerDetails.providers[0],
        withdrawal: providerDetails.withdrawal.sudo,
        funding: providerDetails.funding.sudo,
        creationLimit: providerDetails.creationLimit.sudo,
        active: providerDetails.active.sudo,
        type: providerDetails.type,
      });
    } else if (selectedProvider === 'Miden') {
      if (providerDetails.providers.length > 1) {
        setCurrentProvider({
          providers: providerDetails.providers[1],
          withdrawal: providerDetails.withdrawal.miden,
          funding: providerDetails.funding.miden,
          creationLimit: providerDetails.creationLimit.miden,
          active: providerDetails.active.miden,
          type: providerDetails.type,
        });
      } else {
        setCurrentProvider({
          providers: {} as ICurrencyProvider,
          withdrawal: providerDetails.withdrawal.miden,
          funding: providerDetails.funding.miden,
          creationLimit: providerDetails.creationLimit.miden,
          active: providerDetails.active.miden,
          type: providerDetails.type,
        });
      }
    }
  }, [selectedProvider, providerDetails]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Change Provider</h5>
        <SwitchProvider
          selectedProvider={selectedProvider}
          setSelectedProvider={setSelectedProvider}
        />
      </div>
      {(cardType === 'usd' ||
        (cardType === 'ngn' && currentProvider.providers.name === 'sudo')) && (
        <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
          <h5>Activate {capitalized(selectedProvider)} Card Creation</h5>
          <Switch checked={currentProvider.active} onCheck={activateCard} />
        </div>
      )}

      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Activate {capitalized(selectedProvider)} Provider</h5>
        <Switch
          checked={currentProvider.providers.active}
          onCheck={activateCardProvider}
        />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Withdrawals</h5>
        <Switch
          checked={currentProvider.withdrawal}
          onCheck={activateWithdrawals}
        />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Funding</h5>
        <Switch checked={currentProvider.funding} onCheck={activateFunding} />
      </div>
      {cardType === 'usd' && currentProvider && (
        <>
          <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>USD Funding</h5>
            <Switch
              checked={currentProvider.providers.usdFunding}
              onCheck={activateUSDFunding}
            />
          </div>
          <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>USD Withdrawal</h5>
            <Switch
              checked={currentProvider.providers.usdWithdrawal}
              onCheck={activateUSDWithdrawal}
            />
          </div>
        </>
      )}
      <div className="rounded-md bg-dark">
        {(cardType === 'usd' ||
          (cardType === 'ngn' &&
            currentProvider.providers.name === 'sudo')) && (
          <>
            <FundingMarkup
              providerData={currentProvider}
              isLoading={isLoading}
              updateValues={updateProviderValue}
              selectedProvider={selectedProvider}
            />
            <hr className="border-grey" />
            <WithdrawalMarkup
              providerData={currentProvider}
              isLoading={isLoading}
              updateValues={updateProviderValue}
              selectedProvider={selectedProvider}
            />
            <hr className="border-grey" />
            <CardCreationFee
              providerData={currentProvider}
              isLoading={isLoading}
              updateValues={updateProviderValue}
              selectedProvider={selectedProvider}
            />
            <hr className="border-grey" />
            <Charges
              providerData={currentProvider}
              isLoading={isLoading}
              updateValues={updateProviderValue}
            />
            <hr className="border-grey" />
            <CardLimits
              providerData={currentProvider}
              isLoading={isLoading}
              updateValues={updateProviderValue}
            />
            <hr className="border-grey" />
          </>
        )}
        <CreationLimit
          providerData={currentProvider}
          isLoading={isLoading}
          updateValues={updateValue}
          selectedProvider={selectedProvider}
        />
      </div>
    </div>
  );
};

export default CardProviders;
