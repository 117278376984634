import { useEffect, useState } from 'react';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import ItemCard from '../../../../../../components/itemCard';
import { Modal } from '../../../../../../components/modal';
import {
  ICurrencyProvider,
  ICurrencyType,
  ISingleCurrencyType,
} from '../../../../../../interface/settings';

interface ICreationLimit {
  providerData?: ISingleCurrencyType;
  updateValues: (
    key: keyof ICurrencyType,
    value: any,
    subKey: 'sudo' | 'miden'
  ) => void;
  isLoading: boolean;
  selectedProvider: string;
}

const CreationLimit = ({
  providerData,
  updateValues,
  isLoading,
  selectedProvider,
}: ICreationLimit) => {
  const [open, setOpen] = useState(false);
  const [creationLimit, setCreationLimit] = useState<string>('');

  const submit = () => {
    updateValues(
      'creationLimit',
      Number(creationLimit),
      selectedProvider.toLowerCase() as 'sudo' | 'miden'
    );
  };

  useEffect(() => {
    if (providerData) {
      setCreationLimit(String(providerData.creationLimit));
    }
  }, [providerData, setCreationLimit]);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Creation Limit" />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Creation Limits"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={isLoading} loading={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div>
          <h5>Creation Limit</h5>
          <Input
            parentClassName="mt-2"
            defaultValue={providerData?.creationLimit}
            onChange={(e) => setCreationLimit(e.target.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreationLimit;
