import { useContext, useEffect } from 'react';
import { Back } from '../../../../../components/icons';
import ItemCard from '../../../../../components/itemCard';
import GlobalContext from '../../../../../context/globalContext';
import { ISettingsResponse } from '../../../../../interface/settings';
import DeviceTypesDetails from './device-type-details';

interface IVersionControlProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const VersionControl = ({ settingsData, refetch }: IVersionControlProps) => {
  const versionControlData = settingsData?.figur;
  const {
    setDeviceDetails,
    progressCount,
    setProgressCount,
    setCardType,
  } = useContext(GlobalContext);

  const handleFoward = () => {
    setProgressCount(progressCount + 1);
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  useEffect(() => {
    setProgressCount(0);
  }, []);

  return (
    <div className="md:w-96 w-full text-sm">
      {progressCount === 0 ? (
        <div className="rounded-md bg-dark md:w-96 w-full text-sm">
          <div
            onClick={() => {
              setDeviceDetails(versionControlData?.android!);
              setCardType('android');
              handleFoward();
            }}
          >
            <ItemCard title={versionControlData?.android ? 'Android' : ''} />
          </div>
          <hr className="border-grey" />
          <div
            onClick={() => {
              setDeviceDetails(versionControlData?.ios!);
              setCardType('ios');
              handleFoward();
            }}
          >
            <ItemCard title={versionControlData?.ios ? 'IOS' : ''} />
          </div>
        </div>
      ) : (
        <div>
          <div
            className="bg-dark rounded-md flex items-center justify-center w-7 h-7 mb-6 cursor-pointer"
            onClick={() => handleBack()}
          >
            <Back />
          </div>
          {progressCount === 1 && <DeviceTypesDetails />}
        </div>
      )}
    </div>
  );
};

export default VersionControl;
