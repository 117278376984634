import { useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "../../../../../components/modal";
import { IPostingHistory } from "../../../../../interface/txn";
import { isCurrency } from "../../../../../utils/isCurrency";
import { format } from "date-fns";

interface IProps {
  item: IPostingHistory;
}

const TransactionDetails = ({ item }: IProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className=" w-auto">
        <Link
          to=""
          onClick={() => {
            setOpen(true);
          }}
          className="text-sm text-blue"
        >
          More Details
        </Link>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        title="Transaction details"
        header={true}
      >
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Date</small>
            <small>
              {item.RealDate &&
                format(new Date(item.RealDate), "MMM do, yyyy H:mma")}
            </small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Amount</small>
            <small>₦{isCurrency(item.Amount / 100)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Fees</small>
            <small>₦{isCurrency(item.Charge)}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>Narration</small>
            <small>{item.Narration}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>From</small>
            <small>{item.SenderBank}</small>
          </div>
        </div>
        <div className="border-b border-gray">
          <div className="flex justify-between py-4 px-4">
            <small>To</small>
            <small>{item.RecipientBank}</small>
          </div>
        </div>
        <div>
          <div className="flex justify-between py-4 px-4">
            <small>Transation Reference</small>
            <small>{item.ReferenceNumber}</small>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransactionDetails;
