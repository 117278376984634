import React, { useState } from 'react';
import { capitalized } from '../../../../../utils/capitalize';
import Dropdown from '../../../../../components/dropdown';
import { IUpdateAcct } from '../../../../../interface/settings';
import Input from '../../../../../components/input';
import Button from '../../../../../components/button';
import {
  useUpdateBusinessAccount,
  useUpdateUserAccount,
} from '../../../../../services/settings';
import { useToast } from '../../../../../context/toast';

const UpdateAccount = () => {
  const [inputs, setInputs] = useState<IUpdateAcct>({} as IUpdateAcct);
  const [type, setType] = useState<string>('');
  const { mutate, isLoading } = useUpdateBusinessAccount();
  const { mutate: updateUser, isLoading: updateUserLoading } =
    useUpdateUserAccount();
  const { addToast } = useToast();

  const submit = () => {
    if (!type) {
      addToast({ msg: 'Please select account type', type: 'error' });
      return;
    }
    if (!inputs.name) {
      addToast({ msg: 'Please enter a name', type: 'error' });
      return;
    }
    if (!inputs.name) {
      addToast({ msg: 'Please enter an account number', type: 'error' });
      return;
    }
    const mutation = type === 'user' ? updateUser : mutate;

    mutation(inputs, {
      onSuccess: () => {
        addToast({ msg: 'Successfully Updated', type: 'success' });
        setInputs({} as IUpdateAcct);
        setType('');
      },
    });
  };

  const getAccountType = () => {
    return accounts
      ? accounts.map((item) => {
          return {
            label: capitalized(item.type),
            value: item.type,
          };
        })
      : [];
  };

  return (
    <div className="md:w-[30rem] w-full text-sm">
      <div className="bg-dark rounded-t-md px-4 py-4">
        <h5 className="text-sm mb-6">UPDATE ACCOUNT</h5>
        <div className="flex flex-col gap-4">
          <Dropdown
            label={'Account Type'}
            option={getAccountType()}
            onChange={(e) => setType(e?.value)}
            defaultValue={type}
          />
          {type && (
            <div className="flex flex-col gap-4">
              <Input
                placeholder="Name"
                onChange={(e) => setInputs({ ...inputs, name: e.target.value })}
                defaultValue={inputs?.name}
              />
              <Input
                onChange={(e) =>
                  setInputs({ ...inputs, accountNumber: e.target.value })
                }
                placeholder="Account Number"
                defaultValue={inputs.accountNumber}
                maxLength={10}
              />
            </div>
          )}
        </div>
      </div>
      <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
        <Button
          onClick={submit}
          disabled={isLoading || updateUserLoading}
          loading={isLoading || updateUserLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default UpdateAccount;

const accounts = [{ type: 'user' }, { type: 'business' }];
