import React, { useEffect, useState } from 'react';
import {
  ISettingsResponse,
  ISignupBonus,
} from '../../../../interface/settings';
import Switch from '../../../../components/switch';
import { useToast } from '../../../../context/toast';
import { useUpdateSettingsData } from '../../../../services/settings';
import ItemCard from '../../../../components/itemCard';
import { format } from 'date-fns';
import Button from '../../../../components/button';
import Input from '../../../../components/input';
import { Modal } from '../../../../components/modal';

interface IProps {
  settingsData?: ISettingsResponse;
  refetch: () => void;
}

const SignupBonus = ({ settingsData, refetch }: IProps) => {
  const signupBonusData = settingsData?.figur.signUpBonus;
  const [payload, setPayload] = useState<ISignupBonus>();
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useUpdateSettingsData();
  const { addToast } = useToast();

  const toggleSignUpBonus = () => {
    setPayload({ ...payload!, active: !payload?.active });
    mutate(
      {
        figur: {
          ...signupBonusData,
          active: !signupBonusData?.active,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const submit = () => {
    mutate(
      {
        figur: {
          ...signupBonusData,
          payload,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  useEffect(() => {
    if (settingsData) {
      setPayload(signupBonusData);
    }
  }, [settingsData]);

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Activate Signup Bonus</h5>
        <Switch checked={payload?.active!} onCheck={toggleSignUpBonus} />
      </div>

      <div className="rounded-md bg-dark">
        <ItemCard
          title="Users Limit"
          hint={payload?.usersLimit}
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard title="Amount" hint={payload?.amount} showIcon={false} />
        <hr className="border-grey" />
        <ItemCard
          title="Start Date"
          hint={
            payload?.startDate &&
            format(new Date(payload.startDate), 'MMM do, yyyy H:mma')
          }
          showIcon={false}
        />
        <hr className="border-grey" />
        <ItemCard
          title="End Date"
          hint={
            payload?.endDate &&
            format(new Date(payload.endDate), 'MMM do, yyyy H:mma')
          }
          showIcon={false}
        />
        <div className="px-4 py-4 bg-[#202121] rounded-b-md flex justify-end">
          <Button onClick={() => setOpen(true)}>Edit Signup Bonus</Button>
        </div>
      </div>

      <Modal
        isVisible={open}
        close={setOpen}
        title={'Signup Bonus'}
        padding={false}
        width="medium"
        center
        header
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Save
            </Button>
          </div>
        }
      >
        <div>
          <div className="flex justify-between items-center py-4 px-4">
            <h5>Amount</h5>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setPayload({ ...payload!, amount: Number(e.target.value) })
                }
                defaultValue={payload?.amount}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Users Limit</h5>
            <div className="w-2/5">
              <Input
                type="text"
                onChange={(e) =>
                  setPayload({
                    ...payload!,
                    usersLimit: Number(e.target.value),
                  })
                }
                defaultValue={payload?.usersLimit}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>Start Date</h5>
            <div className="w-2/5">
              <Input
                type="datetime-local"
                onChange={(e) =>
                  setPayload({ ...payload!, startDate: e.target.value })
                }
                defaultValue={payload?.startDate}
                className="text-xs"
              />
            </div>
          </div>

          <div className="flex justify-between items-center py-4 px-4">
            <h5>End Date</h5>
            <div className="w-2/5">
              <Input
                type="datetime-local"
                onChange={(e) =>
                  setPayload({ ...payload!, endDate: e.target.value })
                }
                defaultValue={payload?.endDate}
                className="text-xs"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SignupBonus;
