import { FC, ReactNode, useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/auth";

interface IProps {
    children: ReactNode;
  }

  const AuthGuard: FC<IProps> = ({ children }) => {
    const { auth,secret } = useContext(AuthContext);
  
    if (auth && secret) {
      return <Navigate to="/app" replace />;
    }
  
    return <div>{children}</div>;
  };
  
  export default AuthGuard;