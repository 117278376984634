import ButtonDropDOwn from '../../../../../../components/buttonDropDown';

interface ISwitchProvider {
  selectedProvider: string;
  setSelectedProvider: (v: string) => void;
}

const SwitchProvider = ({
  selectedProvider,
  setSelectedProvider,
}: ISwitchProvider) => {
  const providers = ['Sudo', 'Miden'];

  return (
    <div>
      <ButtonDropDOwn
        label={selectedProvider}
        option={providers}
        onClick={(e) => setSelectedProvider(e!)}
        updateValue={true}
      />
    </div>
  );
};

export default SwitchProvider;
