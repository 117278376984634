import { isCurrency } from "../../utils/isCurrency";

interface SuspendedWalletsProps {
  suspendedBalance: string;
}

const SuspendedBalance = ({ suspendedBalance }: SuspendedWalletsProps) => {
  return (
    <div>
      <div
        className={`
              "border-b border-gray"
           `}
      >
        <div className="p-4 flex gap-6 items-center justify-between !text-xs">
          <div>
            <p>Total balance</p>
          </div>

          <div className="flex items-center gap-2">
            <p className="font-medium">
              {isCurrency(Number(suspendedBalance))}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuspendedBalance;
