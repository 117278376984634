import { useContext, useState } from 'react';
import ToastContext from '../../context/toast';
import { ITxn } from '../../interface/txn';
import { StatusEnum } from '../../utils/constants';
import Button from '../button';
import { Modal } from '../modal';
import Input from '../input';
import { useDeclinePayoneer } from '../../services/payoneer';
import { IPayoneerResponse } from '../../interface/payoneer';

interface IDeclineGiftCard {
  item: IPayoneerResponse;
}

const DeclinePayoneer = ({ item }: IDeclineGiftCard) => {
  const [open, setOpen] = useState(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [openAccessKeyModal, setOpenAccessKeyModal] = useState(false);
  const [accessKey, setAccessKey] = useState<string>('');
  const { mutate, isLoading } = useDeclinePayoneer();
  const { addToast } = useContext(ToastContext);

  const submit = () => {
    if (!accessKey) {
      addToast({
        msg: 'Please enter access Key',
        type: 'error',
      });
    } else {
      mutate(
        {
          id: item._id,
          payload: { secret: accessKey, status: StatusEnum.REJECTED },
        },
        {
          onSuccess: () => {
            setOpenAccessKeyModal(false);
            addToast({
              msg: 'Successfully Updated',
              type: 'success',
            });
          },
        }
      );
    }
  };

  return (
    <div>
      <div className=" w-auto">
        <div
          onClick={() => {
            item.status !== StatusEnum.REJECTED &&
              item.status !== StatusEnum.SUCCESS &&
              setOpen(true);
          }}
          className={`${
            item.status === StatusEnum.REJECTED ||
            item.status === StatusEnum.SUCCESS
              ? 'opacity-50'
              : ''
          } text-sm text-red-500`}
        >
          Decline
        </div>
      </div>
      <Modal
        padding={false}
        isVisible={open}
        center
        close={setOpen}
        width="medium"
      >
        <div className="border-b border-grey">
          <div className="px-4 py-4 flex flex-col gap-6 items-center">
            <h4>Are you sure you want to Decline this Transaction?</h4>
            <div className="flex gap-4">
              <Button
                onClick={() => {
                  setOpenAccessKeyModal(true);
                  setOpen(false);
                }}
              >
                Yes, Continue
              </Button>
              <Button
                onClick={() => setOpen(false)}
                bgColor="bg-activeLinkBlack"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        padding={false}
        isVisible={openAccessKeyModal}
        center
        close={setOpenAccessKeyModal}
        title="Enter Access Key"
        header
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button loading={isLoading} disabled={isLoading} onClick={submit}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="p-4">
          <p className="pb-2">
            <small className="w-1/4 text-textDimGray">Access key</small>
          </p>
          <Input
            name="secret"
            type={showToken ? 'text' : 'password'}
            value={accessKey}
            onChange={(e) => setAccessKey(e.target.value)}
            trailing={
              <p
                onClick={() => setShowToken(!showToken)}
                className="text-sm text-blue"
              >
                Show
              </p>
            }
          />
        </div>
      </Modal>
    </div>
  );
};

export default DeclinePayoneer;
