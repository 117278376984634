import React, { useContext, useEffect, useState } from 'react';
import ItemCard from '../../../../../components/itemCard';
import GlobalContext from '../../../../../context/globalContext';
import { Back } from '../../../../../components/icons';
import AyinlakProvider from './ayinlak';
import SMEProvider from './sme';

const Providers = () => {
  const { progressCount, setProgressCount } = useContext(GlobalContext);
  const [providerType, setProviderType] = useState<string>('');

  const handleFoward = () => {
    setProgressCount(progressCount + 1);
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  useEffect(() => {
    setProgressCount(0);
  }, []);

  return (
    <div className="md:w-96 w-full text-sm">
      {progressCount === 0 ? (
        <div className="rounded-md bg-dark md:w-96 w-full text-sm">
          <div
            onClick={() => {
              handleFoward();
              setProviderType('sme');
            }}
          >
            <ItemCard title="SME Plug" />
          </div>
          <hr className="border-grey" />
          <div
            onClick={() => {
              handleFoward();
              setProviderType('ayinlak');
            }}
          >
            <ItemCard title="Ayinlak" />
          </div>
        </div>
      ) : (
        <div>
          <div
            className="bg-dark rounded-md flex items-center justify-center w-7 h-7 mb-6 cursor-pointer"
            onClick={() => handleBack()}
          >
            <Back />
          </div>
          {progressCount === 1 && providerType === 'sme' && <SMEProvider />}
          {progressCount === 1 && providerType === 'ayinlak' && (
            <AyinlakProvider />
          )}
        </div>
      )}
    </div>
  );
};

export default Providers;
