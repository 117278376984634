import { useContext, useState } from 'react';
import Button from '../../components/button';
import Input from '../../components/input';
import { ILogin } from '../../interface/auth';
import { useAuthentication } from '../../services/auth';
import useForm from '../../utils/useForm'; 

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { mutate, isLoading } = useAuthentication();

  const submit = () => mutate(inputs);
  const { handleChange, handleSubmit, inputs } = useForm<ILogin>(
    {} as ILogin,
    submit
  );

  return (
    <form
      onSubmit={handleSubmit}
      className="bg-dimGray rounded-lg px-8 py-10 flex flex-col gap-4 font-medium"
    >
      <div className=" ">
        <p className="pb-2">
          <small className="w-1/4 text-textDimGray ">Email Address</small>
        </p>
        <Input
          name="email"
          type="email"
          value={inputs.email}
          onChange={handleChange}
        />
      </div>
      <div className="mb-1">
        <p className="pb-2">
          <small className="w-1/4 text-textDimGray">Password</small>
        </p>
        <Input
          name="password"
          type={showPassword ? 'text' : 'password'}
          value={inputs.password}
          onChange={handleChange}
          autoComplete="new-password"
          trailing={
            <p
              onClick={() => setShowPassword(!showPassword)}
              className="text-sm text-blue"
            >
              Show
            </p>
          }
        />
      </div>
      <Button type="submit" loading={isLoading} className="!py-3">
        Login
      </Button>
    </form>
  );
};

export default Login;
