import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../../../components/button';
import Dropdown from '../../../../../components/dropdown';
import { Back, Close } from '../../../../../components/icons';
import Input from '../../../../../components/input';
import GlobalContext from '../../../../../context/globalContext';
import { currencies } from '../../../../../services/currencies';
import { useToast } from '../../../../../context/toast';

interface ICurrency {
  label: string;
  value: string;
}

interface ICountry {
  name: string;
  currency: string;
  active: boolean;
}

const AddGiftCards = () => {
  const {
    progressCount,
    setProgressCount,
    giftCardInputs,
    setGiftCardInputs,
    setView,
  } = useContext(GlobalContext);
  const { addToast } = useToast();
  const [uploadFile, setUploadFile] = useState<File>();
  const uploadRef = useRef<HTMLInputElement>(null);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [countryName, setCountryName] = useState<string>('');
  const [currency, setCurrency] = useState<string>('');

  const handleAddCountry = () => {
    const newCountry = {
      name: '',
      currency: 'USD',
      active: true,
    };
    setCountries([...countries, newCountry]);
  };

  const handleCountryNameChange = (index: number, value: string) => {
    const updatedCountries = countries.map((country, itemIndex) =>
      index === itemIndex ? { ...country, name: value } : country
    );
    setCountries(updatedCountries);
  };

  const handleCurrencyChange = (index: number, value: string) => {
    const updatedCountries = countries.map((country, itemIndex) =>
      index === itemIndex ? { ...country, currency: value } : country
    );
    setCountries(updatedCountries);
  };

  const getCurrencies = () => {
    return currencies
      ? currencies.map((currency) => {
          return {
            label: currency.code,
            value: currency.code,
          };
        })
      : [];
  };
  const [firstCurrency] = getCurrencies();

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setUploadFile(e.target.files[0]);
    }
  };

  const next = () => {
    if (!giftCardInputs.brandName) {
      addToast({ msg: 'Please add a brand name', type: 'error' });
    } else if (!giftCardInputs.processingTime) {
      addToast({ msg: 'Please add processing time', type: 'error' });
    } else if (!uploadFile) {
      addToast({ msg: 'Please add an image', type: 'error' });
    } else if (!currency) {
      addToast({ msg: 'Please select a currency', type: 'error' });
    } else if (!countryName) {
      addToast({ msg: 'Please add a country', type: 'error' });
    } else {
      setProgressCount(progressCount + 1);
      setView('add');
      setGiftCardInputs({
        ...giftCardInputs,
        image: uploadFile!,
        availableCountry: [
          ...countries,
          { name: countryName, currency: currency, active: true },
        ],
      });
    }
  };

  useEffect(() => {
    setCurrency(firstCurrency.label);
  }, [firstCurrency]);

  return (
    <div className="rounded-md md:w-96 w-full flex flex-col gap-4 text-sm">
      <div className="flex flex-col gap-2">
        <h5>Brand Name</h5>
        <Input
          placeholder="Enter brand name e.g Amazon"
          onChange={(e) =>
            setGiftCardInputs({
              ...giftCardInputs,
              brandName: e.target.value,
            })
          }
        />
      </div>
      <div className="flex flex-col gap-2">
        <h5>Card Image</h5>
        <div
          className="w-full h-auto border-grey border-[1px] rounded-md px-6 py-3 bg-transparent cursor-pointer"
          onClick={() => uploadRef.current?.click()}
        >
          <input
            type="file"
            accept="image/*"
            ref={uploadRef}
            hidden={true}
            name="file"
            onChange={onSelectFile}
          />
          <div className="text-blue text-center">Upload card image</div>
        </div>
        {uploadFile && (
          <div className="bg-transparent border border-grey/60 w-40 h-28 rounded-md">
            <img
              src={URL.createObjectURL(uploadFile)}
              alt="giftcard"
              className="object-cover w-40 h-28 rounded-md"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-2">
        <h5>Processing Time</h5>
        <Input
          placeholder="E.g 5-10hrs"
          onChange={(e) =>
            setGiftCardInputs({
              ...giftCardInputs,
              processingTime: e.target.value,
            })
          }
        />
      </div>

      <div className="flex flex-col gap-2">
        <h5>Available Countries</h5>

        <div className="grid grid-cols-[65%_30%] gap-4 mb-2">
          <Input
            placeholder="Enter Country Name"
            onChange={(e) => setCountryName(e.target.value)}
          />

          <Dropdown
            label={''}
            defaultValue={currency}
            option={getCurrencies()}
            onChange={(e) => setCurrency(e?.value)}
          />
        </div>

        {countries.map((country, index) => (
          <div
            key={index}
            className="grid grid-cols-[65%_30%_5%] gap-4 mb-2 items-center"
          >
            <Input
              placeholder="Enter Country Name"
              onChange={(e) => handleCountryNameChange(index, e.target.value)}
            />

            <Dropdown
              label={''}
              defaultValue={currency}
              option={getCurrencies()}
              onChange={(e) => handleCurrencyChange(index, e?.value)}
            />

            <Close
              onClick={() =>
                setCountries(
                  countries.filter((item, itemIndex) => index !== itemIndex)
                )
              }
              className="cursor-pointer"
            />
          </div>
        ))}
      </div>
      <Button
        className="bg-transparent border-blue border-[1px] !py-3"
        onClick={handleAddCountry}
      >
        Add More Countries
      </Button>

      <div className="flex justify-end">
        <Button className="!py-3" onClick={next}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default AddGiftCards;
