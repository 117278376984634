import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import Button from '../../../../components/button';
import Dropdown, { IDropdownList } from '../../../../components/dropdown';
import Input from '../../../../components/input';
import { Modal } from '../../../../components/modal';
import { IUserProps, TrapTypeEnum } from '../../../../interface/user';
import { useUser, useUpdateUser } from '../../../../services/user';
import { Loading } from '../../../../components/icons';
import { Link } from 'react-router-dom';

const TrapUser = ({ user }: IUserProps) => {
  const [open, setOpen] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [trapValue, setTrapValue] = useState<string>('');
  const [amount, setAmount] = useState<string>('');
  const { refetch } = useUser(user?._id!);
  const { mutate, isLoading, isSuccess } = useUpdateUser();

  useEffect(() => {
    if (!isLoading && isSuccess) {
      setConfirmationModal(false);
      refetch();
    }
  }, [isLoading, isSuccess, refetch]);

  useEffect(() => {
    if (user) {
      setAmount(String(user.trap.amount));
      setTrapValue(user.trap.type);
    }
  }, [user]);

  return (
    <div>
      <div className=" w-auto">
        <Button
          bgColor="bg-activeLinkBlack"
          onClick={() => {
            user?.trap.status ? setConfirmationModal(true) : setOpen(true);
          }}>
          {`${user?.trap.status ? 'Untrap' : 'Trap'} User`}{' '}
        </Button>

        <Modal
          isVisible={open}
          close={setOpen}
          title="Trap User"
          header
          padding
          overflow
          width="medium"
          showFooter
          footer={
            <div className="flex justify-end">
              <Button
                onClick={() => {
                  setOpen(false);
                  setConfirmationModal(true);
                }}>
                Trap
              </Button>
            </div>
          }
          center>
          <div className="flex flex-col gap-2">
            <div>
              <h6 className="mb-2">Type</h6>
              <Dropdown
                label={''}
                top
                defaultValue={capitalize(
                  trapValue.toLowerCase().replace(/_/g, ' ')
                )}
                option={trapOptions}
                onChange={(e) => setTrapValue(e?.value)}
              />
            </div>

            {trapValue === TrapTypeEnum.SUSPEND && (
              <div>
                <h6 className="mb-2">Amount</h6>
                <Input
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  defaultValue={amount}
                />
              </div>
            )}
          </div>
        </Modal>
      </div>

      <div>
        <Modal
          isVisible={confirmationModal}
          close={setConfirmationModal}
          center
          width="medium">
          <div className="text-center ">
            <p className="pb-4">
              Are your sure you want to {user?.trap.status ? 'Untrap' : 'Trap'}{' '}
              this user?
            </p>
            {isLoading ? (
              <Loading />
            ) : (
              <Link
                to=""
                onClick={() =>
                  mutate({
                    _id: user?._id,
                    trap: {
                      amount:
                        trapValue === TrapTypeEnum.NO_WITHDRAW
                          ? 0
                          : parseInt(amount),
                      type: trapValue,
                      status: user?.trap.status ? false : true,
                    },
                  })
                }
                className="text-blue">
                Yes, {user?.trap.status ? 'Untrap' : 'Trap'}
              </Link>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TrapUser;

const trapOptions: IDropdownList[] = [
  { label: 'Suspend', value: TrapTypeEnum.SUSPEND },
  { label: 'No withdraw', value: TrapTypeEnum.NO_WITHDRAW },
];
