import { useContext, useEffect, useState } from 'react';
import ItemCard from '../../../../../../components/itemCard';
import GlobalContext from '../../../../../../context/globalContext';
import {
  ICurrencyType,
  ICustomRate,
} from '../../../../../../interface/settings';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../services/settings';
import { capitalized } from '../../../../../../utils/capitalize';
import { Modal } from '../../../../../../components/modal';
import Button from '../../../../../../components/button';
import Input from '../../../../../../components/input';
import { useToast } from '../../../../../../context/toast';

const CardTypes = () => {
  const { data, refetch } = useGetSettings();
  const { mutate, isLoading } = useUpdateSettingsData();
  const { updateProviderDetails, setProgressCount, progressCount, cardType } =
    useContext(GlobalContext);
  const [customRates, setCustomRates] = useState<ICustomRate>(
    {} as ICustomRate
  );
  const [openRateModal, setOpenRateModal] = useState<boolean>(false);
  const { addToast } = useToast();

  const updateProvidersWithCustomRates = (currency: ICurrencyType[]) => {
    return currency.map((currencyItem) => ({
      ...currencyItem,
      providers: currencyItem.providers.map((provider) =>
        provider.name === 'miden'
          ? {
              ...provider,
              withdraw: customRates.withdrawal,
              markup: customRates.funding,
            }
          : provider
      ),
    }));
  };

  const submit = () => {
    const updatedNgnDetails = updateProvidersWithCustomRates(
      data?.data.cards.currency.ngn!
    );
    const updatedUsdDetails = updateProvidersWithCustomRates(
      data?.data.cards.currency.usd!
    );

    mutate(
      {
        cards: {
          ...data?.data.cards,
          customRates: { miden: customRates },
          currency: {
            ngn: updatedNgnDetails,
            usd: updatedUsdDetails,
          },
        },
      },
      {
        onSuccess: () => {
          addToast({ msg: 'Successfully Updated', type: 'success' });
          refetch();
        },
      }
    );
  };

  useEffect(() => {
    if (data) {
      setCustomRates(data.data.cards.customRates.miden);
    }
  }, [data]);

  return (
    <div className=" md:w-96 w-full ">
      <div className="rounded-md bg-dark text-sm mb-4">
        <div onClick={() => setOpenRateModal(true)}>
          <ItemCard title={'Custom Rates'} />
        </div>
      </div>
      <div className="rounded-md bg-dark text-sm">
        {cardType === 'usd'
          ? data?.data.cards.currency.usd.map((item: ICurrencyType, index) => (
              <div
                key={index}
                onClick={() => {
                  setProgressCount(progressCount + 1);
                  updateProviderDetails(item);
                }}
              >
                <ItemCard title={capitalized(item.type)} />
                {index !== data?.data.cards.currency.usd.length - 1 && (
                  <hr className="border-grey" />
                )}
              </div>
            ))
          : data?.data.cards.currency.ngn.map((item: ICurrencyType, index) => (
              <div
                key={index}
                onClick={() => {
                  setProgressCount(progressCount + 1);
                  updateProviderDetails(item);
                }}
              >
                <ItemCard title={capitalized(item.type)} />
                {index !== data?.data.cards.currency.ngn.length - 1 && (
                  <hr className="border-grey" />
                )}{' '}
              </div>
            ))}
      </div>

      <Modal
        padding={true}
        isVisible={openRateModal}
        center
        close={setOpenRateModal}
        title="Custom Rates"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={isLoading} loading={isLoading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <h5>Base</h5>

            <Input
              parentClassName="mt-2"
              defaultValue={customRates.base}
              onChange={(e) => {
                setCustomRates({
                  ...customRates,
                  base: Number(e.target.value),
                });
              }}
            />
          </div>
          <div>
            <h5>Funding</h5>

            <Input
              parentClassName="mt-2"
              defaultValue={customRates.funding}
              onChange={(e) => {
                setCustomRates({
                  ...customRates,
                  funding: Number(e.target.value),
                });
              }}
            />
          </div>
          <div>
            <h5>Withdrawal</h5>

            <Input
              parentClassName="mt-2"
              defaultValue={customRates.withdrawal}
              onChange={(e) => {
                setCustomRates({
                  ...customRates,
                  withdrawal: Number(e.target.value),
                });
              }}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CardTypes;
