export const editorData = `<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Figur</title>

    <style>
      * {
        padding: 0;
        margin: 0;
      }
      /* Poppins font */
      @import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700");
      body {
        font-family: "Poppins", Arial, sans-serif;
      }
    </style>
  </head>

  <body
    style="
      background-color: #eff1f4;
      padding: 30px 0;
      margin: 0;
      font-family: 'Poppins', sans-serif;
    "
    data-new-gr-c-s-check-loaded="14.1126.0"
    data-gr-ext-installed=""
  >
    <div style="width: 600px; margin: 0 auto; padding: 30px 0">
      <div style="margin: 15px 0">
        <img
          src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/logo-dark.png"
          alt="figur-logo"
          width="120"
        />
      </div>
      <div>
        <img
          src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/header.png"
          alt="figur-header-image"
          width="100%"
          style="border-radius: 10px 10px 0 0; object-fit: cover"
        />
      </div>

      <div
        style="
          background-color: white;
          padding: 40px 40px;
          color: #032b69;
          margin-top: -5px;
          border-radius: 0 0 10px 10px;
          font-size: 16px;
          line-height: 24px;
        "
      >
        <div>
          <div style="font-family: 'Poppins', sans-serif">
            Dear esteemed customer,
            <br /><br />
            We are excited to share with you some fantastic updates that will
            undoubtedly enhance your Figur experience. Our team has been hard at
            work, and we're thrilled to introduce three new features that cater
            to your diverse financial needs.
            <br /><br />

            <div style="display: flex">
              <div style="margin-right: 16px">
                <div
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #2260ff;
                    color: white;
                    text-align: center;
                    line-height: 30px;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  1
                </div>
              </div>
              <div>
                <b>Safe Haven MFB Integration </b><br />
                We are delighted to announce that Safe Haven MFB has been
                seamlessly integrated into the Figur platform for every
                BVN-verified user. This means that you now have an additional
                funding source directly available in your Figur account. The
                best part? You can fund your Figur account with Safe Haven MFB
                at zero stamp duty charge! This integration aims to provide you
                with more flexibility and options when managing your finances.
                Don't worry if you already have an existing Providus account;
                you can still use it to fund your Figur account as before.
              </div>
            </div>
            <br /><br />

            <div style="display: flex">
              <div style="margin-right: 16px">
                <div
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #2260ff;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  2
                </div>
              </div>
              <div>
                <b> Airtime to Cash: Instantly Monetize Your Airtime </b><br />
                We understand the value of every resource, including airtime.
                That's why we are thrilled to introduce the Airtime to Cash
                feature on Figur! Now, you can convert your airtime to cash and
                receive instant payments directly into your Figur account. This
                innovative feature ensures that no resource goes to waste, and
                you have the freedom to use your airtime in a way that best
                suits your financial goals.
              </div>
            </div>
            <br /><br />

            <div style="display: flex">
              <div style="margin-right: 16px">
                <div
                  style="
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    background-color: #2260ff;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: bold;
                  "
                >
                  3
                </div>
              </div>
              <div>
                <b> Receive Money with Figur Dollar Card</b><br />
                In our ever-connected world, we recognize the importance of
                seamless global transactions. With our latest update, you can
                now receive money directly to your Figur dollar card from
                renowned global platforms like <b>Paypal</b> and <b>Meta</b>.
                This enhancement makes Figur an even more versatile financial
                tool, allowing you to manage international transactions
                effortlessly.
              </div>
            </div>
            <br /><br /><br />
            <span
              style="
                background-color: #2261ff2a;
                border: none;
                padding: 12px 32px;
                border-radius: 8px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
              "
              >🔥 HOT 🔥</span
            >
            <br />
            <br />
            <div style="margin-bottom: 4px">
              <b>Payoneer Exchange 🚀 </b>
            </div>
            Managing your account just got easier! You can now fund your account
            effortlessly using Payoneer, making transactions smoother than ever.
            You no longer have to wait 2 business days to get
            your payoneer funds to your local bank. All you have to do is enter
            the amount you want to transfer into your figur account and follow
            the instructions. Within the hour your account will be credit with
            the Naira equavilent. Also, we have the best rates! 😊

            <br /><br />
            We believe these new features will not only simplify your financial
            transactions but also empower you to make the most of your
            resources.<br />
            <br />
            At Figur, our commitment is to continuously enhance your experience
            and provide you with cutting-edge financial solutions. If you have
            any questions or need assistance with these new features, our
            customer support team is ready to help. Thank you for being a valued
            member of the Figur community. We look forward to seeing you thrive
            with these exciting additions to the Figur platform!
          </div>
        </div>

        <div><br /><br /></div>
        <div style="text-align: center">
          <a href="https://figur.africa/download" target="_blank">
            <button
              style="
                background-color: #2360ff;
                border: none;
                color: white;
                padding: 12px 32px;
                border-radius: 8px;
                text-align: center;
                text-decoration: none;
                font-size: 16px;
              "
            >
              Login
            </button>
          </a>
        </div>
      </div>

      <div
        style="
          background-color: white;
          border-radius: 10px;
          padding: 35px 0;
          margin-top: 20px;
          text-align: center;
          color: #032b69;
        "
      >
        <h2 style="font-size: 20px; margin-top: 20px">Get Figur app!</h2>
        <p style="font-size: 14px; margin-top: 20px">
          Transforming the way people pay, work, play and live.
        </p>
        <div style="margin-top: 20px">
          <button style="background-color: transparent; border: none">
            <a
              href="https://play.google.com/store/apps/details?id=africa.figur.app"
              target="_blank"
            >
              <img
                src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/google-play.png"
                alt="play-store-button"
                height="40px"
              />
            </a>
          </button>
          <button style="background-color: transparent; border: none">
            <a
              href="https://apps.apple.com/ng/app/figur/id6444032669"
              target="_blank"
            >
              <img
                src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/app-store.png"
                alt="app-store-button"
                height="40px"
              />
            </a>
          </button>
        </div>
      </div>
      <table
        style="
          width: 100%;
          max-width: 600px;
          margin: 15px 0;
          border-collapse: collapse;
          table-layout: fixed;
        "
      >
        <tbody>
          <tr>
            <td style="padding: 0; width: 33.3%">
              <img
                src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/logo-dark.png"
                alt="figur-logo"
                style="width: 80px"
              />
            </td>
            <td style="padding: 0; text-align: center; width: 33.3%">
              <div>
                <a
                  href="https://linkedin.com/company/figurafrica"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img
                    src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/linkedin.png"
                    alt="linkedIn"
                    style="width: 15px; height: 15px; margin-right: 20px"
                  />
                </a>
                <a
                  href="https://twitter.com/figurafrica"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img
                    src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/twitter.png"
                    alt="twitter"
                    style="width: 15px; height: 15px; margin-right: 20px"
                  />
                </a>
                <a
                  href="https://facebook.com/figurafrica"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img
                    src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/facebook.png"
                    alt="facebook"
                    style="width: 15px; height: 15px; margin-right: 20px"
                  />
                </a>
                <a
                  href="https://instagram.com/figurafrica"
                  style="text-decoration: none"
                  target="_blank"
                >
                  <img
                    src="https://figur.ams3.cdn.digitaloceanspaces.com/email_assets/instagram.png"
                    alt="instagram"
                    style="width: 15px; height: 15px"
                  />
                </a>
              </div>
            </td>
            <td colspan="2" style="padding: 0; text-align: end; width: 33.3%">
              <p style="font-size: 12px; color: #032b69; margin: 0">
                Copyright © 2023
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <p style="text-align: center; font-size: 12px">
        <a
          class="Unsubscribe--unsubscribeLink"
          href=""
          target="_blank"
          style="font-family: 'Poppins', sans-serif; text-decoration: none"
        >
          Unsubscribe
        </a>
      </p>
    </div>
  </body>
</html>`;
