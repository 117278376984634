import React, { FC, useContext, useEffect, useState } from "react";
import Switch from "../../../../../components/switch";
import { IAirtime } from "../pay-bills/airtime";
import { useToast } from "../../../../../context/toast";
import { useUpdateSettingsData } from "../../../../../services/settings";
import ItemCard from "../../../../../components/itemCard";
import LimitPerDay from "./limit-per-day";
import Max from "./max";
import Min from "./min";
import GlobalContext from "../../../../../context/globalContext";
import { Back } from "../../../../../components/icons";
import Network from "./network";

const AirtimeToCash: FC<IAirtime> = ({ settingsData, refetch }) => {
  const airtimeToCash = settingsData?.airtimeToCash;
  const { setProgressCount, progressCount } = useContext(GlobalContext);
  const { addToast } = useToast();
  const [active, setActive] = useState<boolean>(airtimeToCash?.active ?? false);
  const [openMax, setOpenMax] = useState(false);
  const [openMin, setOpenMin] = useState(false);
  const [openLimit, setOpenLimit] = useState(false);
  const [openNetwork, setOpenNetwork] = useState(false);

  const { mutate } = useUpdateSettingsData();

  const handleMutationSuccess = () => {
    refetch();
    addToast({ msg: "Successfully Updated", type: "success" });
  };

  const toggleChange = () => {
    setActive(!active);
    mutate(
      {
        airtimeToCash: {
          ...airtimeToCash,
          active: !settingsData?.airtimeToCash.active,
        },
      },
      {
        onSuccess: handleMutationSuccess,
      }
    );
  };

  const handleBack = () => {
    setProgressCount(progressCount - 1);
  };

  useEffect(() => {
    setProgressCount(0);
  }, []);

  return (
    <>
      {progressCount === 0 && (
        <div className="md:w-96 w-full text-sm">
          <div className="mb-6 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
            <h5>Activate Airtime to Cash</h5>
            <Switch checked={active} onCheck={toggleChange} />
          </div>

          <Max
            item={airtimeToCash}
            open={openMax}
            setOpen={setOpenMax}
            refetch={refetch}
          />
          <Min
            item={airtimeToCash}
            open={openMin}
            setOpen={setOpenMin}
            refetch={refetch}
          />
          <LimitPerDay
            item={airtimeToCash}
            open={openLimit}
            setOpen={setOpenLimit}
            refetch={refetch}
          />
          <div
            className="rounded-md bg-dark mb-6"
            onClick={() => setProgressCount(progressCount + 1)}
          >
            <ItemCard title="Network" />
          </div>
        </div>
      )}

      {progressCount === 1 && (
        <div>
          <div
            className="bg-dark rounded-md flex items-center justify-center w-7 h-7 mb-6 cursor-pointer"
            onClick={() => handleBack()}
          >
            <Back />
          </div>
          <Network item={airtimeToCash} refetch={refetch} />
        </div>
      )}
    </>
  );
};

export default AirtimeToCash;
