import * as React from "react";
import { SVGProps } from "react";
const SvgMasterCardWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle opacity={0.85} cx={11.172} cy={11.327} r={11.172} fill="#fff" />
    <circle opacity={0.55} cx={24.828} cy={11.327} r={11.172} fill="#fff" />
  </svg>
);
export default SvgMasterCardWhite;
