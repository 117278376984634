import React, { FC, TextareaHTMLAttributes } from 'react';

const Textarea: FC<TextareaHTMLAttributes<HTMLTextAreaElement>> = ({
  className,
  ...props
}) => {
  return (
    <div className="flex items-center bg-black rounded-md w-full relative gap-2 p-2">
      <textarea
        className={`bg-transparent outline-none w-full ${className}`}
        {...props}
      />
    </div>
  );
};

export default Textarea;
