import format from 'date-fns/format';
import { useState } from 'react';
import {
  CardVariant,
  ICardTransactionResponse,
  IGetUserCardsResponse,
  StatusEnum,
} from '../../interface/cards';
import {
  useGetUserCardsBalnce,
  useGetUserCardsTransactions,
  useReconcileCard,
} from '../../services/cards';
import { isCurrency } from '../../utils/isCurrency';
import Button from '../button';
import { ArrowDown, ArrowUp, MasterCardWhite, VisaWhite } from '../icons';
import { FullscreenModal } from '../modal/fullscreenModal';
import Pagination from '../pagination';
import FundCard from '../fundCard';
import WithdarwCard from '../withdrawCard';
import { Modal } from '../modal';
import CardReconciliationData from '../allModals/CardReconciliationData';
import EditCard from './editCard';

const UserCard = ({
  card,
  refetchCard,
}: {
  card: IGetUserCardsResponse;
  refetchCard: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [openReconcile, setOpenReconcile] = useState<boolean>(false);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { data, refetch } = useReconcileCard({
    cardId: card._id,
    setLoading,
    setOpenReconciliation: () => setOpenReconcile(true),
  });

  const { data: cardBalance } = useGetUserCardsBalnce({
    acct: card.variant === CardVariant.SUDO ? card.account : card.card,
    params: { variant: card.variant },
  });

  const { data: cardTransactions } = useGetUserCardsTransactions({
    account: card.variant === CardVariant.SUDO ? card.account : card.card,
    params: { variant: card.variant, page: currentPage - 1, limit },
  });

  const reconcileCard = () => {
    setLoading(true);
    refetch();
  };

  return (
    <>
      <Modal
        padding={false}
        isVisible={openReconcile}
        center
        close={setOpenReconcile}
        title={`Card name: ${card.name}`}
        width="medium"
        header
        overlay
      >
        <CardReconciliationData data={data?.data} />
      </Modal>
      <FullscreenModal
        title="Card Details"
        header
        isVisible={open}
        close={setOpen}
      >
        <div className="flex flex-col gap-4">
          <div className="flex flex-col md:flex-row gap-4 md:gap-0 justify-between">
            <div className="flex flex-col gap-4">
              <h3>{card.cardName}</h3>
              <div className="flex gap-2 items-center">
                <div className="text-[28px] font-medium">
                  ${isCurrency(cardBalance?.data.currentBalance!)}
                </div>
                <div className="bg-bColor py-[1px] px-2 gap-2 rounded-md text-[8px] flex">
                  Current
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <h6>${isCurrency(cardBalance?.data.availableBalance!)}</h6>
                <div className="bg-bColor py-[1px] px-2 gap-2 rounded-md text-[8px] flex">
                  Available
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-4 text-md">
              <div className="flex gap-2 items-center justify-end">
                <div className="bg-bColor py-[1px] px-2 gap-2 rounded-md">
                  Card ID
                </div>
                <h6>{card.card}</h6>
              </div>
              <div className="flex gap-2 items-center justify-end">
                <div className="bg-bColor py-[1px] px-2 gap-2 rounded-md">
                  Account ID
                </div>
                <h6>{card.account}</h6>
              </div>

              <div className="flex gap-4 items-center">
                <FundCard cardId={card._id} variant={card.variant} />
                <WithdarwCard cardId={card._id} variant={card.variant} />
                <Button
                  loading={loading}
                  disabled={loading}
                  bgColor="bg-blue"
                  onClick={reconcileCard}
                >
                  Reconcile
                </Button>
              </div>
            </div>
          </div>
          <div>
            <h5>Transactions ({cardTransactions?.data.pagination.total})</h5>
            <div className="overflow-x-auto tableScrollBar ">
              <div className="inline-block min-w-full ">
                <div className=" overflow-x-auto ">
                  <table className="min-w-full table-auto">
                    {cardTransactions?.data.response.map(
                      (item: ICardTransactionResponse) => (
                        <tr className="border-b border-gray" key={item._id}>
                          <td>
                            <div className="flex justify-between items-center my-3">
                              <div className="flex gap-4 items-center">
                                {item.type === 'Credit' ? (
                                  <div className="w-10 h-10 items-center justify-center flex text-[24px] bg-green-500 rounded-md">
                                    <ArrowUp />
                                  </div>
                                ) : (
                                  <div className="w-10 h-10 items-center justify-center flex text-[24px] bg-red-500 rounded-md">
                                    <ArrowDown />
                                  </div>
                                )}
                                <div className="flex flex-col gap-1">
                                  <div className="flex gap-2">
                                    <h6>{item.providerChannel}</h6>
                                    <div>|</div>
                                    <h6>{item.paymentReference}</h6>
                                    <div>|</div>
                                    <h6>{item.narration}</h6>
                                    <div>|</div>
                                    <h6>{item.transactionReference}</h6>
                                  </div>
                                  <div className="text-[12px]">
                                    {item.createdAt
                                      ? format(
                                          new Date(item.createdAt),
                                          'MMM do, yyyy H:mma'
                                        )
                                      : ''}
                                  </div>
                                </div>
                              </div>
                              <div>${isCurrency(item.amount)}</div>
                            </div>
                          </td>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              </div>
            </div>

            {cardTransactions?.data.pagination.total! > 0 && (
              <Pagination
                totalItems={cardTransactions?.data.pagination.total!}
                itemsPerPage={limit}
                setPage={setCurrentPage}
                page={currentPage}
                setSize={setLimit}
              />
            )}
          </div>
        </div>
      </FullscreenModal>
      <div
        className={`border border-grey rounded-md max-w-sm cursor-pointer w-full ${
          card.status === StatusEnum.Canceled ? 'opacity-40' : ''
        }`}
      >
        <div
          className="bg-dimGray p-4"
          onClick={() => {
            card.status !== StatusEnum.Canceled && setOpen(true);
          }}
        >
          <div className="flex justify-between items-center">
            <span>
              <small className="">Balance</small>
              <h3 className="font-medium -mt-1">
                $ {isCurrency(card.balance)}
              </h3>
            </span>
            <p className="w-30 truncate">{card.name}</p>
          </div>
          <div className="flex justify-between items-center mt-5">
            <span>
              <small className="">Card Name</small>
              <p className="font-medium -mt-1">{card.cardName}</p>
            </span>
            <span>
              {card.brand === 'MasterCard' ? (
                <MasterCardWhite className="h-8 w-10" />
              ) : (
                <VisaWhite className="h-8 w-10" />
              )}
            </span>
          </div>
        </div>
        <div className="flex justify-center gap-5 py-5">
          <Button className=" text-grayText" bgColor="bg-activeLinkBlack">
            {card.status === StatusEnum.InActive ? 'Unfreeze' : 'Freeze'}
          </Button>
          <EditCard cardId={card._id} refetch={refetchCard} />
        </div>
      </div>
    </>
  );
};

export default UserCard;
