export const capitalized = (word: string) => {
  return word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase() ?? null;
};

export const formatRecipient = (value: string): string => {
  return value
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};
