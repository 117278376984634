import { useContext, useState } from 'react';
import Button from '../../../../components/button';
import { Modal } from '../../../../components/modal';
import { IPendingKycResponse } from '../../../../interface/user';
import {
  useApproveKycSelfie,
  useDeclineKycSelfie,
} from '../../../../services/user';
import ToastContext from '../../../../context/toast';

interface IViewKYC {
  details: IPendingKycResponse;
  refetch: () => void;
}

const ViewKyc = ({ details, refetch }: IViewKYC) => {
  const [open, setOpen] = useState(false);
  const { mutate, isLoading } = useApproveKycSelfie();
  const { mutate: decline, isLoading: loadingdecline } = useDeclineKycSelfie();
  const { addToast } = useContext(ToastContext);

  const approveKycSelfie = () => {
    mutate(details._id, {
      onSuccess: () => {
        setOpen(false);
        addToast({
          msg: 'Successfully Approved',
          type: 'success',
        });
        refetch();
      },
    });
  };

  const declineKycSelfie = () => {
    decline(details._id, {
      onSuccess: () => {
        setOpen(false);
        addToast({
          msg: 'Successfully Declined',
          type: 'success',
        });
        refetch();
      },
    });
  };

  return (
    <>
      <div className=" w-auto">
        <div
          onClick={() => {
            setOpen(true);
          }}
          className="text-blue cursor-pointer"
        >
          View KYC{' '}
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        padding={false}
        width="medium"
        header
        title="KYC Details"
        showFooter
        footer={
          <div className="flex justify-end gap-2">
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={approveKycSelfie}
            >
              Approve
            </Button>
            <Button
              loading={loadingdecline}
              disabled={loadingdecline}
              onClick={declineKycSelfie}
              bgColor="bg-red-500"
            >
              Decline
            </Button>
          </div>
        }
      >
        <div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Name</small>
              <small>
                {details.user.firstName} {details.user.lastName}
              </small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Email</small>
              <small>{details.user.email}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Status</small>
              <small>{details.bvnSelfieStatus}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Identity</small>
              <small>{details.identity}</small>
            </div>
          </div>
          <div className="border-b border-gray">
            <div className="flex justify-between py-4 px-4">
              <small>Purpose</small>
              <small>{details.purpose}</small>
            </div>
          </div>
          <div className="grid gap-2 grid-cols-2 py-4 px-4">
            <div>
              <h5 className="mb-4">User Selfie</h5>
              <img src={details.userUploadedSelfie} alt="user-selfie" />
            </div>
            <div>
              <h5 className="mb-4">Provider Selfie</h5>
              <img src={details.selfieFromProviderBase64} alt="provider-selfie" />
            </div>{' '}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewKyc;
