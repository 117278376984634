import React, { FC } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        color: "rgba(68, 68, 68, 0.2)",
      },
    },
    y: {
      grid: {
        color: "rgba(68, 68, 68, 0.2)",
      },
    },
  },
  plugins: {
    legend: {
      position: "bottom" as const,
      display: true,
      align: "start" as const,
    },
    title: {
      display: false,
    },
  },
};

export type ChartDataPropNum = {
  [key: string]: number | undefined;
};

export interface IProps {
  data: IChartData;
}

export interface IChartData {
  labels: number[];
  datasets: IDataset[];
}

export interface IDataset {
  label: string;
  data: ChartDataPropNum | {};
  backgroundColor: string;
  borderColor?: string;
  borderWidth?: number;
}

export const BarChart2: FC<IProps> = ({ data }) => {
  return <Bar options={options} data={data} width={100} />;
};
