import { AxiosError, AxiosResponse } from 'axios';
import { IList, IResponse } from '../interface/response';
import Axios from './axios';
import { useToast } from '../context/toast';
import { useMutation, useQuery } from 'react-query';
import {
  ICardBalanceResponse,
  ICardTransactionResponse,
  ICreateCard,
  IFundCard,
  IGetUserCardsResponse,
  IUpdateCardBalance,
  IWithdrawCard,
} from '../interface/cards';
import { ReconciliationResponse } from '../interface/user';

const getCardRates = async (params: object) => {
  const response: AxiosResponse<IResponse<any>> = await Axios.get(
    '/card/rate',
    { params }
  );
  return response.data;
};

const getUserCards = async (id: string) => {
  const response: AxiosResponse<IResponse<IGetUserCardsResponse[]>> =
    await Axios.get(`/card/${id}`);

  return response.data;
};

const getUserCardsBalance = async ({
  acct,
  params,
}: {
  acct: string;
  params: object;
}) => {
  const response: AxiosResponse<IResponse<ICardBalanceResponse>> =
    await Axios.get(`/card/provider/balance/${acct}`, { params });

  return response.data;
};

const createCard = async (payload: ICreateCard) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/card/admin`,
    payload
  );
  return response;
};

const fundCard = async (payload: IFundCard) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/card/admin/fund`,
    payload
  );
  return response;
};

const withdrawCard = async (payload: IWithdrawCard) => {
  const response: AxiosResponse<IResponse> = await Axios.post(
    `/card/admin/withdraw`,
    payload
  );
  return response;
};

const editCardBalance = async (payload: IUpdateCardBalance) => {
  const response: AxiosResponse<IResponse> = await Axios.patch(
    `/card/admin/balance`,
    payload
  );
  return response;
};

const getUserCardsTransactions = async ({
  account,
  params,
}: {
  account: string;
  params: object;
}) => {
  const response: AxiosResponse<IResponse<IList<ICardTransactionResponse>>> =
    await Axios.get(`/card/${account}/transactions`, { params });

  return response.data;
};
const reconcile = async ({ cardId }: { cardId: string }) => {
  const response: AxiosResponse<IResponse<ReconciliationResponse>> =
    await Axios.get(`/card/admin/transactions/${cardId}/reconciliation`);

  return response.data;
};

export const useGetCards = (params: object) => {
  const { addToast } = useToast();

  return useQuery(['business', params], () => getCardRates(params), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching cards',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useGetUserCards = (id: string) => {
  const { addToast } = useToast();

  return useQuery(['user cards', id], () => getUserCards(id), {
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error fetching cards',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useGetUserCardsBalnce = ({
  acct,
  params,
}: {
  acct: string;
  params: object;
}) => {
  const { addToast } = useToast();

  return useQuery(
    ['user cards balance', acct, params],
    () => getUserCardsBalance({ acct, params }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg: err.response.data.message || 'Error fetching card balance',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
      keepPreviousData: true,
    }
  );
};

export const useGetUserCardsTransactions = ({
  account,
  params,
}: {
  account: string;
  params: object;
}) => {
  const { addToast } = useToast();

  return useQuery(
    ['user cards transaction', account, params],
    () => getUserCardsTransactions({ account, params }),
    {
      onError: (error) => {
        const err = error as AxiosError<IResponse>;
        if (err.response) {
          addToast({
            msg:
              err.response.data.message || 'Error fetching cards transactions',
            type: 'error',
          });
        } else {
          addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
        }
      },
      keepPreviousData: true,
    }
  );
};

export const useCreateCard = () => {
  const { addToast } = useToast();

  return useMutation(createCard, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Card creation failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useFundCard = () => {
  const { addToast } = useToast();

  return useMutation(fundCard, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Card funding failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useWithdraCard = () => {
  const { addToast } = useToast();

  return useMutation(withdrawCard, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Card withdrawal failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};

export const useReconcileCard = ({
  cardId,
  setLoading,
  setOpenReconciliation,
}: {
  cardId: string;
  setLoading?: (loading: boolean) => void;
  setOpenReconciliation?: () => void;
}) => {
  const { addToast } = useToast();

  return useQuery(['reconcile', cardId], () => reconcile({ cardId }), {
    enabled: false,
    onSuccess: (data) => {
      setLoading!(false);
      setOpenReconciliation!();
      addToast({
        msg: 'Successful',
        type: 'success',
      });
    },
    onError: (error) => {
      setLoading!(false);
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Error reconciling cards',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}` || 'An Error has occured', type: 'error' });
      }
    },
    keepPreviousData: true,
  });
};

export const useUpdateCardBalance = () => {
  const { addToast } = useToast();

  return useMutation(editCardBalance, {
    onSuccess: async (response: AxiosResponse<IResponse>) => {
      const { data } = response;
      addToast({ msg: data.message!, type: 'success' });
    },
    onError: (error) => {
      const err = error as AxiosError<IResponse>;
      if (err.response) {
        addToast({
          msg: err.response.data.message || 'Card withdrawal failed',
          type: 'error',
        });
      } else {
        addToast({ msg: `${err}`, type: 'error' });
      }
    },
  });
};
