import { useState } from "react";
import { useToast } from "../../context/toast";
import Button from "../button";
import Input from "../input";
import {
  AccountNumbersResponse,
  ITopupPayload,
} from "../../interface/dashboard";
import { useGetAccountNumbers, useTopup } from "../../services/dashboard";
import Dropdown from "../dropdown";

interface TopupProps {
  account: AccountNumbersResponse;
  closeModal: () => void;
}

const Topup = ({ account, closeModal }: TopupProps) => {
  const { addToast } = useToast();
  const { mutate, isLoading } = useTopup();
  const [payload, setPayload] = useState<ITopupPayload>();
  const { data } = useGetAccountNumbers();

  const filteredAccounts = data?.data.filter(
    (item) =>
      item.name === "Providus Settlement" ||
      item.name === "Kuda Settlement" ||
      item.name === "Safe Haven Settlement"
  );

  const accounts = filteredAccounts
    ?.map((item) => ({
      label: item.name,
      value: item.account,
    }))
    .filter((item) => item.label !== account.name);

  const sendRequest = () => {
    if (!payload?.from) {
      addToast({
        msg: "Select account to send from",
        type: "error",
      });
      return;
    }
    if (!payload?.amount) {
      addToast({
        msg: "Amount is required",
        type: "error",
      });
      return;
    }
    if (!payload?.secret) {
      addToast({
        msg: "Secret is required",
        type: "error",
      });
      return;
    }

    mutate(
      {
        payload: {
          from: payload.from,
          to: account.account,
          amount: payload.amount,
          secret: payload.secret,
        },
      },
      {
        onSuccess: () => {
          setPayload(undefined);
          closeModal();
        },
      }
    );
  };

  return (
    <div className="p-4">
      <div className=" w-auto">
        <div className="flex flex-col gap-2">
          <div>
            <small className="text-xs">Transfer from</small>
            <Dropdown
              label=""
              option={accounts!}
              onChange={(e) => {
                setPayload({ ...payload!, from: e?.value });
              }}
            />
          </div>
          <div className="py-2">
            <small className="text-xs">Amount</small>
            <Input
              defaultValue={payload?.amount}
              type="number"
              onChange={(e) => {
                setPayload({ ...payload!, amount: Number(e?.target.value) });
              }}
            />
          </div>
          <div className="py-2">
            <small className="text-xs">Secret</small>
            <Input
              type="password"
              defaultValue={payload?.secret}
              onChange={(e) => {
                setPayload({ ...payload!, secret: e?.target.value });
              }}
            />
          </div>

          <Button
            loading={isLoading}
            bgColor="bg-blue !py-2"
            onClick={() => {
              sendRequest();
            }}
          >
            Topup
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Topup;
