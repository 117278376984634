import React, { useEffect, useState } from 'react';
import { LineChart } from '..';
import {
  useBusinessSummaryMonth,
  useBusinessSummaryRange,
  useBusinessSummaryYear,
} from '../../../services/dashboard';
import ChartDropDown from '../../chartDropDown';

const CashFlowChart = () => {
  let date = new Date();
  let cashFlowRangeStartDate = `${date.getDate() - 5}-${
    date.getMonth() + 1
  }-${date.getFullYear()}`;

  let cashFlowRangeEndDate = new Date()
    .toLocaleDateString()
    .replaceAll('/', '-');

  const [openCashFlowDropDown, setopenCashFlowDropDown] =
    useState<boolean>(false);
  const [cashFlowPeriod, setCashFlowPeriod] = useState('This month');
  const [cashFlowLabel1, setCashFlowLabel1] = useState<{
    [key: string]: number;
  }>({});
  const [cashFlowLabel2, setCashFlowLabel2] = useState<{
    [key: string]: number;
  }>({});
  const [cashFlowRange, setCashFlowRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: cashFlowRangeStartDate,
    endDate: cashFlowRangeEndDate,
  });

  const { data: cashFlowYearDepositSummary } = useBusinessSummaryYear({
    type: 'BANK_TRANSFER',
    activity: 'DEPOSIT',
  });
  const { data: cashFlowYearWithDrawSummary } = useBusinessSummaryYear({
    type: 'BANK_TRANSFER',
    activity: 'WITHDRAW',
  });
  const { data: cashFlowMonthDepositSummary } = useBusinessSummaryMonth({
    type: 'BANK_TRANSFER',
    activity: 'DEPOSIT',
  });
  const { data: cashFlowMonthWithDrawSummary } = useBusinessSummaryMonth({
    type: 'BANK_TRANSFER',
    activity: 'WITHDRAW',
  });
  const { data: cashFlowRangeWithDrawSummary } = useBusinessSummaryRange({
    ...cashFlowRange,
    type: 'BANK_TRANSFER',
    activity: 'WITHDRAW',
  });
  const { data: cashFlowRangeDepositSummary } = useBusinessSummaryRange({
    ...cashFlowRange,
    type: 'BANK_TRANSFER',
    activity: 'DEPOSIT',
  });

  const cashFlowdata = {
    labels: [],
    datasets: [
      {
        label: 'Outbound',
        data: cashFlowLabel1,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Inbound',
        data: cashFlowLabel2,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  useEffect(() => {
    if (
      cashFlowMonthDepositSummary &&
      cashFlowMonthWithDrawSummary &&
      cashFlowPeriod === 'This month'
    ) {
      setCashFlowLabel1(cashFlowMonthWithDrawSummary!.data.data);
      setCashFlowLabel2(cashFlowMonthDepositSummary!.data.data);
    }

    if (cashFlowYearDepositSummary && cashFlowPeriod === 'This year') {
      setCashFlowLabel1(cashFlowYearWithDrawSummary!.data.data);
      setCashFlowLabel2(cashFlowYearDepositSummary!.data.data);
    }

    if (
      cashFlowRangeWithDrawSummary &&
      cashFlowRangeDepositSummary &&
      cashFlowPeriod === 'Range'
    ) {
      setCashFlowLabel1(cashFlowRangeWithDrawSummary!.data.data);
      setCashFlowLabel2(cashFlowRangeDepositSummary!.data.data);
    }
  }, [
    cashFlowMonthDepositSummary,
    cashFlowMonthWithDrawSummary,
    cashFlowPeriod,
  ]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">Cash Flow</h3>
        <ChartDropDown
          open={openCashFlowDropDown}
          setOpen={setopenCashFlowDropDown}
          value={cashFlowPeriod}
          setValue={setCashFlowPeriod}
          data={periodArray}
          range={cashFlowRange!}
          setRange={setCashFlowRange}
        />
      </div>
      <LineChart data={cashFlowdata} />
    </div>
  );
};

export default CashFlowChart;

const periodArray = [
  {
    label: 'Range',
  },
  {
    label: 'This month',
  },
  {
    label: 'This year',
  },
];
