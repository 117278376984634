import React, { useRef } from 'react';
import Button from '../button';

interface IFileUpload {
  onSelectFile: React.ChangeEventHandler;
  uploadFile?: File;
  defaultImage?: string;
}

const FileUpload = ({
  onSelectFile,
  uploadFile,
  defaultImage,
}: IFileUpload) => {
  const uploadRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <Button
        onClick={() => uploadRef.current?.click()}
        className="w-full mb-2"
        type="button"
      >
        <input
          type="file"
          accept="image/*, application/pdf"
          ref={uploadRef}
          hidden={true}
          name="file"
          onChange={onSelectFile}
        />
        Upload File
      </Button>

      {uploadFile ? (
        uploadFile.type.includes('application/pdf') ? (
          <div className="mb-4">{uploadFile.name}</div>
        ) : (
          <img
            src={URL.createObjectURL(uploadFile)}
            alt={uploadFile.name}
            className="mb-4"
          />
        )
      ) : defaultImage ? (
        <img src={defaultImage} alt={'default'} className="mb-4" />
      ) : (
        ''
      )}
    </div>
  );
};

export default FileUpload;
