import React, { useEffect, useRef, useState } from 'react';
import { CaretDown } from '../icons';

export interface IDropdownList {
  label: string;
  value: any;
}

interface IDropdown {
  label: string;
  option: IDropdownList[];
  onChange: (value?: IDropdownList) => void;
  top?: boolean;
  defaultValue?: string;
  parentClassName?: string;
  dropdownClassName?: string;
}

const Dropdown = ({
  option,
  onChange,
  label,
  defaultValue,
  parentClassName,
  dropdownClassName,
  top = false,
}: IDropdown) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('----');
  const wrapperRef = useRef<any>(null);

  const handleChange = (item: IDropdownList) => {
    setSelected(item.label);
    onChange(item);
    setOpen(false);
  };

  const reset = () => {
    setSelected('----');
    onChange();
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current?.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    defaultValue && setSelected(defaultValue);
  }, [defaultValue]);

  return (
    <div
      className={`relative py-3 px-4 cursor-pointer rounded-md bg-black font-normal ${parentClassName}`}
      ref={wrapperRef}
    >
      <div onClick={() => setOpen(!open)}>
        <div className="flex gap-2 items-center">
          {label && <span className="text-lightGray2">{label}:</span>}{' '}
          <span className="text-grayText">{selected}</span>
          <div className="ml-auto">
            <CaretDown height={10} width={10} />
          </div>
        </div>
      </div>
      {open && (
        <ul
          className={`bg-dark border border-grey max-h-40 overflow-y-auto modalScrollBar w-full absolute z-50 text-grayText font-normal ${dropdownClassName} ${
            top ? 'bottom-12' : 'top-12'
          } flex flex-col px-4 py-2 left-0`}
        >
          <li
            className="p-2 text-sm text-center cursor-pointer"
            onClick={() => reset()}
          >
            ----
          </li>
          {option.map((item, index) => (
            <li
              key={index}
              className="p-2 text-xs text-center cursor-pointer"
              onClick={() => handleChange(item)}
            >
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
