import React, { useContext, useState } from 'react';
import GlobalContext from '../../../../../../context/globalContext';
import {
  useGetSettings,
  useUpdateSettingsData,
} from '../../../../../../services/settings';
import Switch from '../../../../../../components/switch';
import URL from './url';
import Version from './version';
import Build from './build';
import { useToast } from '../../../../../../context/toast';

const DeviceTypesDetails = () => {
  const { data, refetch } = useGetSettings();
  const figurData = data?.data.figur;
  const { mutate } = useUpdateSettingsData();
  const { deviceDetails, cardType, setDeviceDetails } =
    useContext(GlobalContext);
  const { addToast } = useToast();

  const toggleShow = () => {
    setDeviceDetails({ ...deviceDetails, show: !deviceDetails.show });
    mutate(
      {
        figur: {
          ...figurData,
          android:
            cardType === 'android'
              ? { ...figurData?.android, show: !figurData?.android.show }
              : figurData?.android,
          ios:
            cardType === 'ios'
              ? { ...figurData?.ios, show: !figurData?.ios.show }
              : figurData?.ios,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  const toggleForce = () => {
    setDeviceDetails({ ...deviceDetails, force: !deviceDetails.force });
    mutate(
      {
        figur: {
          ...figurData,
          android:
            cardType === 'android'
              ? { ...figurData?.android, force: !figurData?.android.force }
              : figurData?.android,
          ios:
            cardType === 'ios'
              ? { ...figurData?.ios, force: !figurData?.ios.force }
              : figurData?.ios,
        },
      },
      {
        onSuccess: () => {
          refetch();
          addToast({ msg: 'Successfully Updated', type: 'success' });
        },
      }
    );
  };

  return (
    <div className="md:w-96 w-full text-sm">
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Show</h5>
        <Switch checked={deviceDetails.show} onCheck={toggleShow} />
      </div>
      <div className="mb-4 bg-transparent border border-grey px-2 py-3 rounded-md flex justify-between items-center">
        <h5>Force</h5>
        <Switch checked={deviceDetails.force} onCheck={toggleForce} />
      </div>

      <div className="rounded-md bg-dark">
        <URL />
        <hr className="border-grey" />
        <Build />
        <hr className="border-grey" />
        <Version />
      </div>
    </div>
  );
};

export default DeviceTypesDetails;
