import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../../components/button';
import { Loading } from '../../../../components/icons';
import { Modal } from '../../../../components/modal';
import { IBusinessInfo } from '../../../../interface/business';
import { useBusinessUpdate } from '../../../../services/business';

const SuspendAndRestoreBusiness = ({ business, refetch }: IBusinessInfo) => {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate, isLoading } = useBusinessUpdate();

  const handleSubmit = () => {
    mutate(
      {
        id: business._id,
        body: {
          suspend: true,
        },
      },
      {
        onSuccess: () => {
          refetch();
          setOpen(false);
        },
      }
    );
  };

  return (
    <>
      <div className=" w-auto">
        <Button bgColor="bg-activeLinkBlack" onClick={() => setOpen(true)}>
          {!business?.suspend ? 'Suspend business' : 'Restore business'}
        </Button>
      </div>
      <Modal isVisible={open} close={setOpen} center width="medium">
        <div className="text-center ">
          <p className="pb-4">
            Are your sure you want to{' '}
            {business?.suspend ? 'restore' : 'suspend'} this business?
          </p>
          {isLoading ? (
            <Loading />
          ) : (
            <Link to="" onClick={handleSubmit} className="text-blue">
              Yes, {business?.suspend ? 'restore' : 'suspend'}
            </Link>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SuspendAndRestoreBusiness;
