import React, { useState } from 'react';
import { Modal } from '../../../../components/modal';
import { INewBusinessResponse } from '../../../../interface/business';
import Button from '../../../../components/button';
import { useUpdateBusinessReg } from '../../../../services/business';
import { StatusEnum } from '../../../../utils/constants';

const RejectRegDetails = ({
  item,
  refetch,
}: {
  item: INewBusinessResponse;
  refetch: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const { mutate, isLoading } = useUpdateBusinessReg();

  const submit = () => {
    mutate(
      { id: item._id, body: {status: StatusEnum.REJECTED}},
      {
        onSuccess: () => {
          setOpen(false);
          refetch();
        },
      }
    );
  };

  return (
    <div>
      <div className=" w-auto">
        <div className="text-red-500" onClick={() => setOpen(true)}>
          Reject
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        center
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} loading={isLoading} disabled={isLoading}>
              Submit
            </Button>
          </div>
        }
      >
        <div className="text-center ">
          <p className="pb-4">
            Are your sure you want to reject this business?
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default RejectRegDetails;
