import * as React from "react";
import { SVGProps } from "react";
const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 387.702 516.918"
    {...props}
  >
    <g data-name="Group 1">
      <path
        data-name="Path 1"
        d="M317.7 172.308H215.39v-107.7h21.539v86.156h80.77Z"
      />
      <path
        data-name="Path 2"
        d="M333.85 516.918H0V53.838h255.23l37.691 43.078 40.922 49.542ZM21.54 495.379h290.77v-340.3l-35.539-43.078-31.23-36.617h-224Z"
      />
      <path
        data-name="Path 3"
        d="M387.7 463.078h-63.539v-21.539h42v-420H74.311v42H52.772V0h334.93Z"
      />
      <path data-name="Path 4" d="M42 204.608h205.69v21.539H42Z" />
      <path data-name="Path 5" d="M42 258.458h248.77v21.539H42Z" />
      <path data-name="Path 6" d="M42 312.308h248.77v21.539H42Z" />
      <path data-name="Path 7" d="M42 366.148h248.77v21.539H42Z" />
    </g>
  </svg>
);
export default SvgFile;
