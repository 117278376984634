import { useEffect, useState } from 'react';
import { Providers } from '../../../interface/dashboard';
import { useCheapDataGigabytesSummary } from '../../../services/dashboard';
import ButtonDropDOwn from '../../buttonDropDown';
import ChartDropDown from '../../chartDropDown';
import { BarChart2, ChartDataPropNum } from '../barChart2';

const CheapDataGBChart = () => {
  const [openDropDown, setopenDropDown] = useState<boolean>(false);
  const [openProviderDropDown, setopenProvideDropDown] =
    useState<boolean>(false);

  const [provider, setProvider] = useState<Providers>('MTN');
  const [period, setPeriod] = useState('This month');
  const newPeriod =
    period === 'This month' ? 'month' : period === 'This year' ? 'year' : '';

  const [range, setRange] = useState<{ startDate: string; endDate: string }>();
  const [gig, setGig] = useState<ChartDataPropNum>(
    {} as ChartDataPropNum
  );

  const { data } = useCheapDataGigabytesSummary({
    ...(period === 'Range' ? { ...range } : {}),
    provider,
    ...(period !== 'Range' ? { period: newPeriod } : {}),
  });

  const formatData = () => {
    const newGig: ChartDataPropNum = {};

    for (const key in data?.data.data) {
      newGig[String(key)] = data?.data.data[key].gigabytes;
    }
    setGig(newGig);
  };

  const barChartData = {
    labels: [],
    datasets: [
      {
        label: 'Gigabytes',
        data: gig,
        backgroundColor: '#ff6384',
      },
    ],
  };

  useEffect(() => {
    if (data) {
      formatData();
    }
  }, [data, period]);

  return (
    <div className="border border-grey p-4 mt-8 bg-dimGray rounded-md h-fit w-full">
      <div className="pb-4 flex justify-between items-center">
        <h3 className="font-semibold">Cheap Data in GB</h3>
        <div className="flex items-center gap-5">
          <ButtonDropDOwn
            label={provider}
            defaultValue={provider}
            option={availableProviders}
            onClick={(e) => {
              setProvider(e as Providers);
              setopenProvideDropDown(true);
            }}
          />

          <ChartDropDown
            open={openDropDown}
            setOpen={setopenDropDown}
            value={period}
            setValue={setPeriod}
            setRange={setRange}
            data={periodArray}
            range={range!}
          />
        </div>
      </div>
      <div className="h-[305px] w-full cheapDataChart">
        <BarChart2 data={barChartData} />
      </div>
    </div>
  );
};

export default CheapDataGBChart;

const periodArray = [
  {
    label: 'Range',
  },
  {
    label: 'This month',
  },
  {
    label: 'This year',
  },
];

const availableProviders = ['MTN', 'AIRTEL', '9MOBILE', 'GLO'];
