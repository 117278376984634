import { useState } from 'react';
import Button from '../../../../../components/button';
import Input from '../../../../../components/input';
import ItemCard from '../../../../../components/itemCard';
import { Modal } from '../../../../../components/modal';

interface ICharges {
  charge: string;
  setCharge: (value: string) => void;
  submit: () => void;
  loading: boolean;
}
const Charges = ({ charge, setCharge, submit, loading }: ICharges) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div onClick={() => setOpen(true)}>
        <ItemCard title="Charges" hint={charge} />
      </div>

      <Modal
        padding={true}
        isVisible={open}
        center
        close={setOpen}
        title="Charges"
        header={true}
        width="medium"
        showFooter
        footer={
          <div className="flex justify-end">
            <Button onClick={submit} disabled={loading} loading={loading}>
              Save Changes
            </Button>
          </div>
        }
      >
        <div className="flex flex-col gap-4 text-sm">
          <div>
            <div className="flex justify-between">
              <h5>Enter Charge</h5>
            </div>
            <Input
              parentClassName="mt-2"
              defaultValue={charge}
              onChange={(e) => setCharge(e.target.value)}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Charges;
