import React, { useState } from 'react';
import { Modal } from '../../../../components/modal';
import { IBusinessDetailsprops } from '../../../../interface/business';
import Application from './application';

const ApplicationForm = ({ business, refetch }: IBusinessDetailsprops) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <div
        onClick={() => {
          setOpen(true);
        }}
        className="bg-activeLinkBlack rounded-normal p-5 flex items-center justify-between cursor-pointer">
        <p>Application Form</p>
        <div className="bg-bColor py-1 px-2 gap-2 rounded-md text-[8px] flex">
          <div className="mx-2">
            Verification: {business.verification.application.validated ?? '---'}
          </div>
        </div>
      </div>
      <Modal
        isVisible={open}
        close={setOpen}
        header
        center
        overflow
        title="Application form"
        padding={false}>
        <Application item={business} refetch={refetch} />
      </Modal>
    </div>
  );
};

export default ApplicationForm;
